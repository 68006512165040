import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { MdAdd, MdEdit } from "react-icons/md";

import moment from "moment";
import { PaginationLimit } from "../../Config";

import { IoMdEye, IoMdPricetag } from "react-icons/io";

const CourseTable = ({ ApiData, setSelectedData, setViewDetail, setEditCourse, setChangePasswordModel, setAddPlan, setDeleteCategory, userType }) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const viewLesson = (item) => {
    setViewDetail(true);
    setSelectedData(item);
  };
  const addPlan = (item) => {
    setAddPlan(true);
    setSelectedData(item);
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        name: item.name,
        date: moment(item.created_at).format("DD-MM-YYYY"),
        price: getCoursePrice(item),
        discount: getDiscountPrice(item),
        purchaseCount: item.purchaseCount,
        // status: getStatus(item.status),
        action: ActionBtns(item),
      }));

      setData(tempData);
    }
  }, [ApiData]);

  const ActionBtns = (item) => {
    return (
      <ActionBtnWrapper>
        <Btn title="View Detail" onClick={() => viewLesson(item)}>
          <IoMdEye className="text-gray-700" />
        </Btn>
        <Btn title="Edit Plan" onClick={() => addPlan(item)}>
          <MdEdit className="text-gray-700" />
        </Btn>
      </ActionBtnWrapper>
    );
  };

  const getCoursePrice = (item) => {
    return (
      <div className="flex gap-2 flex-wrap text-xs">
        <span className="text-teal-600 rounded-full px-3 py-1 bg-teal-50">${item?.priceUSD}</span>
        <span className="text-teal-600 rounded-full px-3 py-1 bg-teal-50">ZK {item?.price}</span>
      </div>
    );
  };
  const getDiscountPrice = (item) => {
    return (
      <div className="flex gap-2 flex-wrap text-xs">
        <span className="text-blue-600 rounded-full px-3 py-1 bg-blue-50">${item?.discountUSD}</span>
        <span className="text-blue-600 rounded-full px-3 py-1 bg-blue-50">ZK {item?.discount}</span>
      </div>
    );
  };
  const getStatus = (status) => {
    if (status == 1) {
      return <SuccessBadge>Active</SuccessBadge>;
    } else {
      return <InActiveBadge>Inactive</InActiveBadge>;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Book Name",
          accessor: "name",
        },
      ];
    }

    // Columns for larger screens
    return [
      {
        Header: "Book Name",
        accessor: "name",
      },

      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Discount",
        accessor: "discount",
      },
      {
        Header: "Book Buyer Counts",
        accessor: "purchaseCount",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, []);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>{column.isSorted ? <img src={Images.Arrow} alt="down arrow" className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"} w-1.5 inline-block ml-1.5`} /> : ""}</span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (cellIndex === 0 || (cellIndex === 1 && window.innerWidth < 768)) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      {/* {/ Render the details of the selected row here /} */}
                      <ul className="flex flex-col gap-2">
                        <li className="flex gap-4 items-center">
                          <strong>Book Name:</strong> {data[rowIndex].name}
                        </li>

                        <li>
                          <strong>Price:</strong> {data[rowIndex].price}
                        </li>

                        <li>
                          <strong>Discount Price:</strong> {data[rowIndex].discount}
                        </li>

                        <li>
                          <strong>Book Buyer Counts:</strong> {data[rowIndex].purchaseCount}
                        </li>
                        <li>
                          <strong>Actions:</strong> {data[rowIndex].action}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>

          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 rotate-180 opacity-75" />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};

const UserImageWrapper = tw.div` flex items-center space-x-2`;
const UserImage = tw.img`w-5 h-5 rounded-full overflow-hidden`;

// const ActionBtnWrapper = tw.div`flex items-center space-x-3`;
// const ActionBtnWrapper = tw.div`grid xl:grid-cols-2 md:grid-cols-1 grid-cols-4 w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0`;
const ActionBtnWrapper = tw.div`flex gap-2 flex-wrap`;
const AddBtn = tw.button`grid place-items-center bg-blue-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const EditBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const Delete = tw.button`grid place-items-center bg-red-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b text-zinc-600 bg-slate-100 hover:bg-slate-200 uppercase`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden hover:bg-slate-50`;
// const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;

const Td = tw.td`p-3 text-sm md:p-3 text-left min-w-[100px] max-w-[200px]`;
const BookingBtn = tw.button` px-8 py-1 shadow bg-green-100 text-green-700 rounded cursor-pointer`;
const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const PlainBtn = tw.button`grid place-items-center w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const Active = tw.p`w-16 h-7 text-green-700  bg-green-100 grid place-items-center rounded inline-block`;
const Inactive = tw.p`w-16 h-7 text-red-700  bg-red-100 grid place-items-center rounded inline-block`;

const SuccessBadge = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const PendingBadge = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
const PropertyWrapper = tw.div`flex flex-col md:w-auto mt-3 object-cover`;
const PropertyImage = tw.img`w-10 h-10 md:w-16 md:h-16 hover:cursor-pointer rounded-md object-cover`;
const PropertyImagePathWrapper = tw.div`flex space-x-1`;
const ReadyBadge = tw.div`bg-blue-100 text-blue-700 text-xs border-blue-300 border w-24 h-7 flex items-center justify-center rounded`;
const InActiveBadge = tw.div`bg-red-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
export default CourseTable;
