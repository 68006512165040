import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const AddModuleModel = ({
  AddUserLoading,
  postModuleMutate,
  setOpenUploadModel,
  selectedData,
  data,
}) => {
  const [uploadFile, setUploadFile] = useState("");
  const [districtsList, setDistricts] = useState([]);
  const { t, i18n } = useTranslation();
  const InitialValues = {

  };
  console.log(data)
  const SubmitHandler = (values) => {
    //console.log(values.roles);
    // if (!values.roles) {
    //   toast.error("Please select roles for user");
    //   return;
    // }
    postModuleMutate(values);
  };

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Module</Title>

      {AddUserLoading && <Loading />}

      {!AddUserLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="courseName">Course Name</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="courseName"
                    id="courseName"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="moduleName">Module Name</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="moduleName"
                    id="moduleName"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="description">Description</Label>
                <FieldWrapper>
                  <Field
                    component="textarea"
                    name="description"
                    id="description"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>

            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddModuleModel;
