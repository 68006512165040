import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import { PaginationLimit } from "../../Config";
import { HiEye } from "react-icons/hi";
import { RiContactsFill } from "react-icons/ri";
import { FaFileExport } from "react-icons/fa";
import { MdInfoOutline, MdModeEdit, MdPassword } from "react-icons/md";
import { IoIosMore } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import Config from "../../Config";
const PlanTable = ({
  ApiData,
  setSelectedData,
  onEditPlan,
  setViewDetail,
  onDeletePlan,
  userType,
}) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  //   const updatePassword = (item) => {
  //     setChangePasswordModel(true);
  //     setSelectedData(item);
  //   };

  useEffect(() => {
    if (ApiData) {
      console.log(ApiData);
      const tempData = ApiData.map((item) => ({
        planName: getPlan(item.planName),
        course: getCourseName(item),
        courseDuration: item.courseDuration,
        price: getCoursePrice(item),
        discount: getCourseDiscount(item),
        duration: getCourseDuration(item.courseDuration),
        description: item.description,
        action: <ActionBtns item={item} />,
      }));

      setData(tempData);
    }
  }, [ApiData]);

  const edit = (item) => {
    setSelectedData(item);
    onEditPlan(true);
  };
  const deletePlan = (item) => {
    setSelectedData(item);
    onDeletePlan(true);
  };
  const viewDetails = (item) => {
    setSelectedData(item);
    setViewDetail(true);
  };
  const ActionBtns = ({ item }) => {
    return (
      <ActionBtnWrapper>
        <Btn title="View Plan" onClick={() => viewDetails(item)}>
          <IoIosMore className="text-gray-700 text-xl" />
        </Btn>
        {userType == Config.userType.SUPERADMIN &&
          <>
            <Btn title="Edit Plan" onClick={() => edit(item)}>
              <MdModeEdit className="text-gray-700" />
            </Btn>

            <Btn title="Delete Plan" onClick={() => deletePlan(item)}>
              <FaTrashAlt className="text-gray-700" />
            </Btn>
          </>}
      </ActionBtnWrapper>
    );
  };

  const getPlan = (item) => {
    let className = "";
    const plans = ["Bronze", "Silver", "Gold"];
    switch (item) {
      case 1:
        className =
          "bg-gradient-to-r from-[#CD7F32] to-amber-100 text-amber-800 rounded-full px-4 py-2";
        break;
      case 2:
        className =
          "bg-gradient-to-r from-[#C0C0C0] to-slate-100 text-neutral-900 rounded-full px-4 py-2";
        break;
      case 3:
        className =
          "bg-gradient-to-r from-[#FFD700] to-amber-100 text-amber-600 rounded-full px-4 py-2";
        break;
    }
    return <span className={`${className}`}>{plans[item - 1]}</span>;
  };
  const getCourseName = (item) => {
    return (
      <div className="text-2xl flex gap-2 font-semibold text-slate-600 items-end">
        <span>{item.courseName}</span>
        <span className="text-slate-400 italic text-lg">#{item.courseId}</span>
      </div>
    );
  };

  const getCoursePrice = (item) => {
    return (
      <div className="flex gap-2 flex-wrap text-xs">
        <span className="text-teal-600 rounded-full px-3 py-1 bg-teal-50">
          ${item.priceUSD}
        </span>
        <span className="text-teal-600 rounded-full px-3 py-1 bg-teal-50">
          ZK {item.price}
        </span>
      </div>
    );
  };
  const getCourseDuration = (duration) => {
    return (
      <span className="bg-sky-50 text-sky-700 rounded-md px-3 py-1">
        {duration} months
      </span>
    );
  };
  const getCourseDiscount = (item) => {
    return (
      <div className="flex gap-2 flex-wrap text-xs">
        <span className="text-fuchsia-600 rounded-full px-3 py-1 bg-fuchsia-50">
          $ {item.discountUSD}%
        </span>
        <span className="text-fuchsia-600 rounded-full px-3 py-1 bg-fuchsia-50">
          ZK {item.discount}%
        </span>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Plan Name",
          accessor: "planName",
        },
        {
          Header: "Price",
          accessor: "price",
        },
      ];
    }

    // Columns for larger screens
    return [
      //   {
      //     Header: "Course Name",
      //     accessor: "courseName",
      //   },
      {
        Header: "Plan Name",
        accessor: "planName",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Discount (%)",
        accessor: "discount",
      },
      {
        Header: "Validity",
        accessor: "duration",
      },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, []);

  return (
    <>
      <h1 className="mt-10 mb-4">{data[0].course}</h1>
      <TableWrapper>
        <CustomTable {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        <img
                          src={Images.Arrow}
                          alt="down arrow"
                          className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                            } w-1.5 inline-block ml-1.5`}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <React.Fragment key={rowIndex}>
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          onClick={() => {
                            if (cellIndex === 0 || cellIndex === 1 && window.innerWidth < 768) {
                              toggleRow(rowIndex);
                            }
                          }}
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                  {selectedRow === rowIndex && window.innerWidth < 768 && (
                    <Tr>
                      <Td colSpan={columns.length}>
                        {/* {/ Render the details of the selected row here /} */}
                        <ul className="flex flex-col gap-4">
                          <li className="flex gap-2 items-center">
                            <strong>Discount:</strong> {data[rowIndex].discount}
                          </li>
                          <li className="flex gap-2 items-center">
                            <strong>Validity:</strong> {data[rowIndex].duration}
                          </li>
                          <li>
                            <strong>Action:</strong> {data[rowIndex].action}
                          </li>
                        </ul>
                      </Td>
                    </Tr>
                  )}
                </React.Fragment>
              );
            })}
          </Tbody>
        </CustomTable>

        {ApiData?.length > 0 && (
          <PaginationWrapper>
            <div className="px-2">
              Page{" "}
              <em>
                {pageIndex + 1} of {pageOptions.length}
              </em>
            </div>

            <div className="flex gap-1">
              <PrevBtn
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <img
                  src={Images.Arrow}
                  alt="arrow"
                  className="w-2 opacity-75 "
                />
              </PrevBtn>
              <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
                <img
                  src={Images.Arrow}
                  alt="arrow"
                  className="w-2 rotate-180 opacity-75"
                />
              </NextBtn>
            </div>
          </PaginationWrapper>
        )}
      </TableWrapper>
    </>
  );
};

const UserImageWrapper = tw.div` flex items-center space-x-2`;
const UserImage = tw.img`w-5 h-5 rounded-full overflow-hidden`;
const TableWrapper = tw.h1` border bg-white  rounded-md overflow-auto`;
// const ActionBtnWrapper = tw.div`flex items-center space-x-3`;
const ActionBtnWrapper = tw.div`flex flex-wrap gap-2 items-center`;
const AddBtn = tw.button`grid place-items-center bg-blue-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const EditBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const Delete = tw.button`grid place-items-center bg-red-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b bg-slate-100 uppercase text-slate-600`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden hover:bg-slate-50 `;
// const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-semibold`;

const Td = tw.td`p-3 text-sm md:p-3 text-left max-w-[120px]`;
const BookingBtn = tw.button` px-8 py-1 shadow bg-green-100 text-green-700 rounded cursor-pointer`;
const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const PlainBtn = tw.button`grid place-items-center w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const Active = tw.p`w-16 h-7 text-green-700  bg-green-100 grid place-items-center rounded inline-block`;
const Inactive = tw.p`w-16 h-7 text-red-700  bg-red-100 grid place-items-center rounded inline-block`;

const SuccessBadge = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const PendingBadge = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;

const InActiveBadge = tw.div`bg-red-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
export default PlanTable;
