import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import tw from "tailwind-styled-components";
import { twMerge } from "tailwind-merge";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Parser from "html-react-parser";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Dropzone from "../Module/Dropzone";
import Loading from "react-loading";
import { IoIosAdd } from "react-icons/io";
import { FaTrashAlt, FaTrashRestore } from "react-icons/fa";
import Spinner from "../Spinner";

const animatedComponents = makeAnimated();

const EditModule = ({ user, isLoading, editModuleMutate, data, videolist, attachmentList, index }) => {
  console.log(videolist);
  const SubModuleTitle = Number(index) + 1;
  console.log(SubModuleTitle);

  // ! STATES
  const [videos, setVideos] = useState(
    videolist.map((video) => ({
      id: video.id,
      url: video.file,
      title: video.name,
      mins: Number(video.duration.split(":")[0]), // Extract minutes from duration
      secs: Number(video.duration.split(":")[1]), // Extract seconds from duration
    }))
  );
  const [deletedVideoIds, setDeletedVideoIds] = useState([]);
  // const [videos, setVideos] = useState([
  //   { url: "", title: "", mins: "", secs: "" },
  // ]);
  const [files, setFiles] = useState(
    attachmentList.map((attachment) => ({
      id: attachment.id,
      file: attachment.file,
      title: attachment.name,
    }))
  );

  const [newFiles, setNewFiles] = useState([{ file: null, title: "" }]);
  //const [files, setFiles] = useState([{ file: null, title: "" }]);

  const initialValues = {
    name: data?.name,
    description: data?.shortDescription,
  };

  // ! HANDLE ADD VIDEO
  const handleAddVideo = () => {
    setVideos((prev) => [...prev, { url: "", title: "", mins: "", secs: "" }]);
  };

  // ! -----ADD VIDEO INPUTS FUNCTION---------
  const handleChange = (e, i) => {
    console.log(e.target);
    const { name, value } = e.target;
    const onChangeVal = [...videos];
    onChangeVal[i][name] = value;

    // VALIDATION FOR DURATION
    if (name === "secs") {
      if (!isNaN(value) && value >= 0 && value < 60) {
        setVideos(onChangeVal);
        console.log(value);
      }
    } else {
      setVideos(onChangeVal);
    }
  };

  const formSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    // videos: yup.array().of(videoSchema).required("Videos are required"),
  });
  const videoId = videos.map((item) => item.id);
  console.log(videoId);

  const handleDeleteVideo = (videoId) => {
    // Remove the video from the videos state
    const updatedVideos = videos.filter((video) => video.id !== videoId);
    setVideos(updatedVideos);

    // Add the video ID to the state for deleted video IDs
    setDeletedVideoIds((prevIds) => [...prevIds, videoId]);
  };

  const [deletedAttachmentIds, setDeletedAttachmentIds] = useState([]);
  const attachmentId = attachmentList.map((item) => item.id);
  console.log(attachmentId);

  const handleDeleteAttachment = (attachmentId) => {
    const updatedFiles = files.filter((file) => file.id !== attachmentId);
    setFiles(updatedFiles);

    setDeletedAttachmentIds((prevIds) => [...prevIds, attachmentId]);
  };

  // SUBMIT HANDLER
  const handleSubmit = (values) => {
    const pdfTitles = [];
    const attachments = [];
    const attachmentsId = [];
    // ! PDF TITLES
    files.map((item) => {
      console.log(item);
      if (!item.id) {
        pdfTitles.push(item.title);
        attachments.push(item.file);
        attachmentsId.push(item.id);
      }
    });
    // const pdfTitles = files.map((item) => item.title);
    // const attachments = files.map((item) => item.file);
    // const attachmentsId = files.map((item) => item.id);
    const videoId = videos.map((item) => item.id);
    // ! CREATE VIDEO OBJECTS
    const videoUrls = videos.map((video) =>
      Object.assign({
        id: video.id,
        url: video.url,
        title: video.title,
        duration: `${video.mins}:${video.secs}`,
      })
    );

    const data = {
      ...values,
      videos: videoUrls,
      pdfTitles: pdfTitles,
      attachments: attachments,
      attachmentsId: attachmentsId,
      videoId: videoId,
      deletedVideoIds: deletedVideoIds,
      deletedAttachmentIds: deletedAttachmentIds,
    };
    console.log(data);
    editModuleMutate(data);
  };
  return (
    <article className="mt-10 w-full m-auto p-8 rounded-lg bg-white shadow-md">
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={formSchema} enableReinitialize>
        {({ values, setFieldValue, errors, touched }) => {
          // console.log(errors);
          return (
            <Form className="flex flex-col gap-y-4">
              <GroupWrapper>
                <FieldWrapper className="flex-1 flex-wrap">
                  <label>Module Name</label>
                  <Field type="text" name="name" id="name" placeholder="Enter the module name..." as={Input} />
                  {errors.name && touched.name ? <p className="bg-red-100 text-red-500 p-2 text-xs">{errors.name}</p> : null}
                </FieldWrapper>
              </GroupWrapper>
              <FieldWrapper>
                <label>Short Description</label>
                <Field type="text" name="description" id="description" placeholder="Enter the module description" as={TextArea} />
                {errors.description && touched.description ? <p className="bg-red-100 text-red-500 p-2 text-xs">{errors.description}</p> : null}
              </FieldWrapper>

              <FieldWrapper className="flex-wrap">
                <div className="flex gap-2 items-center">
                  {/* <label>
                    Sub Module <em className="text-sm text-gray-500">(YouTube Video key)</em>
                  </label> */}
                  <button type="button" onClick={handleAddVideo} className="rounded-full bg-blue-100 text-blue-500 text-xl p-2 flex items-center">
                    <IoIosAdd /> <span className="text-sm">Add Sub Module</span>
                  </button>
                </div>

                {videos &&
                  videos.map((video, idx) => (
                    <>
                      {" "}
                      <label>
                        Sub Module {SubModuleTitle}.{idx < 9 ? idx + 1 : idx + 1}
                      </label>
                      <div key={idx} className=" flex relative flex-col lg:grid lg:grid-cols-12 gap-4 mb-4">
                        <Field
                          // required
                          type="text"
                          className="col-span-3"
                          name="title"
                          value={video.title}
                          placeholder="Enter Sub Module Title"
                          as={Input}
                          onChange={(e) => handleChange(e, idx)}
                        />

                        <Field
                          // required
                          className="col-span-4"
                          type="text"
                          name="url"
                          value={video.url}
                          placeholder="Enter YouTube Video Key..."
                          as={Input}
                          onChange={(e) => handleChange(e, idx)}
                        />

                        {/* <Field
                          required
                          type="text"
                          className="col-span-3"
                          name="title"
                          value={video.title}
                          placeholder="Enter Video title"
                          as={Input}
                          onChange={(e) => handleChange(e, idx)} /> */}
                        <Field
                          // required
                          type="number"
                          name="mins"
                          className="col-span-2"
                          value={video.mins}
                          min={0}
                          max={59}
                          placeholder="Video Duration(hrs)"
                          as={Input}
                          onChange={(e) => handleChange(e, idx)}
                        />
                        <Field
                          // required
                          type="number"
                          name="secs"
                          className="col-span-2"
                          value={video.secs}
                          min={0}
                          max={59}
                          placeholder="Video Duration(mins)"
                          as={Input}
                          onChange={(e) => handleChange(e, idx)}
                        />
                        {errors.videoUrls && errors.videoUrls[idx] && errors.videoUrls[idx].video && errors.videoUrls[idx].video.url && touched.videoUrls && touched.videoUrls[idx] && touched.videoUrls[idx].video && touched.videoUrls[idx].video.url ? <p className="bg-red-100 text-red-500 p-2 text-xs">{errors.videoUrls[idx].video.url}</p> : null}
                        {/* DELETE INPUT FIELD  */}
                        <button
                          type="button"
                          // onClick={() => {
                          //   console.log(idx);
                          //   const deleteVal = [...videos];
                          //   deleteVal.splice(idx, 1);
                          //   setVideos(deleteVal);
                          // }}
                          onClick={() => handleDeleteVideo(video.id)}
                          className="grid place-items-center bg-rose-100 text-rose-500 rounded-full w-9 h-9"
                        >
                          {<FaTrashAlt />}
                        </button>
                      </div>
                    </>
                  ))}
              </FieldWrapper>

              <FieldWrapper>
                <div className="flex gap-2 items-center">
                  <label>
                    Attachments <em className="text-sm text-gray-500">(add attachments with their titles)</em>
                  </label>
                  <button
                    type="button"
                    onClick={() => {
                      console.log("clicked");
                      setFiles([...files, { file: null, title: "" }]);
                    }}
                    className="rounded-full bg-blue-100 text-blue-500 text-xl p-2"
                  >
                    <IoIosAdd />
                  </button>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                  {files &&
                    files.map((file, i) => (
                      <FieldWrapper className="relative" key={i}>
                        <button
                          type="button"
                          onClick={() => handleDeleteAttachment(file.id)}
                          // onClick={() => {
                          //   console.log(i);
                          //   const tempFiles = [...files];
                          //   tempFiles.splice(i, 1);
                          //   setFiles(tempFiles);
                          // }}
                          className="absolute bg-red-50 p-2 rounded-full text-red-400 text-sm right-2 top-2"
                        >
                          <FaTrashAlt />
                        </button>
                        <Field id="attachments" name="attachments" type="text">
                          {(props) => (
                            <Dropzone
                              // setAttachmentTitles={setAttachmentTitles}
                              index={i}
                              fields={props.field}
                              setFieldValue={props.form.setFieldValue}
                              onAddFiles={setFiles}
                              pdfFiles={files}
                              componentFor="attachments"
                              accept=""
                              fileType="all"
                              File={props.field.value ? [props.field.value] : null}
                            />
                          )}
                        </Field>
                        <label className="text-gray-500 font-semibold text-sm">Pdf Title</label>
                        <Field
                          as={Input}
                          required
                          name="attachmentName"
                          value={file.title}
                          onChange={(e) => {
                            const { name, value } = e.target;
                            const tempFiles = [...files];
                            tempFiles[i]["title"] = value;
                            setFiles(tempFiles);
                          }}
                          placeholder="Enter title"
                        />
                      </FieldWrapper>
                    ))}
                </div>
              </FieldWrapper>

              <SubmitBtn type="submit" className="flex items-center justify-center h-12">
                {isLoading ? <Spinner className="text-white w-3 h-3" /> : "Submit"}
              </SubmitBtn>
            </Form>
          );
        }}
      </Formik>
    </article>
  );
};

const FieldWrapper = tw.div`${(props) => twMerge(`flex flex-col gap-2`, props.className)}`;
const GroupWrapper = tw.div`flex flex-col md:flex-row md:items-start gap-4 `;
const Input = tw.input`bg-slate-50 text-sm rounded-md p-2`;
const TextArea = tw.textarea`bg-slate-50 text-sm rounded-md p-2`;
const SubmitBtn = tw.button`bg-black rounded-lg text-center text-slate-100 py-2 px-4 w-1/2 m-auto`;

export default EditModule;
