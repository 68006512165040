import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Books/Table";
import Navbar from "../../Components/Navbar";
import AddPlanModal from "../../Components/Books/AddPlanModal";
import { ToastContainer, toast } from "react-toastify";
import {
  Page,
  Bg,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
  SmallBtn,
} from "../../Components/Styles/InputStyles";
import { Field, Form, Formik } from "formik";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import ViewDetailModel from "../../Components/Books/ViewDetail";

import EditCourseModel from "../../Components/Courses/EditCourseModel";

const Books = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openRoleViewModel, setopenRoleViewModel] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [editUser, setEditUser] = useState(false);
  const [editCourse, setEditCourse] = useState(false);
  const [addPlan, setAddPlan] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);

  console.log(filterValue);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
  };
  const fetchFunction = async () => {
    const body = new FormData();
    // body.append("filterType", filter);
    // body.append("filterValue", filterValue);
    return await axios.post(`${Config.apiUrl}/get-books`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getCourseSuccess = (res) => {};

  const getCourseError = (res) => {
    toast.error("An error occured");
  };
  const {
    isLoading: isLoading,
    mutate: getCourseMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getCourseSuccess,
    onError: getCourseError,
  });

  useEffect(() => {
    // *----------DEBOUNCE--------
    const timeout = setTimeout(() => {
      getCourseMutate();
    }, 500);
    return () => clearTimeout(timeout);
  }, [filterValue]);

  const submitHandler = (values) => {
    setFilterValue(values.filterVal);
    getCourseMutate();
  };
  // console.log(data?.data?.data?.userList?.length);
  console.log(data?.data?.data?.bookList);

  //------- Edit Single Entry -------
  const EditCourseFunction = async (values) => {
    const body = new FormData();
    body.append("id", values.id);
    body.append("name", values.courseName);
    body.append("categoryId", values.categoryList);
    body.append("price1", values.price);
    body.append("rating", values.rating);
    body.append("requirements", values.requirements);
    body.append("shortDescription", values.description);
    body.append("totalTime", values.duration);

    return await axios.post(`${Config.apiUrl}/editcourse`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getCourseMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditCourse(false);
  };

  const onEditEntryError = (data) => {
    setEditCourse(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occured");
  };

  const { isLoading: editEntryLoading, mutate: editCourseMutate } = useMutation(
    EditCourseFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  const AddPlanFunction = async (values) => {
    const body = new FormData();
    console.log(values);
    body.append("bookId", selectedData.id);

    body.append("priceUSD", values.priceUsd);
    body.append("price", values.priceZk);
    body.append("discountUSD", values.discountUsd);
    body.append("discount", values.discountZk);

    return await axios.post(`${Config.apiUrl}/update-book-price`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddPlanSuccess = (data) => {
    console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getCourseMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setAddPlan(false);
  };

  const onAddPlanError = (data) => {
    console.log(data?.response?.data?.msg);
    setOpenUploadModel(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: AddPlanLoading, mutate: postAddPlan } = useMutation(
    AddPlanFunction,
    {
      onSuccess: onAddPlanSuccess,
      onError: onAddPlanError,
    }
  );
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Book Catalogue")}</Title>
              <Underline />
            </div>
          </div>

          {/* <div className="flex w-full justify-between items-center">
                        <Filters
                            submitHandler={submitHandler}
                            initialValues={initialValues}
                            InitialLoading={isLoading}
                            setFilter={setFilter}
                            filterValue={filterValue}
                            setFilterValue={setFilterValue}
                            filter={filter}
                            user={user}
                            t={t}
                        />
                    </div> */}

          {editCourse && (
            <EditCourseModel
              editCourseMutate={editCourseMutate}
              editEntryLoading={editEntryLoading}
              setEditCourse={setEditCourse}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {viewDetail && (
            <ViewDetailModel
              selectedData={selectedData}
              setViewDetail={setViewDetail}
            />
          )}
          {addPlan && (
            <AddPlanModal
              setAddPlan={setAddPlan}
              data={data?.data}
              selectedData={selectedData}
              setOpenUploadModel={setOpenUploadModel}
              postAddPlan={postAddPlan}
              AddPlanLoading={AddPlanLoading}
            />
          )}
          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data?.data?.bookList}
                setopenRoleViewModel={setopenRoleViewModel}
                setSelectedData={setSelectedData}
                setAddPlan={setAddPlan}
                selectedData={selectedData}
                setEditCourse={setEditCourse}
                setViewDetail={setViewDetail}
                userType={user.userType}
              />
            </TableWrapper>
          )}

          {/* {isLoading && <Loading />}
          {error && !isLoading && data?.data?.data?.userList == false && (
            <NotFoundModel />
          )} */}
          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data?.bookList?.length === 0) &&
            !isLoading && <NotFoundModel />}
        </BoxContainer>
      </Bg>
    </>
  );
};
const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  filterValue,
  setFilterValue,
  t,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form className="w-full">
        <FilterContainer>
          <InputGroup className="" style={{ flexBasis: "content" }}>
            <Label htmlFor="filter">{t("Search")}</Label>

            <Select
              autoComplete="off"
              className="w-36 pl-2 bg-transparent text-sm p-0 border-none"
              style={{ height: "40px" }}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
                //searchHandler(e.target.value);
              }}
            >
              <MenuItem value="name">By Course</MenuItem>\
            </Select>
          </InputGroup>
          <InputGroup className="w-[10rem]">
            <Label htmlFor="filterVal">{t("Value")}</Label>
            <FieldWrapper className="h-10">
              <Field
                type="text"
                name="filterVal"
                id="filterVal"
                class="ml-1"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </FieldWrapper>
          </InputGroup>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-[#4e752f] hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`
grid grid-cols-2 w-full gap-4 md:flex md:space-x-4 items-end mt-12 md:mt-8`;

export default Books;
