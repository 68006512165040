import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/UnpublishCourses/Table";
import Navbar from "../../Components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import {
  Page,
  Bg,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
  SmallBtn,
} from "../../Components/Styles/InputStyles";
import { Field, Form, Formik } from "formik";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { ForUs } from "../../Config";
import { MdAdd } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import EditUserModel from "../../Components/Users/EditUsersModel";
import AddCourseModel from "../../Components/Courses/AddCourseModel";
import ViewDetailModel from "../../Components/Courses/ViewDetail";
import DropZoneModel from "../../Components/Courses/DropzoneImageUpload";
import EditCourseModel from "../../Components/Courses/EditCourseModel";
import AddPlanModal from "../../Components/Plans/AddPlanModal";
import DeleteCourse from "../../Components/Courses/DeleteCourse";
import UpdateCourse from "../../Components/UnpublishCourses/UpdateCourse";

const UnpublishCourses = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openRoleViewModel, setopenRoleViewModel] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [editUser, setEditUser] = useState(false);
  const [editCourse, setEditCourse] = useState(false);
  const [addPlan, setAddPlan] = useState(false);
  const [selectedImage, setSelectedImage] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);

  console.log(filterValue);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
  };
  const fetchFunction = async () => {
    const body = new FormData();
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    return await axios.post(`${Config.apiUrl}/get-unpublish-course`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getCourseSuccess = (res) => {};

  const getCourseError = (res) => {
    toast.error("An error occured");
  };
  const {
    isLoading: isLoading,
    mutate: getCourseMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getCourseSuccess,
    onError: getCourseError,
  });

  useEffect(() => {
    // *----------DEBOUNCE--------
    const timeout = setTimeout(() => {
      getCourseMutate();
    }, 500);
    return () => clearTimeout(timeout);
  }, [filterValue]);

  const submitHandler = (values) => {
    setFilterValue(values.filterVal);
    getCourseMutate();
  };
  // console.log(data?.data?.data?.userList?.length);
  console.log(data?.data?.data);

  const EditUserFunction = async (values) => {
    const body = new FormData();

    body.append("status", values.status);

    body.append("courseId", values.id);
    return await axios.post(`${Config.apiUrl}/edit-course-status`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getCourseMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditUser(false);
  };

  const onEditEntryError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
    EditUserFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Unpublished Courses")}</Title>
              <Underline />
            </div>

            {/* <Uploader setOpenUploadModel={setOpenUploadModel} t={t} /> */}
          </div>

          <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              filter={filter}
              user={user}
              t={t}
            />
          </div>

          {selectedImage && (
            <DropZoneModel
              selectedData={selectedData}
              setSelectedImage={setSelectedImage}
            />
          )}
          {editUser && (
            <UpdateCourse
              editAddUser={editAddUser}
              editEntryLoading={editEntryLoading}
              setEditUser={setEditUser}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {viewDetail && (
            <ViewDetailModel
              selectedData={selectedData}
              setViewDetail={setViewDetail}
            />
          )}

          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data?.data?.courseList}
                setopenRoleViewModel={setopenRoleViewModel}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setEditCourse={setEditCourse}
                setChangePasswordModel={setChangePasswordModel}
                setSelectedImage={setSelectedImage}
                setViewDetail={setViewDetail}
                setAddPlan={setAddPlan}
                setDeleteCategory={setDeleteCategory}
                userType={user.userType}
                setEditUser={setEditUser}
              />
            </TableWrapper>
          )}

          {/* {isLoading && <Loading />}
          {error && !isLoading && data?.data?.data?.userList == false && (
            <NotFoundModel />
          )} */}
          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data?.courseList?.length === 0) &&
            !isLoading && <NotFoundModel />}
        </BoxContainer>
      </Bg>
    </>
  );
};
const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  filterValue,
  setFilterValue,
  t,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form className="w-full">
        <FilterContainer>
          <InputGroup className="" style={{ flexBasis: "content" }}>
            <Label htmlFor="filter">{t("Search")}</Label>

            <Select
              autoComplete="off"
              className="w-36 pl-2 bg-transparent text-sm p-0 border-none"
              style={{ height: "40px" }}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
                //searchHandler(e.target.value);
              }}
            >
              <MenuItem value="name">By Course</MenuItem>\
            </Select>
          </InputGroup>
          <InputGroup className="w-[10rem]">
            <Label htmlFor="filterVal">{t("Value")}</Label>
            <FieldWrapper className="h-10">
              <Field
                type="text"
                name="filterVal"
                id="filterVal"
                class="ml-1"
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </FieldWrapper>
          </InputGroup>

          {/* <InputGroup className="max-w-xs">
            <Label htmlFor="endDate">End Date:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="endDate" id="endDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup> */}

          {/* <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Search")}
          </ApplyBtn> */}
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const Uploader = ({ setOpenUploadModel, t }) => {
  const navigate = useNavigate();
  return (
    <UploadWrapper>
      <Button type="button" onClick={() => navigate("/courses/addCourse")}>
        <MdAdd className="text-white w-5 h-5" /> <p>{t("Add Course")}</p>
      </Button>
      {/* <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <MdAdd className="text-white w-5 h-5" /> <p>{t("Add Course")}</p>
      </Button> */}
      {/* <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <HiUpload className="text-white w-5 h-5" /> <p>Upload Bulk Contact</p>
      </Button> */}
    </UploadWrapper>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-[#4e752f] hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`
grid grid-cols-2 w-full gap-4 md:flex md:space-x-4 items-end mt-12 md:mt-8`;

export default UnpublishCourses;
