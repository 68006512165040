import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import Config, { PaginationLimit } from "../../Config";

import { MdModeEdit, } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
// import Config from "../../Config";
const CoursesTable = ({
    ApiData,

    userType,
}) => {
    console.log(Config.userType.OPERATION)
    const [data, setData] = useState([
        {
            deviceName: "",
            extractionTime: "",
            processingTime: "",
            drops: "",
            action: "",
        },
    ]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const toggleRow = (index) => {
        setSelectedRow(selectedRow === index ? null : index);
    };


    const getMode = (mode) => {
        if (mode == 1) {
            return <p>Mobile Money</p>;
        } else if (mode == 2) {
            return <p>Cash</p>;
        } else {
            return <p>Bank</p>;
        }
    };

    const getAmount = (item) => {
        if (item.currency == 1) {
            return (<p className="text-teal-600 rounded-full px-3 py-1 bg-teal-50 max-w-fit">ZK {item.amount}</p>);
        } else {
            return <p className="text-teal-600 rounded-full px-3 py-1 bg-teal-50 max-w-fit">$ {item.amount}</p>;
        }
    };
    useEffect(() => {
        if (ApiData) {
            const tempData = ApiData.map((item) => ({
                id: getCategoryId(item),
                name: item.name,
                courseName: item.courseName,
                studentCount: item.studentCount,
                amount:
                    getAmount(item),
                mode: getMode(item.payment_mode),
            }));

            setData(tempData);
        }
    }, [ApiData]);


    const getCategoryId = (item) => {
        return (
            <span className="bg-sky-100 text-sm font-semibold text-sky-700 p-1 rounded-md">
                #{item.id}
            </span>
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const columns = React.useMemo(() => {
        if (isMobileView) {

            return [
                {
                    Header: "Id",
                    accessor: "id",

                },
                {
                    Header: "Name",
                    accessor: "name",

                },
            ];
        }


        return [
            {
                Header: "Id",
                accessor: "id",

            },
            {
                Header: "Name",
                accessor: "name",

            },

            // {
            //     Header: "Course Name",
            //     accessor: "courseName",
            // },
            {
                Header: "Amount",
                accessor: "amount",
            },
            {
                Header: "Student Count",
                accessor: "studentCount",
            },
            {
                Header: "Payment Mode ",
                accessor: "mode",
            },

        ];
    }, [isMobileView]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        pageOptions,
        page,
        state: { pageIndex, pageSize },
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
            data,
            columns,
        },
        useSortBy,
        usePagination
    );

    useEffect(() => {
        setPageSize(PaginationLimit);
    }, []);

    return (
        <>
            <CustomTable {...getTableProps()}>
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted ? (
                                            <img
                                                src={Images.Arrow}
                                                alt="down arrow"
                                                className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                                                    } w-1.5 inline-block ml-1.5`}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {page.map((row, rowIndex) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={rowIndex}>
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map((cell, cellIndex) => {
                                        return (
                                            <Td
                                                {...cell.getCellProps()}
                                                onClick={() => {
                                                    if (cellIndex === 0 || cellIndex === 1 && window.innerWidth < 768) {
                                                        toggleRow(rowIndex);
                                                    }
                                                }}
                                            >
                                                {cell.render("Cell")}
                                            </Td>
                                        );
                                    })}
                                </Tr>
                                {selectedRow === rowIndex && window.innerWidth < 768 && (
                                    <Tr>
                                        <Td colSpan={columns.length}>

                                            <ul className="flex flex-col gap-2">

                                                <li>
                                                    <strong>Course Name:</strong> {data[rowIndex].courseName}
                                                </li>

                                                <li className="flex gap-2">
                                                    <strong>Student Count:</strong> {data[rowIndex].studentCount}
                                                </li>

                                            </ul>
                                        </Td>
                                    </Tr>
                                )}
                            </React.Fragment>
                        );
                    })}
                </Tbody>
            </CustomTable>
            {ApiData?.length > 0 && (
                <PaginationWrapper>
                    <div className="px-2">
                        Page{" "}
                        <em>
                            {pageIndex + 1} of {pageOptions.length}
                        </em>
                    </div>

                    <div className="flex gap-1">
                        <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
                        </PrevBtn>
                        <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
                            <img
                                src={Images.Arrow}
                                alt="arrow"
                                className="w-2 rotate-180 opacity-75"
                            />
                        </NextBtn>
                    </div>
                </PaginationWrapper>
            )}
        </>
    );
};

const UserImageWrapper = tw.div` flex items-center space-x-2`;
const UserImage = tw.img`w-5 h-5 rounded-full overflow-hidden`;

// const ActionBtnWrapper = tw.div`flex items-center space-x-3`;
const ActionBtnWrapper = tw.div`grid xl:grid-cols-2 md:grid-cols-1 grid-cols-4 w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0`;
const AddBtn = tw.button`grid place-items-center bg-blue-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const EditBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const Delete = tw.button`grid place-items-center bg-red-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden hover:bg-slate-50 `;
// const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;

const Td = tw.td`p-3 text-sm md:p-3 text-left`;
const BookingBtn = tw.button` px-8 py-1 shadow bg-green-100 text-green-700 rounded cursor-pointer`;
const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const PlainBtn = tw.button`grid place-items-center w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const Active = tw.p`bg-green-100 text-green-700 text-xs border-green-300 border  h-7 flex items-center justify-center rounded w-10 font-bold`;
const Inactive = tw.p`bg-red-100 text-red-700 text-xs border-red-300 border  h-7 flex items-center justify-center rounded w-10 font-bold`;

const PendingBadge = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;

const SuccessBadge = tw.div`bg-teal-100 text-emerald-600 text-xs grid font-bold place-items-center rounded-lg px-2 py-1 w-24 h-7`;
const InActiveBadge = tw.div`bg-rose-100 text-rose-600 font-bold text-xs grid place-items-center rounded-md px-2 py-1 w-24 h-7`;
const Unpaid = tw.p`bg-gray-100 text-gray-700 text-xs border-gray-300 border  h-7 flex items-center justify-center rounded w-10 font-bold`;
export default CoursesTable;
