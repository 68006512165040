import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../Components/Transaction/Table";

import {
  Page,
  Bg,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
  SmallBtn,
} from "../../Components/Styles/InputStyles";
import { Field, Form, Formik } from "formik";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import moment from "moment";

const Transaction = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const { id } = useParams();
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );

  console.log("Filter Value -> ", filterValue);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  console.log("Filter -> ", filter);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };

  // ! -----------FETCH PAYMENTS RECORDS-----------
  const fetchPaymentRecords = async () => {
    const body = new FormData();
    body.append("startDate", startDate);
    body.append("endDate", endDate);
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    body.append("customerId", id);
    return await axios.post(`${Config.apiUrl}/customer-payments`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const {
    isLoading,
    data,
    error,
    mutate: getPaymentsMutate,
  } = useMutation(fetchPaymentRecords, {
    onSuccess: (res) => {
      if (res.data.status === "OK") {
        console.log("Success");
      }
    },
    onError: (res) => {
      console.log(res);
      toast.error("An error occured");
    },
  });

  // data && console.log(data.data.payments);

  // useEffect(() => {
  //   getPaymentsMutate();
  // }, []);

  useEffect(() => {
    // ? ------- DEBOUNCING --------
    const timeout = setTimeout(() => {
      getPaymentsMutate();
    }, 500);

    return () => clearTimeout(timeout);
  }, [filterValue, startDate, endDate]);

  const submitHandler = (values) => {
    const body = new FormData();
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    body.append("customerId", id);
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    setFilterValue(values.filterVal);
  };

  // console.log(data?.data?.data?.userList?.length);
  console.log(data);

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Transactions")}</Title>
              <Underline />
            </div>

            <Uploader setOpenUploadModel={setOpenUploadModel} t={t} />
          </div>

          {/* <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              filterValue={filterValue}
              filter={filter}
              user={user}
              t={t}
            />
          </div> */}
          {!isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={data ? data?.data?.payments : []}
                isLoading={isLoading}
              />
            </TableWrapper>
          )}
          {isLoading && <Loading />}
          {(error || data?.data?.payments?.length === 0) && !isLoading && (
            <NotFoundModel />
          )}
        </BoxContainer>
      </Bg>
    </>
  );
};
const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  setFilterValue,
  filterValue,
  t,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form className="w-full">
        <FilterContainer>
          <div className="flex gap-2 items-end sm:text-base text-xs flex-wrap">
            <div className="flex items-end gap-2">
              <InputGroup className="max-w-[130px]">
                <Label htmlFor="startDate">{t("Start_Date")}:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field
                    type="date"
                    name="startDate"
                    id="startDate"
                    class="ml-1"
                  />
                </FieldWrapper>
              </InputGroup>

              <InputGroup className="max-w-[130px]">
                <Label htmlFor="endDate">{t("End_Date")}:</Label>
                <FieldWrapper className="h-10">
                  <img
                    src={Images.Calender}
                    alt="Calender icon"
                    className="w-3.5"
                  />
                  <Field type="date" name="endDate" id="endDate" class="ml-1" />
                </FieldWrapper>
              </InputGroup>
            </div>
            <ApplyBtn disabled={InitialLoading} type="submit">
              {t("Date_filter")}
            </ApplyBtn>
          </div>

          <div className="flex gap-4 items-end">
            <InputGroup className="max-w-[200px]">
              <Label htmlFor="filterVal">{t("")}</Label>
              {/* <FieldWrapper className="h-10"> */}
              <Field
                placeholder="Search..."
                type="text"
                name="filterVal"
                id="filterVal"
                class="ml-1"
                value={filterValue}
                onChange={(event) => setFilterValue(event.target.value)}
                className="border border-slate-300 rounded-md px-4 focus:ring focus:ring-lime-300 py-2"
              />
              {/* </FieldWrapper> */}
            </InputGroup>
            <InputGroup
              className="min-w-[120px]"
              style={{ flexBasis: "content" }}
            >
              <Label htmlFor="filter">{t("Search By")}</Label>

              <Select
                autoComplete="off"
                className="w-24 px-2 bg-transparent text-sm border-none"
                style={{ height: "40px" }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);

                  //searchHandler(e.target.value);
                }}
              >
                <MenuItem value="name">Name</MenuItem>
                {/* <MenuItem value="mobile">Mobile</MenuItem> */}
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </InputGroup>
            {/* 
          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Search")}
          </ApplyBtn> */}
          </div>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const Uploader = ({ setOpenUploadModel, t }) => {
  return <UploadWrapper></UploadWrapper>;
};

const TableWrapper = tw.h1`my-10 border bg-white rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-[#4e752f] hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4 mt-8`;

export default Transaction;
