import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import { PaginationLimit } from "../../Config";
import { HiEye } from "react-icons/hi";
import { RiContactsFill } from "react-icons/ri";
import { FaFileExport } from "react-icons/fa";
import { MdInfoOutline, MdModeEdit, MdPassword } from "react-icons/md";
import { IoMdEye, IoMdPricetag } from "react-icons/io";
import { VscFileSubmodule } from "react-icons/vsc";
import { getImageListItemBarUtilityClass } from "@mui/material";
import { FaCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

const PaymentsTable = ({
  ApiData,
  setopenRoleViewModel,
  setSelectedData,
  setViewDetail,
  setEditCourse,
  setChangePasswordModel,
  setAddPlan,
}) => {
  const [data, setData] = useState([
    {
      action: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const viewLesson = (item) => {
    setViewDetail(true);
    setSelectedData(item);
  };

  const editCourse = (item) => {
    setSelectedData(item);
    setEditCourse(true);
  };

  const addPlan = (item) => {
    setSelectedData(item);
    setAddPlan(true);
  };
  useEffect(() => {
    console.log(ApiData);
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        paymentId: item.id,
        course: (
          <p className="truncate w-64 md:w-48" value={getCourse(item)}>
            {" "}
            {getCourse(item)}
          </p>
        ),
        // course: getCourse(item),
        customer: getCustomer(item),
        planType: item.playType,
        // price:
        //   item.price,
        price:
          getCoursePrice(item),
        date: moment(item.created_at).format("DD-MM-YYYY"),
        transactionId: getTransactionId(item.transactionId),
        transactionId: (
          <p className="truncate w-full md:w-32 " value={getTransactionId(item.transactionId)}>
            {" "}
            {getTransactionId(item.transactionId)}
          </p>
        ),
        status: getStatus(item.status),
      }));

      setData(tempData);
    }
  }, [ApiData]);

  // HELPER FUNCTIONS

  const getTransactionId = (id) => {
    return (
      // <span className="text-[12px] bg-sky-50 truncate text-sky-500 rounded-md py-1 px-2">
      //   {id}
      // </span>
      <span className="text-[12px] bg-sky-50  text-sky-500 rounded-md py-1 px-2 ">
        <CopyToClipboard text={id} onCopy={() => toast.success("Copied!")}>
          <button className="mr-2">
            <FaCopy />
          </button>
        </CopyToClipboard>
        {id}

      </span>
    );
  };
  const getCourse = (item) => {
    return (
      <span className="bg-indigo-100 break-keep truncate text-xs no-wrap text-indigo-700  py-1 px-2 rounded-md">
        {item.courseName}
      </span>
    );
  };
  const getCustomer = (item) => {
    return (
      <div className="flex flex-col gap-1">
        <div className="flex gap-2 items-center">
          <span className="font-semibold text-zinc-700 capitalize">
            {item.customerName}
          </span>
          <span className="text-zinc-600 font-light">#{item.customerId}</span>
        </div>
        <span className="text-zinc-600 text-xs">{item.email}</span>
      </div>
    );
  };
  const getCoursePrice = (item) => {
    if (item.country == "ZM") {
      return (<p>ZK {item.price}</p>);
    } else {
      return <p>$ {item.price}</p>;
    }
  };
  const getCategoryNames = (item) => {
    // console.log(bgColor);
    return (
      <div className="flex flex-wrap gap-1">
        {item.categoryNames.split(",").map((name, i) => {
          return (
            <span
              className="bg-violet-50 break-keep no-wrap text-violet-700 rounded-full px-2 py-1 text-xs "
              key={i}
            >
              {name}
            </span>
          );
        })}
      </div>
    );
  };
  const ActionBtns = (item) => {
    return (
      <ActionBtnWrapper>
        <Btn title="View Detail" onClick={() => viewLesson(item)}>
          <IoMdEye className="text-gray-700" />
        </Btn>
        {/* <Link to={`/courses/editCourse/${item.id}`}>
          <Btn title="Edit Course">
            <MdModeEdit className="text-gray-700" />
          </Btn>
        </Link>
        <Link to={`/modules/${item.id}`}>
          <Btn title="View Modules">
            <VscFileSubmodule className="text-gray-700" />
          </Btn>
        </Link>
        <Link to={`/courses/plans/${item.id}`}>
          <Btn title="View Plans">
            <IoMdPricetag className="text-gray-700" />
          </Btn>
        </Link> */}
        {/* <Btn title="Add Plan" onClick={() => addPlan(item)}>
          <IoMdPricetag className="text-gray-700" />
        </Btn> */}
      </ActionBtnWrapper>
    );
  };
  const getCourseImage = (image) => {
    return (
      <PropertyWrapper>
        <PropertyImagePathWrapper>
          <PropertyImage src={image} />
        </PropertyImagePathWrapper>
      </PropertyWrapper>
    );
  };

  // const getCoursePrice = (item) => {
  //   return (
  //     <div className="flex gap-2 flex-wrap text-xs">
  //       <span className="text-teal-600 rounded-full px-3 py-1 bg-teal-50">
  //         ${item?.priceUSD}
  //       </span>
  //       <span className="text-teal-600 rounded-full px-3 py-1 bg-teal-50">
  //         ZK {item?.price}
  //       </span>
  //     </div>
  //   );
  // };
  const getStatus = (status) => {
    if (status === 1) {
      return (
        <span className="bg-emerald-100 rounded-full text-emerald-600 px-4 py-1">
          Success
        </span>
      );
    } else if (status === 2) {
      return (
        <span className="bg-amber-100 rounded-full text-amber-600 px-4 py-1">
          Pending
        </span>
      );
    } else {
      return (
        <span className="bg-rose-100 rounded-full text-rose-600  px-4 py-1">
          Failed
        </span>
      );
    }
  };

  // REACT-TABLE COLUMN DEFINITION

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Transaction Id",
          accessor: "transactionId",
        },

        {
          Header: "Customer",
          accessor: "customer",
        },
      ];
    }
    return [
      {
        Header: "Transaction Id",
        accessor: "transactionId",
      },
      {
        Header: "Customer",
        accessor: "customer",
      },
      {
        Header: "Course",
        accessor: "course",
      },
      {
        Header: "Price",
        accessor: "price",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Status",
        accessor: "status",
      },
    ];
  }, [isMobileView]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, []);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                          } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (
                            cellIndex === 0 ||
                            (cellIndex === 1 && window.innerWidth < 768)
                          ) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      {/* {/ Render the details of the selected row here /} */}
                      <ul className="flex flex-col gap-2">
                        <li>
                          <strong>Course:</strong> {data[rowIndex].course}
                        </li>
                        <li>
                          <strong>Price:</strong> {data[rowIndex].price}
                        </li>
                        <li>
                          <strong>Date:</strong> {data[rowIndex].date}
                        </li>
                        <li>
                          <strong>Status:</strong> {data[rowIndex].status}
                        </li>
                        <li>
                          <strong>Actions:</strong> {data[rowIndex].action}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>

          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img
                src={Images.Arrow}
                alt="arrow"
                className="w-2 rotate-180 opacity-75"
              />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};

const UserImageWrapper = tw.div` flex items-center space-x-2`;
const UserImage = tw.img`w-5 h-5 rounded-full overflow-hidden`;

// const ActionBtnWrapper = tw.div`flex items-center space-x-3`;
// const ActionBtnWrapper = tw.div`grid xl:grid-cols-2 md:grid-cols-1 grid-cols-4 w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0`;
const ActionBtnWrapper = tw.div`flex gap-2 flex-wrap`;
const AddBtn = tw.button`grid place-items-center bg-blue-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const EditBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const Delete = tw.button`grid place-items-center bg-red-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b text-zinc-600 bg-slate-100 hover:bg-slate-200 uppercase`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden hover:bg-slate-50`;
// const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500 min-w-[120px]`;

const Td = tw.td`p-3 text-sm py-3 text-left min-w-[140px] `;
const BookingBtn = tw.button` px-8 py-1 shadow bg-green-100 text-green-700 rounded cursor-pointer`;
const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const PlainBtn = tw.button`grid place-items-center w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const Active = tw.p`w-16 h-7 text-green-700  bg-green-100 grid place-items-center rounded inline-block`;
const Inactive = tw.p`w-16 h-7 text-red-700  bg-red-100 grid place-items-center rounded inline-block`;

const SuccessBadge = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const PendingBadge = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
const PropertyWrapper = tw.div`flex flex-col md:w-auto mt-3`;
const PropertyImage = tw.img`w-10 h-10 md:w-16 md:h-16 hover:cursor-pointer rounded-md`;
const PropertyImagePathWrapper = tw.div`flex space-x-1`;

const InActiveBadge = tw.div`bg-red-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
export default PaymentsTable;
