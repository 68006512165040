import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewPlanModal = ({ setViewDetail, selectedData }) => {
  console.log(selectedData);
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
      <Wrapper>
        <Title>
          <MdViewHeadline className="mt-1" />
          View Details
        </Title>
        <SingleItem name={"Course Name"} value={selectedData?.courseName} />
        <SingleItem name={"Price ($)"} value={selectedData?.priceUSD} />
        <SingleItem name={"Price (zk)"} value={selectedData?.price} />

        <SingleItem name={"Discount % ($)"} value={selectedData?.discountUSD} />
        <SingleItem name={"Discount % (zk)"} value={selectedData?.discount} />

        <SingleItem
          name={"Created At"}
          value={moment(selectedData?.created_at).format("lll")}
        />
        <SingleItem
          name={"Updated At"}
          value={moment(selectedData?.updated_at).format("lll")}
        />
      </Wrapper>
    </Model>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    {
      <Value>
        {name == "Gender" ? (value == "1" ? "Male" : "Female") : value}
      </Value>
    }
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;

export default ViewPlanModal;
