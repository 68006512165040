import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import { RxCross2 } from "react-icons/rx";
import parse from "html-react-parser";
import { useState } from "react";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
  const [showImageModel, setShowImageModel] = useState(false);

  const handleButtonClick = () => {
    setShowImageModel(true);
  };
  const handleCloseImageModel = () => {
    setShowImageModel(false);
  };
  return (
    <>
      <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
        <Wrapper>
          <Title>
            <MdViewHeadline className="mt-1" />
            View Details
          </Title>
          <SingleItem name={"Course Name"} value={selectedData?.name} />
          <SingleItem
            name={"Rating"}
            value={selectedData?.totalRating + " (out of 5)"}
          />
          {/* <SingleItem name={"Price"} value={"Rs " + selectedData?.price1.slice(0, 4)} /> */}
          <SingleItem
            name={"Created At"}
            value={moment(selectedData?.created_at).format("lll")}
          />
          {/* <SingleItem
            className="flex-col gap-4"
            name={"Requirements"}
            value={parse(selectedData?.requirements)}
          /> */}
          <SingleItem
            name={"Description"}
            value={selectedData?.shortDescription}
          />
          <ImageWrapper>
            <SingleItem name={"Image"} />
            <button
              onClick={handleButtonClick}
              className="-mt-10 -ml-12 text-cyan-500"
            >
              Show Image
            </button>
          </ImageWrapper>
          {showImageModel && (
            <img src={selectedData?.image} alt="Displayed on button click" />
          )}
        </Wrapper>
      </Model>
      {showImageModel && (
        <Model width={`w-11/12 max-w-lg`} setOpenModel={setShowImageModel}>
          <EditImageWrapper>
            <img src={selectedData?.image} alt="Displayed on button click" />
          </EditImageWrapper>
        </Model>
      )}
    </>
  );
};

const SingleItem = ({ name, value, className }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    <Value>{value}</Value>
  </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const ImageWrapper = tw.div`grid grid-cols-1`;
const EditImageWrapper = tw.div`bg-white p-4 rounded-lg relative py-6`;

export default ViewDetailModel;
