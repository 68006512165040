// const mainDomain = "http://172.208.33.94/PROJECTS/PANUKA/cms_backend/public/";
const mainDomain = "https://cms.panukaenterprise.com/backend/public/";
// const mainDomain = "http://panukacms.think-straight.com/backend/public/";

const GetAccessToken = () => {
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
  //console.log(user);
  if (user) return user.token;

  return null;
};

const GetUserId = () => {
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

  if (user) return user.userId;

  return null;
};

const Config = {
  AxiosConfig: {
    headers: {
      authorization: `${GetAccessToken()}`,
      id: GetUserId(),
    },
  },

  userType: {
    SUPERADMIN: 1,
    MARKETING: 3,
    OPERATION: 2,
  },

  domain: mainDomain,
  apiUrl: mainDomain + "api",
  certificateUrl: mainDomain + "api/upload/CERTIFICATES/",
  docUrl: mainDomain + "api/upload/",
  sessionExpiredTime: 15, // in minutes
  idleTime: 15, // in mins
};

export const PaginationLimit = 10;

export const plans = [
  { value: 1, label: "Bronze" },
  { value: 2, label: "Silver" },
  { value: 3, label: "Gold" },
];

export const ForUs = (userId) => {
  if (userId === "e9ca6870-e80a-4df1-9b27-b6986fd6244a") {
    return true;
  }

  return false;
};

export const gTypeOfRoles = [
  { value: "1", label: "Registrar" },
  { value: "2", label: "Customers" },
  { value: "3", label: "Subadmins" },
  { value: "4", label: "Reports" },
];

export default Config;
