import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import { BoxContainer, Underline } from "../../Components/Styles/PageStyles";
import tw from "tailwind-styled-components";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import AddModuleForm from "../../Components/Courses/AddModuleForm";
import AddModuleComp from "../../Components/Module/AddModule";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import EditModuleComp from "../../Components/Module/EditModule";
import { useQuery } from "react-query";
import Loading from "../../Components/Loading";
import { useLocation } from "react-router-dom";

const EditModule = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [isProductionFetched, setIsProductionFetched] = useState(false);
  const [productionLoading, setProductionLoading] = useState(true);
  const [error, setError] = useState(null);
  const { courseId, index } = useParams();
  console.log(index);
  const navigate = useNavigate();

  const editModule = async (values) => {
    console.log(values);
    const body = new FormData();
    body.append("moduleId", id);
    body.append("moduleName", values.name);
    body.append("shortDescription", values.description);
    body.append("videos", JSON.stringify(values.videos));
    // body.append("attachments", JSON.stringify(values.moduleAttachments));
    values.attachments.forEach((file) => body.append("attachments[]", file));
    // values.pdfTitles.forEach((item) => body.append("pdfTitles[]", item));
    body.append("pdfTitles", values.pdfTitles.join(","));
    body.append("attachmentId", values.attachmentsId.join(","));
    body.append("videoId", values.videoId.join(","));
    body.append("deletedVideoIds", values.deletedVideoIds.join(","));
    body.append("deletedAttachmentIds", values.deletedAttachmentIds.join(","));

    return await axios.post(`${Config.apiUrl}/editmodule`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const onEditModuleSuccess = (res) => {
    console.log(res.data);
    if (res?.data?.status === "OK") {
      toast.success(res?.data?.msg || "Success");
      return navigate(`/modules/${courseId}`);
    }
  };
  const { isLoading, mutate: editModuleMutate } = useMutation(editModule, {
    onSuccess: onEditModuleSuccess,
    onError: (res) => {
      return toast.error(res?.response?.data?.msg || "There was an error.");
    },
  });
  const { id } = useParams();

  const getModule = async () => {
    const body = new FormData();
    body.append("moduleId", id);
    try {
      const response = await axios.post(`${Config.apiUrl}/getmodule`, body, {
        headers: {
          authorization: "Bearer" + " " + user.token,
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      });

      return response.data; // Assuming your data is in response.data
    } catch (error) {
      console.error("Error fetching production:", error);
      throw error;
    }
  };

  const {
    data: initialData,
    refetch,
    isLoading: queryLoading,
    error: queryError,
    isRefetching,
  } = useQuery("module", getModule, {
    enabled: !isProductionFetched,
    onSuccess: () => {
      setProductionLoading(false);
    },
    onError: (error) => {
      setError(error);
      setProductionLoading(false);
    },
  });

  useEffect(() => {
    if (initialData?.data && !isProductionFetched) {
      setIsProductionFetched(true);
    }
  }, [isProductionFetched, initialData]);
  console.log(initialData);
  // !----GET COURSE BY ID-------
  // const getModule = async () => {
  //   const body = new FormData();
  //   body.append("moduleId", id);

  //   return await axios.post(`${Config.apiUrl}/getmodule`, body, {
  //     headers: {
  //       authorization: "Bearer" + " " + user.token,
  //       "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  //     },
  //   });
  // };
  // const {
  //   isFetching,
  //   data: initialData,
  //   isLoading: editPageLoading,
  // } = useQuery("module", getModule, {
  //   onSuccess: (res) => {
  //     // if (res.data.status === "OK") {
  //     //   toast.success(res.data.msg || "Course fetched successfully.");
  //     //   // navigate("/courses");
  //     // }
  //     console.log(res);
  //   },
  //   onError: (res) => {
  //     toast.error(res.data.msg || "There was an error.");
  //   },
  // });
  // console.log(initialData?.data?.data?.module);
  return (
    <section className="min-h-screen w-screen bg-slate-100 py-20">
      <Navbar />
      <BoxContainer>
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col w-full items-start">
            <Title>Edit Module {parseInt(index) + 1}.0</Title>
            <Underline />
            {/* {editPageLoading && isFetching && !initialData && <Loading />}
            {!editPageLoading && initialData && ( */}
            {(productionLoading || isRefetching) && <Loading />}

            {!productionLoading && !isRefetching && <EditModuleComp data={initialData?.data?.module} videolist={initialData?.data?.videoList} attachmentList={initialData?.data?.attachmentList} editModuleMutate={editModuleMutate} isLoading={isLoading} user={user} index={index} />}

            {/* <AddModule
                addModuleMutate={addModuleMutate}
                isLoading={isLoading}
              user={user}
            /> */}
          </div>
          {/* <Uploader setOpenUploadModel={setOpenUploadModel} t={t} /> */}
        </div>
      </BoxContainer>
    </section>
  );
};
const Title = tw.h1`text-2xl text-zinc-900 font-semibold whitespace-nowrap`;
export default EditModule;
