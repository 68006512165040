import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
    InputGroup,
    FieldWrapper,
    Label,
    SubmitBtn,
    CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";

const EditModuleModel = ({
    editCourseMutate,
    editEntryLoading,
    setEditModule,
    selectedData,
    data,
}) => {
    //const deviceData = data.find((item) => item.id === deviceId);
    //console.log(selectedData);
    const categoryList = data?.categoryList?.map((item) => ({
        value: item.id,
        label: item.name,
    }));
    const courseName = data?.data?.courseList?.map((item) => ({
        value: item.name,
        label: item.name,
    }))

    console.log("hdksb " + courseName);
    const InitialValues = {
        moduleName: selectedData?.name,
        courseName: selectedData?.courseName,
        description: selectedData?.shortDescription,
        id: selectedData?.id,
    };
    console.log("name of abc " + selectedData?.name);
    const SubmitHandler = (values) => {
        editCourseMutate(values);
    }

    return (
        <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditModule}>
            <Title>Edit Module</Title>

            {editEntryLoading && <Loading />}

            {!editEntryLoading && (
                <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
                    <Form>
                        <Wrapper>

                            <InputGroup>
                                <Label htmlFor="moduleName">Module Name</Label>
                                <FieldWrapper>
                                    <Field
                                        type="text"
                                        name="moduleName"
                                        id="moduleName"
                                        autoComplete="off"
                                        className="truncate"
                                        required
                                    />
                                </FieldWrapper>
                            </InputGroup>
                            <InputGroup>
                                <Label htmlFor="courseName">Course Name</Label>
                                <FieldWrapper>
                                    <Field
                                        type="text"
                                        name="courseName"
                                        id="courseName"
                                        autoComplete="off"
                                        className="truncate"
                                        required
                                    />
                                </FieldWrapper>
                            </InputGroup>
                            <InputGroup>
                                <Label htmlFor="description">Description</Label>
                                <FieldWrapper>
                                    <Field
                                        component="textarea"
                                        rows={3}
                                        name="description"
                                        id="description"
                                        autoComplete="off"
                                        className="truncate"
                                        required
                                    />
                                </FieldWrapper>
                            </InputGroup>

                        </Wrapper>
                        <BtnWrapper>
                            <SubmitBtn type="submit">Update</SubmitBtn>
                        </BtnWrapper>
                    </Form>
                </Formik>
            )}
        </Model>
    );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditModuleModel;
