import React, { useState, useEffect } from "react";
import MailBrodcasterForm from "../../Components/MailBrodcaterForm";
import Navbar from "../../Components/Navbar";
import { BoxContainer, Underline } from "../../Components/Styles/PageStyles";
import tw from "tailwind-styled-components";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import NotFoundModel from "../../Components/NotFoundModel";

const MailBrodcaster = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const navigate = useNavigate();

  const addCourse = async (values) => {
    const body = new FormData();
    body.append("subject", values.subject);
    body.append("content", values.requirements);

    return await axios.post(`${Config.apiUrl}/mail-brodcaster`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const { isLoading, mutate: addCourseMutate } = useMutation(addCourse, {
    onSuccess: (res) => {
      if (res?.data?.status === "OK") {
        toast.success(res.data.msg || "Course added successfully.");
        return navigate("/dashboard");
      }
    },
    onError: (res) => {
      return toast.error(res?.response?.data?.msg || "There was an error.");
    },
  });
  return (
    <section className="min-h-screen w-screen bg-slate-100 py-20">
      <Navbar />

      <BoxContainer>
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col w-full items-start">
            <Title>Mail Brodcaster</Title>
            <Underline />
            <MailBrodcasterForm
              addCourseMutate={addCourseMutate}
              isLoading={isLoading}
              user={user}
            />
          </div>
        </div>
      </BoxContainer>
    </section>
  );
};

const Title = tw.h1`text-2xl text-zinc-900 font-semibold whitespace-nowrap`;
export default MailBrodcaster;
