import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { IoClose } from "react-icons/io5";

const Dropzone = ({ setThumbnail, setFieldValue, ...props }) => {
  const maxFiles = props.fileType === "pdf" ? 5 : 1;
  const maxSize = 10485760;
  // 10485760
  const accept =
    props.fileType === "image"
      ? "image/png, image/jpg, image/webp, image/jpeg"
      : "application/pdf";
  const [files, setFiles] = useState([]);

  useEffect(() => {
    console.log(files);
    if (props.fileType === "image")
      setFieldValue("thumbnail", files.length > 0 ? files[0] : null);
    if (props.fileType === "pdf" && files.length > 0) {
      // props.setAttachments(files);
      setFieldValue("attachments", files);
      const pdfs = files.map((file, idx) =>
        Object.assign({ id: idx, title: "" })
      );
      props.setAttachmentTitles(pdfs);
    }
  }, [files]);

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  const preview = files.map((file) => {
    return (
      <li key={file.name}>
        {file.name} - {(file.size / 1000000).toFixed(2)} MB
      </li>
    );
  });
  const thumbs = files.map((file) => {
    console.log(file);
    return (
      <div className="inline-flex rounded-lg w-24 h-24 p-4" key={file.name}>
        <div className="flex overflow-hidden relative">
          <img
            src={file.preview}
            className="w-auto h-full object-cover"
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.path);
            }}
          />
          <button
            className="bg-red-500/70 backdrop-blur-sm text-white text-[10px] rounded-full p-1 absolute right-0"
            onClick={() => setFiles([])}
          >
            <IoClose />
          </button>
        </div>
      </div>
    );
  });

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const tempFile = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      setFiles(tempFile);
    }
    // ! SETTING IT TO PARENT STATE
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles,
    maxSize,
    accept,
  });

  return (
    <>
      <div
        className="w-full bg-sky-50 border-2 md:text-sm text-xs text-center border-blue-300 border-dashed rounded-xl h-[100px] grid place-items-center"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </div>
      {props.fileType === "image" && <div>{thumbs}</div>}
      {props.fileType === "pdf" && <div>{preview}</div>}
    </>
  );
};

export default Dropzone;
