import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Institutes/CoursesTable";
import Navbar from "../../Components/Navbar";

import {
  Page,
  Bg,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";

import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

const InstituteCourse = () => {
  const user = useSelector((state) => state.UserReducer.user);

  const { id: institutionId } = useParams();
  const [selectedData, setSelectedData] = useState("");

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("userType", user.userType);

    body.append("instituteId", institutionId);
    return await axios.post(`${Config.apiUrl}/courseList`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getCustomersSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getCustomersError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getCustomersMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getCustomersSuccess,
    onError: getCustomersError,
  });
  useEffect(() => {
    getCustomersMutate();
  }, [institutionId]);
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {data?.data?.instituteName}'s {t("Courses")}
              </Title>
              <Underline />
            </div>
          </div>

          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data?.data?.courseList}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                userType={user.userType}
              />
            </TableWrapper>
          )}

          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data?.courseList?.length === 0) &&
            !isLoading && <NotFoundModel />}
        </BoxContainer>
      </Bg>
    </>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;
const ExportBtn = tw.button`text-white bg-[#4e752f] hover:bg-green-700 h-10 px-3 rounded flex justify-ceter items-center`;
const Button = tw.button`text-white bg-[#4e752f] hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4 mt-8`;

export default InstituteCourse;
