export const Images = {
  LoginImage2: "./images/loginnew.png",
  LoginImage3: "./images/login-image.jpg",
  PanukaLogo: "./images/LOGO.jpeg",
  PMSLogo: "./images/pms_logo.png",
  PMSNewLogo: "./images/PMS.png",
  SignUpImage: "./images/loginnew.png",
  Logo: "./images/logo.png",
  GovtLogo: "./images/emblem.png",
  MicrosoftLogo: "./images/microsoft.svg",
  NurseTextOverlay: "./images/nurse-text-overlay.png",
  SearchIcon: "./images/search-icon.svg",
  SearchBlueIcon: "./images/search-blue.svg",
  Arrow: "./images/arrow.svg",
  userImage: "./images/logo.png",
  NurseImage: "./images/avatar.png",
  Add: "./images/add.svg",
  Join: "./images/join-meeting-2.svg",
  List: "./images/list-icon.svg",
  Eye: "./images/eye.svg",
  Edit: "./images/edit.svg",
  Call: "./images/call.svg",
  Time: "./images/time.svg",
  Cross: "./images/cross.svg",
  CrossBlack: "./images/cross-black.svg",
  Calender: "./images/calender.svg",
  Email: "./images/email.svg",
  Upload: "./images/upload-outline.svg",
  ErrorImage: "./images/error-image.svg",
  SearchImage: "./images/search-image.svg",
  NotFound: "./images/no-record-found.svg",
  Send: "./images/send.svg",
  Reminder: "./images/reminder.svg",
  Family: "./images/family.svg",
  Filter: "./images/filter.svg",
  Excel: "./sample-sheet.xlsx",
  Delete: "./images/delete.svg",
  BookingIcon: "./images/booking-icon.svg",
  Mtn: "./images/mtn.png",
  Vodafone: "./images/vodafone.png",
  Airtel: "./images/airtel.png",
  Create: "./images/create.svg",
  ViewList: "./images/ViewList.svg",
  // LoginImage: "./images/loginImage.png",
  // LoginImage: "./images/Panuka_bg.png",
  LoginImage: "./images/Panuka_bg_5.png",
  DashboardBackground: "./images/dashboardbackground.jpg",
  BeamsBg: "./images/beamsbg.png",
  NotFoundPage: "./images/notfound_page.svg",
  Panuka: "./images/Panuka.png",
  // Panuka: "./images/PANUKA.png",
  Panukaa: "./images/PANUKAA.png",
  SampleCSV: "./instituteStudent.csv",
};

export default Images;
