import Model from "./Model"
import Loading from "./Loading"

const DeleteModal = ({setDelete, deleteMutate, isDeleteLoading}) => {
  return (
    <Model
              width={`xl:w-1/5 lg:w-1/4 md:w-1/3 w-1/2 max-w-lg `}
              height={`h-1/5 max-h-md`}
              setOpenModel={setDelete}
            >
              <div className="text-center w-full">
                <p className="text-xl font-semibold">Are you sure ?</p>
                <div className="flex gap-4 justify-center my-4">
                  <button
                    className="bg-slate-500 text-xs text-white px-6 py-2 rounded-lg"
                    onClick={() => setDelete(false)}
                  >
                    No
                  </button>
                  <button
                    onClick={() => deleteMutate()}
                    className="bg-blue-500 text-xs text-white px-6 py-2 rounded-lg"
                  >
                    {isDeleteLoading ? (
                      <Loading
                        height={16}
                        width={16}
                        noPadding={true}
                        color={"#ffffff"}
                      />
                    ) : (
                      "Yes"
                    )}
                  </button>
                </div>
              </div>
            </Model>
  )
}
export default DeleteModal