import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Model from "../Model";
import * as Yup from "yup";

const EditCategoryModel = ({
  editCategoryMutate,
  editEntryLoading,
  setEditUser,
  selectedData,
}) => {

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .trim() // Trim whitespace
      .required("Category Name is required") // Require a value
      .matches(/^\S+(?:\s+\S+)*$/, "Category Name should not start or end with space") // Check for leading or trailing spaces
  });

  const InitialValues = {
    name: selectedData?.name,
    id: selectedData?.id,
  };

  const SubmitHandler = (values) => {
    editCategoryMutate(values);
  }
  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditUser}>
      <Title>Edit Category</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler} enableReinitialize validationSchema={ValidationSchema}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="name">Name</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
                <ErrorMessage name="name" component="div" className="error bg-red-100 text-red-500 p-2 text-xs" />
              </InputGroup>

            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditCategoryModel;
