import Navbar from "./Components/Navbar";
import { Route, Routes } from "react-router-dom";
import React, { Suspense, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Config from "./Config";
import moment from "moment";
// import Login from "./Pages/LoginCopyPage";
// import Login from "./Pages/LoginNew";
// import Login from "./Pages/Login";
import Login from "./Pages/LoginNEW";
import DashBoardNew from "./Pages/Admin/DashBoardNew";

import Module from "./Pages/Admin/Module";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import RegisterPage from "./Pages/RegisterPage";

import ForgotPasswordPage from "./Pages/ForgotPassword";
import OtpPage from "./Pages/Otp";
import { useIdleTimer } from "react-idle-timer";
import AddCourse from "./Pages/Admin/AddCourse";
import "./i18";
import { Remove_User } from "./Redux/actions";

import Categories from "./Pages/Admin/Categories";
import AddModule from "./Pages/Admin/AddModule";
import EditModule from "./Pages/Admin/EditModule";
import Plans from "./Pages/Admin/Plans";
import Loading from "./Components/Loading";
import Spinner from "./Components/Spinner";
import EditCourse from "./Pages/Admin/EditCourse";
import Users from "./Pages/Admin/Users";
import Courses from "./Pages/Admin/Courses";
import Customers from "./Pages/Admin/Customer";
import Payments from "./Pages/Admin/Payments";
import Institutes from "./Pages/Admin/Institutes";
import AddInstituteStudents from "./Pages/Admin/AddInstituteStudents";

import NotFoundModel from "./Components/NotFoundModel";
import NotfoundPage from "./Components/NotfoundPage";
import Transaction from "./Pages/Admin/Transaction";
import CommunityTab from "./Pages/Admin/Community";
import UnpublishCourses from "./Pages/Admin/UnpublishCourses";
import MailBrodcaster from "./Pages/Admin/MailBrodcaster";
import Books from "./Pages/Admin/Books";
import SubscribeBook from "./Pages/Admin/SubscribeBook";
import InstituteStudent from "./Pages/Admin/InstituteStudent";
import InstituteCourse from "./Pages/Admin/InstituteCourse";

function App() {
  const user = useSelector((state) => state.UserReducer.user);

  const dispatch = useDispatch();

  const isSessionExpired = () => {
    if (user) {
      const expireAt = user?.expireAt;
      const currentTime = moment().valueOf();
      const isExpired = moment(currentTime).isAfter(expireAt);
      if (isExpired) {
        window.location.href = "/";
        dispatch(Remove_User());
      }
    }
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle: () => isSessionExpired(),
    timeout: 1000 * 60 * Config.idleTime,
  });

  useEffect(() => isSessionExpired(), []);

  if (!user)
    return (
      <>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
        <Routes>
          {/* <Route path="/register" element={<SignupPage />} /> */}
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/get-otp" element={<OtpPage />} />
          <Route path="*" index element={<Login />} />
        </Routes>
      </>
    );

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />

      {/* {isAdmin ? <AdminNavbar /> : <Navbar />} */}

      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}

        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/login" element={<Login />} />
        {/* SUPERADMIN */}
        {user?.userType == Config.userType.SUPERADMIN && (
          <>
            <Route path="*" element={<NotfoundPage />} />
            <Route path="/" element={<DashBoardNew />} />
            <Route path="/dashboard" element={<DashBoardNew />} />
            <Route path="/users" element={<Users />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/institutes" element={<Institutes />} />
            <Route
              path="/institutes/addrecords/:id"
              element={<AddInstituteStudents />}
            />
            <Route
              path="/institutes/studentList/:id"
              element={<InstituteStudent />}
            />
            <Route
              path="/institutes/courses/:id"
              element={<InstituteCourse />}
            />
            <Route path="/courses" element={<Courses />} />
            <Route path="/modules/:id" element={<Module />} />
            <Route path="/module" element={<Module />} />
            <Route path="/courses/addCourse" element={<AddCourse />} />
            <Route
              path="/modules/addModule/:id/:length"
              element={<AddModule />}
            />
            <Route
              path="/modules/editModule/:id/:courseId/:index"
              element={<EditModule />}
            />
            <Route path="/courses/plans/:id" element={<Plans />} />
            <Route path="/courses/editCourse/:id" element={<EditCourse />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/transaction/:id" element={<Transaction />} />
            <Route path="/community" element={<CommunityTab />} />
            <Route path="/unpublish-courses" element={<UnpublishCourses />} />
            <Route path="/mail-brodcaster" element={<MailBrodcaster />} />
            <Route path="/books" element={<Books />} />
            <Route path="/subscribed" element={<SubscribeBook />} />
          </>
        )}

        {/* OPERATION */}
        {user?.userType == Config.userType.OPERATION && (
          <>
            <Route path="*" element={<NotfoundPage />} />
            <Route path="/" element={<DashBoardNew />} />
            <Route path="/dashboard" element={<DashBoardNew />} />
            <Route path="/users" element={<Users />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/modules/:id" element={<Module />} />
            <Route path="/module" element={<Module />} />
            <Route path="/courses/addCourse" element={<AddCourse />} />
            <Route
              path="/modules/addModule/:id/:length"
              element={<AddModule />}
            />
            <Route
              path="/modules/editModule/:id/:courseId/:index"
              element={<EditModule />}
            />
            <Route path="/courses/plans/:id" element={<Plans />} />
            <Route path="/courses/editCourse/:id" element={<EditCourse />} />
            {/* <Route path="/payments" element={<Payments />} />
            <Route path="/transaction/:id" element={<Transaction />} /> */}
            <Route path="/community" element={<CommunityTab />} />
            <Route path="/unpublish-courses" element={<UnpublishCourses />} />
          </>
        )}

        {/* Marketing */}
        {user?.userType == Config.userType.MARKETING && (
          <>
            <Route path="*" element={<NotfoundPage />} />
            <Route path="/" element={<DashBoardNew />} />
            <Route path="/dashboard" element={<DashBoardNew />} />
            <Route path="/users" element={<Users />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/courses" element={<Courses />} />
            <Route path="/modules/:id" element={<Module />} />
            <Route path="/module" element={<Module />} />
            <Route path="/courses/addCourse" element={<AddCourse />} />
            <Route
              path="/modules/addModule/:id/:length"
              element={<AddModule />}
            />
            <Route
              path="/modules/editModule/:id/:courseId/:index"
              element={<EditModule />}
            />
            <Route path="/courses/plans/:id" element={<Plans />} />
            <Route path="/courses/editCourse/:id" element={<EditCourse />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/transaction/:id" element={<Transaction />} />
            <Route path="/community" element={<CommunityTab />} />
            <Route path="/unpublish-courses" element={<UnpublishCourses />} />
            {/* <Route path="/unpublish-courses" element={<UnpublishCourses />} /> */}
          </>
        )}
      </Routes>

      {/* <Footer /> */}
    </>
  );
}

export default App;
