import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { IoClose } from "react-icons/io5";
import { FaFileUpload } from "react-icons/fa";

const Dropzone = ({ onAddFile, onRemoveFile, accept, multiple }) => {
  const [file, setFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const tempFile = acceptedFiles[0];
        setFile(tempFile);
        onAddFile(tempFile);
      }
    },
    [onAddFile]
  );

  useEffect(() => {

    return () => {
      if (file) {
        URL.revokeObjectURL(file.preview);
      }
    };
  }, [file]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept,
    multiple,
  });

  return (
    <>
      <div
        className="bg-sky-100 rounded-xl flex items-center justify-center h-36 text-blue-600 text-3xl"
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p className="bg-blue-300 p-3 text-xl rounded-full">
          <FaFileUpload />
        </p>
      </div>
      {file && (
        <div className="mt-2 flex items-center">
          <p>{file.name}</p>
          <button
            className="bg-red-500 text-white rounded p-2 mt-2 ml-2"
            onClick={() => {
              setFile(null);
              onRemoveFile();
            }}
          >
            <IoClose />
          </button>
        </div>
      )}
    </>
  );
};

export default Dropzone;
