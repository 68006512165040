import AddCourseForm from "../../Components/Courses/AddCourseForm";
import Navbar from "../../Components/Navbar";
import { BoxContainer, Underline } from "../../Components/Styles/PageStyles";
import tw from "tailwind-styled-components";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import EditCourseForm from "../../Components/Courses/EditCourseForm";
import Loading from "../../Components/Loading";

const EditCourse = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const navigate = useNavigate();
  const { id } = useParams();

  // !----GET COURSE BY ID-------
  const getCourse = async () => {
    const body = new FormData();
    body.append("courseId", id);

    return await axios.post(`${Config.apiUrl}/getcourse`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const { isFetching, data: initialData } = useQuery("course", getCourse, {
    onSuccess: (res) => {
      // if (res.data.status === "OK") {
      //   toast.success(res.data.msg || "Course fetched successfully.");
      //   // navigate("/courses");
      // }
      console.log(res);
    },
    onError: (res) => {
      toast.error(res?.response?.data?.msg || "There was an error.");
    },
  });

  // !--------EDIT COURSE BY ID----------------
  const editCourse = async (values) => {
    // const categories = values.categories.map((category) => category.value);
    // console.log(categories, typeof categories);
    const body = new FormData();
    body.append("name", values.name);
    // categories.map((category) => body.append("categories[]", category));
    body.append("categories", values.categories.value);
    body.append("courseId", id);
    body.append("price", values.price);
    // body.append("duration", `${values.hours}:${values.minutes}`);
    body.append("description", values.description);
    body.append("introUrl", values.introVideo);
    body.append("requirements", values.requirements);
    body.append("thumbnail", values.thumbnail);
    return await axios.post(`${Config.apiUrl}/editcourse`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const { isLoading, mutate: editCourseMutate } = useMutation(editCourse, {
    onSuccess: (res) => {
      if (res.data.status === "OK") {
        toast.success(res.data.msg || "Course edited successfully.");
        return navigate("/courses");
      }
    },
    onError: (res) => {
      console.log(res.response);
      // console.log(res.error.data, res.error, res.error.msg)
      // return toast.error(res.error.data.msg || "There was an error.");
      return toast.error(res?.response?.data?.msg || "An Error Occured");
      // return;
    },
  });

  //   initialData && console.log(initialData?.data?.data?.courseList[0]);
  return (
    <section className="min-h-screen w-screen bg-slate-100 py-20">
      <Navbar />
      <BoxContainer>
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col w-full items-start">
            <Title>
              Edit Course <span className="text-zinc-500 text-xl">#{id}</span>
            </Title>
            <Underline />
            {initialData && !isFetching && (
              <EditCourseForm
                editCourseMutate={editCourseMutate}
                data={initialData?.data?.data?.courseList[0]}
                categoryData={initialData?.data?.data?.categoryList}
                isLoading={isLoading}
                user={user}
              />
            )}
          </div>
          {/* <Uploader setOpenUploadModel={setOpenUploadModel} t={t} /> */}
        </div>
        {isFetching && !initialData && <Loading />}
      </BoxContainer>
    </section>
  );
};

const Title = tw.h1`text-2xl text-zinc-900 font-semibold whitespace-nowrap`;
export default EditCourse;
