import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import tw from "tailwind-styled-components";
import { twMerge } from "tailwind-merge";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Parser from "html-react-parser";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Dropzone from "./Dropzone";
import Loading from "react-loading";
import { IoIosAdd } from "react-icons/io";
import { FaTrashAlt, FaTrashRestore } from "react-icons/fa";
import Spinner from "../Spinner";

const animatedComponents = makeAnimated();

const AddModuleForm = ({ user, isLoading, addModuleMutate }) => {
  // ! STATES
  const [attachmentTitles, setAttachmentTitles] = useState(null);
  const [videos, setVideos] = useState([]);
  const [index, setIndex] = useState(0);

  const initialValues = {
    name: "",
    description: "",
  };

  // ! HANDLE ADD VIDEO
  const handleAddVideo = () => {
    setVideos((prev) => [
      ...prev,
      { id: index + 1, url: "", title: "", mins: "", secs: "" },
    ]);
    setIndex((prev) => prev + 1);
  };

  const handleChange = (e, i) => {
    console.log(e.target);
    const { name, value } = e.target;
    const onChangeVal = [...videos];
    onChangeVal[i][name] = value;
    setVideos(onChangeVal);
  };

  // YUP VALIDATION
  const formSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
  });
  // SUBMIT HANDLER
  const handleSubmit = (values) => {
    // ! CREATE ATTACHMENTS OBJECT
    // const moduleAttachments = values.attachments.map((attachment, i) =>
    //   Object.assign({ file: attachment, name: attachmentTitles[i].title })
    // );
    // console.log(moduleAttachments);
    // ! PDF TITLES
    const pdfTitles = attachmentTitles.map((item) => item.title);

    // ! CREATE VIDEO OBJECTS
    const videoUrls = videos.map((video) =>
      Object.assign({
        url: video.url,
        title: video.title,
        duration: `${video.mins}:${video.secs}`,
      })
    );
    videoUrls.push(
      Object.assign({
        url: values.videos,
        title: values.vidTitle,
        duration: `${values.vidMins}:${values.vidSecs}`,
      })
    );
    const data = {
      ...values,
      videos: videoUrls,
      pdfTitles: pdfTitles,
    };
    console.log(data);
    addModuleMutate(data);
  };
  return (
    <article className="mt-10 w-full m-auto p-8 rounded-lg bg-white shadow-md">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
      >
        {({ values, setFieldValue, errors, touched }) => {
          // console.log(errors);
          return (
            <Form className="flex flex-col gap-y-4">
              <GroupWrapper>
                <FieldWrapper className="flex-1 flex-wrap">
                  <label>Module Name</label>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter the module name..."
                    as={Input}
                  />
                  {errors.name && touched.name ? (
                    <p className="bg-red-100 text-red-500 p-2 text-xs">
                      {errors.name}
                    </p>
                  ) : null}
                </FieldWrapper>
              </GroupWrapper>
              <FieldWrapper>
                <label>Short Description</label>
                <Field
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Enter the module description"
                  as={TextArea}
                />
                {errors.description && touched.description ? (
                  <p className="bg-red-100 text-red-500 p-2 text-xs">
                    {errors.description}
                  </p>
                ) : null}
              </FieldWrapper>

              <FieldWrapper className="flex-wrap">
                <label>Videos (url)</label>
                <div className="flex items-center gap-4">
                  <Field
                    type="text"
                    name="videos"
                    id="videos"
                    placeholder="https://www.youtube.com/watch?v=xxx..."
                    as={Input}
                  />
                  <Field
                    type="text"
                    name="vidTitle"
                    id="vidTitle"
                    placeholder="Enter Video Title"
                    as={Input}
                  />
                  <Field
                    type="number"
                    name="vidMins"
                    id="vidMins"
                    min="0"
                    max="60"
                    placeholder="Enter Video Duration(mins)"
                    as={Input}
                  />
                  <Field
                    type="number"
                    name="vidSecs"
                    id="vidSecs"
                    min="0"
                    max="60"
                    placeholder="Enter Video Duration(secs)"
                    as={Input}
                  />
                  <button
                    type="button"
                    onClick={handleAddVideo}
                    className="rounded-full bg-emerald-100 text-emerald-500 text-xl p-2"
                  >
                    <IoIosAdd />
                  </button>
                </div>
                {videos &&
                  videos.map((video, idx) => (
                    <div key={idx} className="flex items-center gap-4">
                      <Field
                        type="text"
                        name="url"
                        value={video.url}
                        placeholder="https://www.youtube.com/watch?v=xxx..."
                        as={Input}
                        onChange={(e) => handleChange(e, idx)}
                      />
                      <Field
                        type="text"
                        name="title"
                        value={video.title}
                        placeholder="Enter Video title"
                        as={Input}
                        onChange={(e) => handleChange(e, idx)}
                      />
                      <Field
                        type="number"
                        name="mins"
                        value={video.mins}
                        min="0"
                        max="60"
                        placeholder="Enter Video duration(mins)"
                        as={Input}
                        onChange={(e) => handleChange(e, idx)}
                      />
                      <Field
                        type="number"
                        name="secs"
                        value={video.secs}
                        min="0"
                        max="60"
                        placeholder="Enter Video Duration(secs)"
                        as={Input}
                        onChange={(e) => handleChange(e, idx)}
                      />

                      {/* DELETE INPUT FIELD  */}
                      <button
                        type="button"
                        onClick={() => {
                          console.log(idx);
                          const deleteVal = [...videos];
                          deleteVal.splice(idx, 1);
                          setVideos(deleteVal);
                        }}
                        className="p-2 bg-rose-100 text-rose-500 rounded-full"
                      >
                        {<FaTrashAlt />}
                      </button>
                    </div>
                  ))}
              </FieldWrapper>

              <FieldWrapper>
                <label>
                  Attachments
                </label>
                <Field id="attachments" name="attachments" type="text">
                  {(props) => (
                    <Dropzone
                      setAttachmentTitles={setAttachmentTitles}
                      fields={props.field}
                      setFieldValue={props.form.setFieldValue}
                      componentFor="attachments"
                      accept="application/pdf"
                      fileType="pdf"
                      File={props.field.value ? [props.field.value] : null}
                    />
                  )}
                </Field>
                <span className="italic text-sm text-zinc-600">
                  *Document size should be less than 2 MB.*
                </span>
              </FieldWrapper>
              {attachmentTitles && (
                <FieldWrapper>
                  <label>Attachment Titles</label>
                  {attachmentTitles.map((attachment, idx) => (
                    <div key={idx}>
                      <Field
                        required
                        as={Input}
                        autoFocus={idx == 0}
                        name="title"
                        value={attachmentTitles?.title}
                        onChange={(e) => {
                          const { name, value } = e.target;
                          const pdfTitles = [...attachmentTitles];
                          pdfTitles[idx][name] = value;
                          setAttachmentTitles(pdfTitles);
                        }}
                      />
                    </div>
                  ))}
                </FieldWrapper>
              )}
              <SubmitBtn
                type="submit"
                className="flex items-center justify-center h-12"
              >
                {isLoading ? (
                  <Spinner className="text-white w-3 h-3" />
                ) : (
                  "Submit"
                )}
              </SubmitBtn>
            </Form>
          );
        }}
      </Formik>
    </article>
  );
};

const FieldWrapper = tw.div`${(props) =>
  twMerge(`flex flex-col gap-2`, props.className)}`;
const GroupWrapper = tw.div`flex flex-col md:flex-row md:items-start gap-4 `;
const Input = tw.input`bg-slate-50 text-sm rounded-md p-2`;
const TextArea = tw.textarea`bg-slate-50 text-sm rounded-md p-2`;
const SubmitBtn = tw.button`bg-black rounded-lg text-center text-slate-100 py-2 px-4 w-1/2 m-auto`;

export default AddModuleForm;
