


import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const AddCategoryModel = ({
  AddCategoryLoading,
  postCategoryMutate,
  setOpenUploadModel,
  selectedData,
  data,
}) => {
  const { t, i18n } = useTranslation();
  const InitialValues = {
    id: selectedData?.id,
    categoryName: ""
  };


  const ValidationSchema = Yup.object().shape({
    categoryName: Yup.string()
      .trim() // Trim whitespace
      .required("Category Name is required")
      .matches(/^\S+(?:\s+\S+)*$/, "Category Name should not start or end with space")
  });

  const SubmitHandler = (values) => {
    postCategoryMutate(values);
  };

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Category</Title>

      {AddCategoryLoading && <Loading />}

      {!AddCategoryLoading && (
        <Formik
          initialValues={InitialValues}
          validationSchema={ValidationSchema} // Pass the validation schema
          onSubmit={SubmitHandler}
        >
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="categoryName">Category Name</Label>
                <FieldWrapper>
                  <Field
                    name="categoryName"
                    id="categoryName"
                    type="text"
                    autoComplete="off"
                    required
                  />

                </FieldWrapper>
                <ErrorMessage name="categoryName" component="div" className="error bg-red-100 text-red-500 p-2 text-xs" />
              </InputGroup>
              <Field
                type="hidden"
                name="id"
                value={selectedData?.id || ""}
              />
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddCategoryModel;
