import React, { useState } from "react";
import Model from "../Model";
import { SubmitBtn, PreviewBtn } from "../Styles/InputStyles";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import Loading from "../Loading";

const DeleteCourse = ({
    setDeleteCategory,
    selectedData,
    categoryDeleteMutate,
    categoryDeleteLoading,
}) => {
    const handleDelete = () => {
        // Make an API call to delete the comment
        categoryDeleteMutate({ id: selectedData?.id });
    };

    return (
        <Model width={"w-11/12 max-w-lg"} setOpenModel={setDeleteCategory}>
            {categoryDeleteLoading && <Loading />}
            {!categoryDeleteLoading && (
                <>
                    <Title>Are you sure to delete this course? </Title>

                    <div className="w-full flex items-center justify-center space-x-10 mt-8">
                        <Delete onClick={handleDelete}> Yes</Delete>
                        <Cancel onClick={() => setDeleteCategory(false)}>No</Cancel>
                    </div>
                </>
            )}
        </Model>
    );
};

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const Delete = tw.button`px-8 py-3 text-sm bg-red-500 text-white rounded hover:bg-red-600`;
const Cancel = tw.button`px-8 py-3 text-sm bg-gray-100 text-gray-600 rounded hover:bg-gray-200`;

export default DeleteCourse;
