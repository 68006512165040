import React, { useState } from "react";
import * as Yup from "yup"; // Import Yup for validation
import tw from "tailwind-styled-components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Spinner from "../Spinner";
import { plans } from "../../Config";

const AddPlanModal = ({
  AddUserLoading,
  postModuleMutate,
  setOpenUploadModel,
  selectedData,
  data,
  setAddPlan,
  postAddPlan,
  AddPlanLoading,
}) => {
  const InitialValues = { discountUsd: "", discountZk: "", priceUsd: "", priceZK: "" };
  const validationSchema = Yup.object().shape({
    priceUsd: Yup.number()
      .min(0, "Price cannot be negative")
      .required("Price (in USD) is required"),
    priceZk: Yup.number()
      .min(0, "Price cannot be negative")
      .required("Price (in ZK) is required"),
    discountUsd: Yup.number()
      .max(100, "Discount cannot be more than 100%")
      .min(0, "Discount cannot be negative"),
    discountZk: Yup.number()
      .max(100, "Discount cannot be more than 100%")
      .min(0, "Discount cannot be negative"),
  });
  console.log(data);
  const SubmitHandler = (values) => {
    console.log("clicked");
    console.log(values);
    const data = {
      ...values,
      // priceZk: usdToZk(values.priceUsd),
      // discountZk: usdToZk(values.discountUsd),
    };
    postAddPlan(data);
  };
  // const usdToZk = (usd) => {
  //   return (usd * 25.49).toFixed(2);
  // };

  const planDuration = [
    { value: 4, label: "4 Months" },
    { value: 8, label: "8 Months" },
    { value: 12, label: "12 Months" },
  ];

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setAddPlan}>
      <Title>Add Plan</Title>

      <Formik initialValues={InitialValues} onSubmit={SubmitHandler} validationSchema={validationSchema}>
        {({ values, errors }) => (
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="courseName">Plan</Label>

                <Field name="plan" id="plan" autoComplete="off">
                  {(props) => (
                    <Select
                      required
                      options={plans}
                      // defaultInputValue={plans[0].label}
                      classNamePrefix="select"
                      onChange={(val) => {
                        props.form.setFieldValue("plan", val.value);
                      }}
                    />
                  )}
                </Field>
              </InputGroup>
              {/* <InputGroup>
                <Label htmlFor="courseName">Plan Duration</Label>

                <Field name="planDuration" id="planDuration" autoComplete="off">
                  {(props) => (
                    <Select
                      required
                      options={planDuration}
                      // defaultInputValue={plans[0].label}
                      classNamePrefix="select"
                      onChange={(val) => {
                        props.form.setFieldValue("planDuration", val.value);
                      }}
                    />
                  )}
                </Field>
              </InputGroup> */}

              <InputGroup>
                <Label htmlFor="priceUsd">
                  Price <i>(in usd)</i>
                </Label>
                <FieldWrapper className="relative">
                  <span className="px-2 text-neutral-600">$</span>
                  <Field
                    type="number"
                    name="priceUsd"
                    id="priceUsd"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
                <ErrorMessage name="priceUsd" component="div" className="text-red-500 text-sm" />
                <Label htmlFor="priceZk">
                  Price <i>(in zk)</i>
                </Label>
                <FieldWrapper className="relative">
                  <span className="px-2 text-neutral-600 text-xs">ZK</span>
                  <Field
                    type="number"
                    name="priceZk"
                    id="priceZk"

                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
                <ErrorMessage name="priceZk" component="div" className="text-red-500 text-sm" />
              </InputGroup>
              <InputGroup>
                <Label htmlFor="discountUsd">
                  USD Discount <i>(in %)</i>
                </Label>
                <FieldWrapper className="relative">
                  <span className="px-2 text-neutral-600">%</span>
                  <Field
                    type="number"
                    name="discountUsd"
                    id="discountUsd"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
                <ErrorMessage name="discountUsd" component="div" className="text-red-500 text-sm" />
                <Label htmlFor="discountZk">
                  ZK Discount <i>(in %)</i>
                </Label>
                <ErrorMessage name="discountZk" component="div" className="text-red-500 text-sm" />
                <FieldWrapper className="relative">
                  <span className="px-2 text-neutral-600 text-xs">%</span>
                  <Field
                    type="number"
                    name="discountZk"

                    id="discountZk"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>

              </InputGroup>
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">
                {AddPlanLoading ? (
                  <Spinner className="text-white w-3 h-3" />
                ) : (
                  "Add"
                )}
              </SubmitBtn>
            </BtnWrapper>
          </Form>
        )}
      </Formik>
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;

const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;

export default AddPlanModal;
