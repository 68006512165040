import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import Config, { PaginationLimit } from "../../Config";
import { HiEye } from "react-icons/hi";
import { RiContactsFill } from "react-icons/ri";
import { FaFileExport } from "react-icons/fa";
import { MdInfoOutline, MdModeEdit, MdPassword } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
// import Config from "../../Config";
const Table = ({
  ApiData,
  setopenRoleViewModel,
  setSelectedData,
  setEditUser,
  setChangePasswordModel,
  setViewDetail,
  userType,
}) => {
  console.log(Config.userType.OPERATION)
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };

  const roleViewModel = (roles) => {
    setopenRoleViewModel(true);
    setSelectedData(roles);
  };

  const edit = (item) => {
    setEditUser(true);
    setSelectedData(item);
  };

  const updatePassword = (item) => {
    setChangePasswordModel(true);
    setSelectedData(item);
  };
  const viewDetail = (item) => {
    setViewDetail(true);
    setSelectedData(item);
  };
  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        name: getName(item),
        mobile: item.mobile,
        email: item.email,
        date: moment(item.created_at).format("DD-MM-YYYY"),
        status: getStatus(item.status),
        payStatus: getPayStatus(item),
        action: ActionBtns(item),
      }));

      setData(tempData);
    }
  }, [ApiData]);

  const getName = (item) => {
    return (
      <span className="font-semibold text-zinc-900 capitalize flex gap-2 items-center">
        <span className="bg-indigo-100 text-blue-600 p-1 rounded-md text-xs">
          #{item.id}
        </span>
        {item.name}
      </span>
    );
  };
  const ActionBtns = (item) => {
    return (
      <ActionBtnWrapper>
        {userType != Config.userType.MARKETING && (
          <Btn title="Edit Customer" onClick={() => edit(item)}>
            <MdModeEdit className="text-gray-700" />
          </Btn>)}
        <Btn title="View Detail" onClick={() => viewDetail(item)}>
          <IoMdEye className="text-gray-700" />
        </Btn>
      </ActionBtnWrapper>
    );
  };
  const getStatus = (status) => {
    if (status == 1) {
      return <InActiveBadge>Unverified</InActiveBadge>;
    } else if (status == 2) {
      return <SuccessBadge>Active</SuccessBadge>;
    } else {
      return <PendingBadge>Inactive</PendingBadge>;
    }
  };


  const getPayStatus = (item) => {
    if (item.paymentStatus == 1) {
      return (<Link to={`/transaction/${item.id}`}><Active title="View Transaction" >Yes</Active></Link>);
    } else {
      return (<Link to={`/transaction/${item.id}`}><Unpaid title="View Transaction" >No</Unpaid></Link>);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Name",
          accessor: "name",
          // Unique ID for the mobile version of the "User" column
        },
        {
          Header: "Email",
          accessor: "email",
          // Unique ID for the mobile version of the "User" column
        },
      ];
    }

    // Columns for larger screens
    return [
      {
        Header: "Name",
        accessor: "name",
        // Unique ID for the desktop version of the "User" column
      },
      {
        Header: "Email",
        accessor: "email",
        // Unique ID for the desktop version of the "User" column
      },

      {
        Header: "Phone Number",
        accessor: "mobile",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      // {
      //   Header: "Company Name",
      //   accessor: "companyName",
      // },

      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: userType != Config.userType.OPERATION ? "Pay Status" : "",
        accessor: userType != Config.userType.OPERATION ? "payStatus" : "undefined",
      },
      // {
      //   Header: "Pay Status",
      //   accessor: "payStatus",
      // },
      {
        Header: "Action",
        accessor: "action",
      },
    ];
  }, [isMobileView]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, []);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      <img
                        src={Images.Arrow}
                        alt="down arrow"
                        className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                          } w-1.5 inline-block ml-1.5`}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (cellIndex === 0 || cellIndex === 1 && window.innerWidth < 768) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      {/* {/ Render the details of the selected row here /} */}
                      <ul className="flex flex-col gap-2">
                        {/* <li>
                          <strong>Email:</strong> {data[rowIndex].email}
                        </li> */}
                        <li>
                          <strong>Phone Number:</strong> {data[rowIndex].mobile}
                        </li>
                        <li>
                          <strong>Date:</strong> {data[rowIndex].date}
                        </li>
                        <li className="flex gap-2">
                          <strong>Status:</strong> {data[rowIndex].status}
                        </li>
                        {/* <li> */}
                        {/* <strong>Company Name:</strong> */}
                        {/* {data[rowIndex].companyName} */}
                        {/* </li> */}
                        {/* <li>
                          <strong>Status:</strong>
                          {data[rowIndex].status}
                        </li> */}
                        <li>
                          <strong>Action:</strong> {data[rowIndex].action}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>

          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img
                src={Images.Arrow}
                alt="arrow"
                className="w-2 rotate-180 opacity-75"
              />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};

const UserImageWrapper = tw.div` flex items-center space-x-2`;
const UserImage = tw.img`w-5 h-5 rounded-full overflow-hidden`;

// const ActionBtnWrapper = tw.div`flex items-center space-x-3`;
const ActionBtnWrapper = tw.div`grid xl:grid-cols-2 md:grid-cols-1 grid-cols-4 w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0`;
const AddBtn = tw.button`grid place-items-center bg-blue-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const EditBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const Delete = tw.button`grid place-items-center bg-red-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const CustomTable = tw.table` w-full text-zinc-600`;
const Thead = tw.thead`border-b uppercase text-zinc-500 bg-slate-100`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden hover:bg-slate-50 `;
// const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;

const Td = tw.td`p-3 text-sm md:p-3 text-left`;
const BookingBtn = tw.button` px-8 py-1 shadow bg-green-100 text-green-700 rounded cursor-pointer`;
const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const PlainBtn = tw.button`grid place-items-center w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const Active = tw.p`bg-green-100 text-green-700 text-xs border-green-300 border  h-7 flex items-center justify-center rounded w-10 font-bold`;
const Inactive = tw.p`bg-red-100 text-red-700 text-xs border-red-300 border  h-7 flex items-center justify-center rounded w-10 font-bold`;

const PendingBadge = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;

const SuccessBadge = tw.div`bg-teal-100 text-emerald-600 text-xs grid font-bold place-items-center rounded-lg px-2 py-1 w-24 h-7`;
const InActiveBadge = tw.div`bg-rose-100 text-rose-600 font-bold text-xs grid place-items-center rounded-md px-2 py-1 w-24 h-7`;
const Unpaid = tw.p`bg-gray-100 text-gray-700 text-xs border-gray-300 border  h-7 flex items-center justify-center rounded w-10 font-bold`;
export default Table;
