import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Images from "../../Images";
import Loading from "../../Components/Loading";
import Table from "../../Components/Module/Table";
import Navbar from "../../Components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import ViewFilesModal from "../../Components/Module/ViewFilesModal";
import moment from "moment";

import {
  Page,
  Bg,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import { InputGroup, Label } from "../../Components/Styles/InputStyles";

import NotFoundModel from "../../Components/NotFoundModel";
import { SmallBtn } from "../../Components/Styles/InputStyles";
import { useSelector } from "react-redux";
import { ForUs } from "../../Config";
import { MdAdd } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import EditModuleModel from "../../Components/Module/EditModuleModel";
import AddModuleModel from "../../Components/Module/AddModuleModel";
import Model from "../../Components/Model";
import Spinner from "../../Components/Spinner";
import DeleteModal from "../../Components/DeleteModal";

const Module = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openRoleViewModel, setopenRoleViewModel] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [editUser, setEditUser] = useState(false);
  const [editModule, setEditModule] = useState(false);
  const [addModule, setAddModule] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [viewFiles, setViewFiles] = useState(false);
  const [deleteModule, setDeleteModule] = useState(false);

  console.log(filterValue);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { id: courseId } = useParams();

  const initialValues = {};

  // !-------------------FETCH MODULES----------------------
  const fetchFunction = async (values) => {
    const body = new FormData();
    body.append("courseId", courseId);
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    return await axios.post(`${Config.apiUrl}/getmodules`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getModulesSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getModulesError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getModulesMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getModulesSuccess,
    onError: getModulesError,
  });

  useEffect(() => {
    // *----------DEBOUNCE--------
    const timeout = setTimeout(() => {
      getModulesMutate();
    }, 500);
    return () => clearTimeout(timeout);
  }, [filterValue]);

  const submitHandler = (values) => {
    setFilterValue(values.filterVal);
    getModulesMutate();
  };

  const length = data?.data?.data?.length || 0;

  //------- Add Single Entry -------
  const AddModuleFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.name);
    body.append("age", values.age);
    body.append("village", values.village);
    body.append("district", values.district);
    body.append("gender", values.gender);
    body.append("phone", values.phone);
    return await axios.post(`${Config.apiUrl}/create-farmer`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddModuleSuccess = (data) => {
    console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      getModulesMutate();
    } else {
      toast.error(data?.response?.data?.msg);
    }
    setSelectedData("");
    setOpenUploadModel(false);
  };
  console.log(data?.data?.msg);

  const onAddModuleError = (data) => {
    console.log(data?.response?.data?.msg);
    setOpenUploadModel(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg || "An Error Occurred");
  };

  const { isLoading: AddSubAgentLoading, mutate: postModuleMutate } =
    useMutation(AddModuleFunction, {
      onSuccess: onAddModuleSuccess,
      onError: onAddModuleError,
    });

  //------- Edit Single Entry -------
  const EditModuleFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.name);
    body.append("age", values.age);
    body.append("village", values.village);
    body.append("district", values.district);
    body.append("phone", values.phone);
    body.append("gender", values.gender.value);
    body.append("id", selectedData?.id);

    return await axios.post(`${Config.apiUrl}/update-farmer`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getModulesMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditUser(false);
  };

  const onEditEntryError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
    EditModuleFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );
  console.log(data?.data?.data);

  // ! -----DELETE MODULE
  const deleteModuleFunction = async () => {
    const body = new FormData();
    body.append("moduleId", selectedData.id);
    body.append("courseId", courseId);
    return await axios.post(`${Config.apiUrl}/deletemodule`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const { isLoading: isDeleteModuleLoading, mutate: deleteModuleMutate } =
    useMutation(deleteModuleFunction, {
      onSuccess: (res) => {
        if (res.data.status === "OK") {
          setDeleteModule(false);
          toast.success(res.data.msg || "Module deleted.");
          getModulesMutate();
        }
      },
      onError: (res) => {
        toast.error("Please try again later.");
      },
    });

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Modules")}</Title>
              <Underline />
            </div>
            {user.userType != Config.userType.MARKETING && (
              <Uploader
                setOpenUploadModel={setOpenUploadModel}
                t={t}
                courseId={courseId}
                length={length}
              />
            )}
          </div>
          <div className="flex w-full justify-between items-center ">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              filter={filter}
              user={user}
              t={t}
            />
          </div>
          {deleteModule && (
            <DeleteModal
              setDelete={setDeleteModule}
              deleteMutate={deleteModuleMutate}
              isDeleteLoading={isDeleteModuleLoading}
            />
          )}
          {openUploadModel && (
            <AddModuleModel
              postModuleMutate={postModuleMutate}
              AddSubAgentLoading={AddSubAgentLoading}
              selectedData={selectedData}
              setOpenUploadModel={setOpenUploadModel}
              data={data?.data?.data?.company}
            />
          )}

          {editModule && (
            <EditModuleModel
              editAddUser={editAddUser}
              editEntryLoading={editEntryLoading}
              setEditModule={setEditModule}
              data={data?.data}
              selectedData={selectedData}
            />
          )}
          {/* {viewDetail && (
            <ViewDetailModel
              setViewDetail={setViewDetail}
              selectedData={selectedData}
            />
          )} */}
          {viewFiles && (
            <ViewFilesModal
              setViewFiles={setViewFiles}
              fileType={fileType}
              selectedData={selectedData}
            />
          )}
          {!error && !isLoading && data && (
            <Table
              ApiData={error ? [] : data?.data?.data}
              setAddModule={setAddModule}
              setSelectedData={setSelectedData}
              selectedData={selectedData}
              setEditModule={setEditModule}
              setChangePasswordModel={setChangePasswordModel}
              setViewDetail={setViewDetail}
              onViewFiles={setViewFiles}
              onSetFileType={setFileType}
              onDeleteModule={setDeleteModule}
              userType={user.userType}
            />
          )}

          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.msg == "No data found") && !isLoading && (
            <NotFoundModel />
          )}
        </BoxContainer>
      </Bg>
    </>
  );
};

const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  setFilterValue,
  t,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form className="w-full">
        <FilterContainer>
          <InputGroup className="" style={{ flexBasis: "content" }}>
            <Label htmlFor="filter">{t("Search")}</Label>

            <Select
              autoComplete="off"
              className="w-36 pl-2 bg-transparent text-sm p-0 border-none"
              style={{ height: "40px" }}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
                //searchHandler(e.target.value);
              }}
            >
              <MenuItem value="name">By Name</MenuItem>
              {/* <MenuItem value="2">Pending Payments</MenuItem>
                <MenuItem value="3">InProgress</MenuItem>
                <MenuItem value="4">Approved By Asst</MenuItem>
                <MenuItem value="5">Completed</MenuItem>
                <MenuItem value="6">Certificate Issued</MenuItem>
                <MenuItem value="10">Rejected</MenuItem> */}
            </Select>
          </InputGroup>
          <InputGroup className="w-[10rem]">
            <Label htmlFor="filterVal">{t("Value")}</Label>
            <FieldWrapper className="h-10">
              <Field
                type="text"
                name="filterVal"
                id="filterVal"
                class="ml-1"
                onChange={(e) => setFilterValue(e.target.value)}
              />
            </FieldWrapper>
          </InputGroup>

          {/* <InputGroup className="max-w-xs">
            <Label htmlFor="endDate">End Date:</Label>
            <FieldWrapper className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="endDate" id="endDate" class="ml-1" />
            </FieldWrapper>
          </InputGroup> */}

          {/* <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Search")}
          </ApplyBtn> */}
        </FilterContainer>
      </Form>
    </Formik>
  );
};
const Uploader = ({ setOpenUploadModel, t, courseId, length }) => {
  const navigate = useNavigate();
  return (
    <UploadWrapper>
      <Button
        type="button"
        onClick={() => navigate(`/modules/addModule/${courseId}/${length}`)}
      >
        <MdAdd className="text-white w-5 h-5" /> <p>{t("Add Module")}</p>
      </Button>
      {/* <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <MdAdd className="text-white w-5 h-5" /> <p>{t("Add Module")}</p>
      </Button> */}
    </UploadWrapper>
  );
};

const TableWrapper = tw.h1`my-10 border bg-white  rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-[#4e752f] hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
// const ApplyBtn = tw.button`${(p) =>
//   p.$active
//     ? "bg-gray-100"
//     : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center  h-full rounded-md px-6  cursor-pointer bg-custom-brown hover:bg-cyan-700  py-2.5 text-sm`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap w-40  !mr-auto border-gray-200 flex space-x-1.5 justify-center items-center text-white  rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  h-10 text-sm 
     `;
const FilterContainer = tw.div`flex w-full gap-4 items-end my-5`;
const FieldWrapper = tw.div`
${(p) => (p.$select ? "h-10" : "pl-2   field-wrapper border border-gray-300")}
 relative  rounded-md  w-full  flex items-center `;

export default Module;
