import React from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import Loading from "../Loading";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";

const EditCourseModel = ({
  editCourseMutate,
  editEntryLoading,
  setEditCourse,
  selectedData,
  data,
}) => {
  //const deviceData = data.find((item) => item.id === deviceId);
  //console.log(selectedData);
  const categoryList = data?.categoryList?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const courseName = data?.data?.courseList?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  console.log("hdksb " + courseName);
  const InitialValues = {
    categoryList: selectedData?.name,
    courseName: selectedData?.name,
    price: selectedData?.price1,
    rating: selectedData?.rating,
    requirements: selectedData?.requirements,
    description: selectedData?.shortDescription,
    duration: selectedData?.totalTime,
    id: selectedData?.id,
  };
  console.log("name of abc " + selectedData?.name);
  const SubmitHandler = (values) => {
    editCourseMutate(values);
  };

  return (
    <Model width={`w-11/12 max-w-lg`} setOpenModel={setEditCourse}>
      <Title>Edit Course</Title>

      {editEntryLoading && <Loading />}

      {!editEntryLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="categoryName">Category Name</Label>
                <FieldWrapper $select={true}>
                  <Field
                    name="categoryName"
                    id="categoryName"
                    autoComplete="off"
                  >
                    {(props) => (
                      <Select
                        className="w-full h-full"
                        // isMulti
                        required
                        options={categoryList}
                        classNamePrefix="select"
                        // onChange={(val) => {
                        //   props.form.setFieldValue("farmerId", val.map((role)=>role.value))
                        // }}
                        onChange={(val) => {
                          props.form.setFieldValue("categoryList", val.value);
                        }}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="courseName">Course Name</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="courseName"
                    id="courseName"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>

              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="price">Price</Label>
                  <FieldWrapper>
                    <Field
                      type="number"
                      name="price"
                      id="price"
                      autoComplete="off"
                      className="truncate"
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      maxLength={10}
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="rating">Rating</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="rating"
                      id="rating"
                      autoComplete="off"
                      className="truncate"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>

              <InputGroup>
                <Label htmlFor="duration">Duration (hours)</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="duration"
                    id="duration"
                    placeholder="Enter Duration (hours)"
                    autoComplete="off"
                    className="truncate"
                    pattern="\d+"
                    title="Please enter a valid number"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="requirements">Requirements</Label>
                <FieldWrapper>
                  <Field
                    type="text"
                    name="requirements"
                    id="requirements"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="description">Description</Label>
                <FieldWrapper>
                  <Field
                    component="textarea"
                    rows={3}
                    name="description"
                    id="description"
                    autoComplete="off"
                    className="truncate"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Update</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid gap-6 my-6 px-1 `;

const Title = tw.h2` text-lg md:text-xl lg:text-2xl text-gray-600 font-medium mb-6 text-center`;
const BtnWrapper = tw.div` w-full flex items-center justify-center space-x-10 mt-8`;

export default EditCourseModel;
