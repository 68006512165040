import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import { RxCross2 } from "react-icons/rx";
import parse from "html-react-parser";
import { useState } from "react";
import moment from "moment";

const ViewDetailModel = ({ setViewDetail, selectedData }) => {
    const [showImageModel, setShowImageModel] = useState(false);

    const handleButtonClick = () => {
        setShowImageModel(true);
    };
    const handleCloseImageModel = () => {
        setShowImageModel(false);
    };
    return (
        <>
            <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
                <Wrapper>
                    <Title>
                        <MdViewHeadline className="mt-1" />
                        View Details
                    </Title>
                    <SingleItem name={"Book Name"} value={selectedData?.name} />



                    <SingleItem
                        name={"Description"}
                        value={selectedData?.shortDescription}
                    />
                    <SingleItem
                        name={"Created At"}
                        value={moment(selectedData?.created_at).format("lll")}
                    />
                </Wrapper>
            </Model>

        </>
    );
};

const SingleItem = ({ name, value, className }) => (
    <div className="flex items-center py-2 border-b border-gray-100">
        <Name>{name}</Name> <p>:</p>
        <Value>{value}</Value>
    </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const ImageWrapper = tw.div`grid grid-cols-1`;
const EditImageWrapper = tw.div`bg-white p-4 rounded-lg relative py-6`;

export default ViewDetailModel;
