import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Users/Table";
import Navbar from "../../Components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import {
  Page,
  Bg,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
  SmallBtn,
} from "../../Components/Styles/InputStyles";
import { Field, Form, Formik } from "formik";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { ForUs } from "../../Config";
import { MdAdd } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import EditUserModel from "../../Components/Users/EditUsersModel";
import AddUserModel from "../../Components/Users/AddUsersModel";

const Users = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [changePasswordModel, setChangePasswordModel] = useState(false);
  const [openUploadModel, setOpenUploadModel] = useState(false);
  const [openRoleViewModel, setopenRoleViewModel] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [editUser, setEditUser] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const initialValues = {
    name: "",
    filterVal: filterValue,
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };
  // console.log('Filter Value -> ', filterValue)
  const fetchFunction = async () => {
    const body = new FormData();
    body.append("userType", Config.userType.SUPERADMIN);
    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    body.append("startDate", startDate);
    body.append("endDate", endDate);
    return await axios.post(`${Config.apiUrl}/users`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getUsersSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getUsersError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getUsersMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getUsersSuccess,
    onError: getUsersError,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getUsersMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterValue, startDate, endDate]);

  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    // setFilterValue(values.filterVal);
  };
  const ChangeHandler = (e) => {
    setFilterValue(e.target.value);
  };

  //------- Add Single Entry -------
  const AddUserFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.fName + " " + values.lName);
    body.append("email", values.email);
    body.append("mobile", values.mobile);
    body.append("password", values.password);
    body.append("userType", Config.userType.SUPERADMIN);
    body.append("Roles", values.roles);

    return await axios.post(`${Config.apiUrl}/addusers`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddUserSuccess = (data) => {
    // console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      getUsersMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setOpenUploadModel(false);
  };

  const onAddUserError = (data) => {
    // console.log(data?.response?.data?.msg);
    setOpenUploadModel(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: AddUserLoading, mutate: postAddUser } = useMutation(
    AddUserFunction,
    {
      onSuccess: onAddUserSuccess,
      onError: onAddUserError,
    }
  );

  //------- Edit Single Entry -------
  const EditUserFunction = async (values) => {
    const body = new FormData();
    body.append("name", values.name);
    body.append("mobile", values.mobile);
    body.append("email", values.email);
    body.append("status", values.status);
    body.append("roles", values.roles);
    body.append("id", values.id);
    return await axios.post(`${Config.apiUrl}/edituser`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditEntrySuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getUsersMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setEditUser(false);
  };

  const onEditEntryError = (data) => {
    setEditUser(false);
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: editEntryLoading, mutate: editAddUser } = useMutation(
    EditUserFunction,
    {
      onSuccess: onEditEntrySuccess,
      onError: onEditEntryError,
    }
  );

  // console.log("Data is" + data?.data?.data?.UsersList);
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Users")}</Title>
              <Underline />
            </div>

            <Uploader
              setOpenUploadModel={setOpenUploadModel}
              t={t}
              selectedData={selectedData}
            />
          </div>

          <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              filterValue={filterValue}
              filter={filter}
              user={user}
              t={t}
              ChangeHandler={ChangeHandler}
            />
          </div>

          {openUploadModel && (
            <AddUserModel
              postAddUser={postAddUser}
              AddUserLoading={AddUserLoading}
              setOpenUploadModel={setOpenUploadModel}
              selectedData={selectedData}
              data={data?.data?.data?.company}
            />
          )}

          {editUser && (
            <EditUserModel
              editAddUser={editAddUser}
              editEntryLoading={editEntryLoading}
              setEditUser={setEditUser}
              data={data?.data}
              selectedData={selectedData}
            />
          )}

          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data?.data?.UsersList}
                setopenRoleViewModel={setopenRoleViewModel}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                setEditUser={setEditUser}
                setChangePasswordModel={setChangePasswordModel}
              />
            </TableWrapper>
          )}

          {/* {isLoading && <Loading />}
          {error && !isLoading && data?.data?.data?.userList == false && (
            <NotFoundModel />
          )} */}
          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data?.userList?.length === 0) &&
            !isLoading && <NotFoundModel />}
        </BoxContainer>
      </Bg>
    </>
  );
};
const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  setFilterValue,
  filterValue,
  ChangeHandler,
  t,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form className="w-full">
        <FilterContainer>
          <div className="flex items-end gap-4 ">
            <InputGroup className="max-w-xs">
              <Label htmlFor="startDate">{t("Start_Date")}:</Label>
              <FieldWrapper className="h-10">
                <img
                  src={Images.Calender}
                  alt="Calender icon"
                  className="w-3.5"
                />
                <Field
                  type="date"
                  name="startDate"
                  id="startDate"
                  class="ml-1"
                />
              </FieldWrapper>
            </InputGroup>

            <InputGroup className="max-w-xs">
              <Label htmlFor="endDate">{t("End_Date")}:</Label>
              <FieldWrapper className="h-10">
                <img
                  src={Images.Calender}
                  alt="Calender icon"
                  className="w-3.5"
                />
                <Field type="date" name="endDate" id="endDate" class="ml-1" />
              </FieldWrapper>
            </InputGroup>

            <ApplyBtn disabled={InitialLoading} type="submit">
              {t("Date_filter")}
            </ApplyBtn>
          </div>

          <div className="flex items-end gap-4 ">
            <InputGroup className="max-w-xs">
              <Label htmlFor="filterVal">{t("")}</Label>
              {/* <FieldWrapper className="h-10"> */}
              <Field
                placeholder="Search..."
                type="text"
                name="filterVal"
                id="filterVal"
                class="ml-1"
                value={filterValue}
                onChange={ChangeHandler}
                // onChange={(event) => setFilterValue(event.target.value)}
                className="border border-zinc-400 rounded-md px-4 min-w-[200px] focus:ring focus:ring-lime-300 py-2"
              />
              {/* </FieldWrapper> */}
            </InputGroup>
            <InputGroup className="max-w-xs" style={{ flexBasis: "content" }}>
              <Label htmlFor="filter">{t("Search By")}</Label>

              <Select
                autoComplete="off"
                className="max-w-xs md:w-36 pl-2 bg-transparent text-sm p-0 border-none"
                style={{ height: "40px" }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setFilterValue("");
                }}
              >
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="mobile">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </InputGroup>
            {/* 
          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Search")}
          </ApplyBtn> */}
          </div>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const Uploader = ({ setOpenUploadModel, t }) => {
  return (
    <UploadWrapper>
      <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <MdAdd className="text-white w-5 h-5" /> <p>{t("Add User")}</p>
      </Button>
    </UploadWrapper>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-[#4e752f] hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`
grid grid-cols-2 w-full justify-between md:flex md:space-x-4 items-end mt-12 md:mt-8`;

export default Users;
