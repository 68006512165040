import React, { useState, useEffect } from "react";
import AddCourseForm from "../../Components/Courses/AddCourseForm";
import Navbar from "../../Components/Navbar";
import { BoxContainer, Underline } from "../../Components/Styles/PageStyles";
import tw from "tailwind-styled-components";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
import Loading from "../../Components/Loading";
import NotFoundModel from "../../Components/NotFoundModel";

const AddCourse = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const navigate = useNavigate();

  const fetchFunction = async () => {
    const body = new FormData();

    return await axios.post(`${Config.apiUrl}/get-category`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getCategorySuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getCategoryError = (res) => {};

  const {
    isLoading: isCategoryLoading,
    mutate: getCategoryMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getCategorySuccess,
    onError: getCategoryError,
  });

  useEffect(() => {
    getCategoryMutate();
  }, []);

  console.log(data?.data?.data);

  const addCourse = async (values) => {
    // const categories = values.categories.map((category) => category.value);
    // console.log(categories, typeof categories);
    const body = new FormData();
    body.append("name", values.name);
    // categories.map((category) => body.append("categories[]", category));
    body.append("categories", values.categories.value);
    body.append("price", values.price);
    // body.append("duration", `${values.hours}:${values.minutes}`);
    body.append("description", values.description);
    body.append("introUrl", values.introUrl);
    body.append("requirements", values.requirements);
    body.append("thumbnail", values.thumbnail);

    return await axios.post(`${Config.apiUrl}/addcourse`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const { isLoading, mutate: addCourseMutate } = useMutation(addCourse, {
    onSuccess: (res) => {
      if (res?.data?.status === "OK") {
        toast.success(res.data.msg || "Course added successfully.");
        return navigate("/courses");
      }
    },
    onError: (res) => {
      return toast.error(res?.response?.data?.msg || "There was an error.");
    },
  });
  return (
    <section className="min-h-screen w-screen bg-slate-100 py-20">
      <Navbar />
      {(isCategoryLoading || isRefetching) && <Loading />}
      {error && !isCategoryLoading && <NotFoundModel />}
      {!isCategoryLoading && data && (
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div className="flex flex-col w-full items-start">
              <Title>Add new course</Title>
              <Underline />
              <AddCourseForm
                addCourseMutate={addCourseMutate}
                isLoading={isLoading}
                user={user}
                data={data?.data?.data?.CategoryList}
              />
            </div>
            {/* <Uploader setOpenUploadModel={setOpenUploadModel} t={t} /> */}
          </div>
        </BoxContainer>
      )}
    </section>
  );
};

const Title = tw.h1`text-2xl text-zinc-900 font-semibold whitespace-nowrap`;
export default AddCourse;
