import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Navbar from "../../Components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import PaymentsTable from "../../Components/SubscribedBook/PaymentsTable";

import {
    Page,
    Bg,
    BoxContainer,
    BoxTitle,
    Underline,
    ErrorText,
} from "../../Components/Styles/PageStyles";
import {
    FieldWrapper,
    InputGroup,
    Label,
    SmallBtn,
} from "../../Components/Styles/InputStyles";
import { Field, Form, Formik } from "formik";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { FaFileExport } from "react-icons/fa";
import moment from "moment";

const SubscribeBook = () => {
    const user = useSelector((state) => state.UserReducer.user);
    const [openUploadModel, setOpenUploadModel] = useState(false);
    const [filterValue, setFilterValue] = useState("");
    const [filter, setFilter] = useState("name");

    const [startDate, setStartDate] = useState(
        moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
    );

    console.log("Filter Value -> ", filterValue);
    const [endDate, setEndDate] = useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    console.log("Filter -> ", filter);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const initialValues = {
        name: "",
        filterVal: filterValue,
        startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
    };

    // ! -----------FETCH PAYMENTS RECORDS-----------
    const fetchPaymentRecords = async () => {
        const body = new FormData();
        body.append("startDate", startDate);
        body.append("endDate", endDate);
        body.append("filterType", filter);
        body.append("filterValue", filterValue);
        return await axios.post(`${Config.apiUrl}/get-book-payments`, body, {
            headers: {
                authorization: "Bearer" + " " + user.token,
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
        });
    };

    const {
        isLoading,
        data,
        error,
        mutate: getPaymentsMutate,
    } = useMutation(fetchPaymentRecords, {
        onSuccess: (res) => {
            if (res.data.status === "OK") {
                console.log("Success");
            }
        },
        onError: (res) => {
            console.log(res);
            toast.error("An error occured");
        },
    });

    data && console.log(data?.data?.data?.payments);


    useEffect(() => {
        const handler = setTimeout(() => {
            getPaymentsMutate();
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [filterValue, startDate, endDate]);

    const submitHandler = (values) => {
        const newValues = {
            startDate: moment(values.startDate).format("YYYY-MM-DD"),
            endDate: moment(values.endDate).format("YYYY-MM-DD"),
        };
        setStartDate(newValues.startDate);
        setEndDate(newValues.endDate);

    };
    const ChangeHandler = (e) => {
        setFilterValue(e.target.value);
    };
    // console.log(data?.data?.data?.userList?.length);
    console.log(data?.data?.data);

    const handleExportReport = async () => {
        const body = new FormData();
        body.append("startDate", startDate);
        body.append("endDate", endDate);
        body.append("filterType", filter);
        body.append("filterValue", filterValue);

        return await axios.post(`${Config.apiUrl}/export-report`, body, {
            headers: {
                authorization: "Bearer" + " " + user.token,
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            },
            responseType: "blob",
        });
    };

    const getReportSuccess = (res) => {
        if (res?.statusText == "OK") {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(res.data);
            link.download = "exported_report.csv";

            link.click();
            toast.success(res?.data?.msg || "Report downloaded successfully");
        } else if (res.statusText == "NOK" || res.data.data == false) {
            toast.error(res?.data?.msg || "Error exporting report");
        }
    };

    const getReportError = (res) => {
        toast.error(error?.res?.data?.msg || "Error exporting report");
    };

    const { isLoading: isReportLoading, mutate: getReportMutate } = useMutation(
        handleExportReport,
        {
            onSuccess: getReportSuccess,
            onError: getReportError,
        }
    );
    const handleButtonClick = () => {
        getReportMutate();
    };

    return (
        <>
            <Bg
                style={{
                    backgroundImage: `url(${Images.BeamsBg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "top left",
                    height: "100vh",
                }}
            >
                <Navbar />

                {/* <Page> */}
                <BoxContainer>
                    <div className="flex w-full justify-between items-center">
                        <div>
                            <Title>{t("Book Buyers List")}</Title>
                            <Underline />
                        </div>


                    </div>

                    <div className="flex w-full justify-between items-center">
                        <Filters
                            submitHandler={submitHandler}
                            initialValues={initialValues}
                            InitialLoading={isLoading}
                            setFilter={setFilter}
                            setFilterValue={setFilterValue}
                            ChangeHandler={ChangeHandler}
                            filterValue={filterValue}
                            filter={filter}
                            handleButtonClick={handleButtonClick}
                            isReportLoading={isReportLoading}
                            handleExportReport={handleExportReport}
                            user={user}
                            t={t}
                        />
                    </div>
                    {!isLoading && data && (
                        <TableWrapper>
                            <PaymentsTable
                                ApiData={data ? data?.data?.data?.payments : []}
                                isLoading={isLoading}
                            />
                        </TableWrapper>
                    )}
                    {isLoading && <Loading />}
                    {(error || data?.data?.data?.payments?.length === 0) &&
                        !isLoading && <NotFoundModel />}
                </BoxContainer>
            </Bg>
        </>
    );
};
const Filters = ({
    initialValues,
    submitHandler,
    InitialLoading,
    user,
    filter,
    setFilter,
    setFilterValue,
    filterValue,
    ChangeHandler,
    handleExportReport,
    isReportLoading,
    handleButtonClick,
    t,
}) => {
    return (
        <Formik initialValues={initialValues} onSubmit={submitHandler}>
            <Form className="w-full">
                <FilterContainer>
                    <div className="flex gap-2 items-end sm:text-base text-xs flex-wrap">
                        <div className="flex items-end gap-2">
                            <InputGroup className="max-w-[130px]">
                                <Label htmlFor="startDate">{t("Start_Date")}:</Label>
                                <FieldWrapper className="h-10">
                                    <img
                                        src={Images.Calender}
                                        alt="Calender icon"
                                        className="w-3.5"
                                    />
                                    <Field
                                        type="date"
                                        name="startDate"
                                        id="startDate"
                                        class="ml-1"
                                    />
                                </FieldWrapper>
                            </InputGroup>

                            <InputGroup className="max-w-[130px]">
                                <Label htmlFor="endDate">{t("End_Date")}:</Label>
                                <FieldWrapper className="h-10">
                                    <img
                                        src={Images.Calender}
                                        alt="Calender icon"
                                        className="w-3.5"
                                    />
                                    <Field type="date" name="endDate" id="endDate" class="ml-1" />
                                </FieldWrapper>
                            </InputGroup>
                        </div>
                        <ApplyBtn disabled={InitialLoading} type="submit">
                            {t("Date_filter")}
                        </ApplyBtn>
                    </div>

                    <div className="flex gap-4 items-end">
                        {filter !== "paystatus" ? (
                            <InputGroup className="max-w-[200px]">
                                <Label htmlFor="filterVal">{t("")}</Label>
                                {/* <FieldWrapper className="h-10"> */}
                                <Field
                                    placeholder="Search..."
                                    type="text"
                                    name="filterVal"
                                    id="filterVal"
                                    class="ml-1"
                                    value={filterValue}
                                    onChange={ChangeHandler}
                                    // onChange={(event) => setFilterValue(event.target.value)}
                                    className="border border-slate-300 rounded-md px-4 focus:ring focus:ring-lime-300 py-2"
                                />
                                {/* </FieldWrapper> */}
                            </InputGroup>) : filter === "paystatus" ? (
                                <InputGroup
                                    className="min-w-[120px]"
                                    style={{ flexBasis: "content" }}
                                >
                                    <Label htmlFor="filter">{t("Search By")}</Label>
                                    <Select
                                        autoComplete="off"
                                        className=" px-2 bg-transparent text-sm border-none"
                                        style={{ height: "40px" }}
                                        value={filterValue}
                                        onChange={ChangeHandler}
                                    >
                                        <MenuItem value="" disabled>
                                            {t("Select Pay Status")}
                                        </MenuItem>
                                        <MenuItem value="1">{t("Paid")}</MenuItem>
                                        <MenuItem value="2">{t("Unpaid")}</MenuItem>
                                        {/* <MenuItem value="3">{t("Failed")}</MenuItem> */}
                                    </Select>
                                </InputGroup>
                            ) : null}
                        <InputGroup
                            className="min-w-[120px]"
                            style={{ flexBasis: "content" }}
                        >
                            <Label htmlFor="filter">{t("Search By")}</Label>

                            <Select
                                autoComplete="off"
                                className="w-24 px-2 bg-transparent text-sm border-none"
                                style={{ height: "40px" }}
                                value={filter}
                                onChange={(e) => {
                                    setFilter(e.target.value);
                                    setFilterValue("");
                                }}
                            // onChange={(e) => {
                            //   setFilter(e.target.value);

                            //   //searchHandler(e.target.value);
                            // }}
                            >
                                <MenuItem value="name">Name</MenuItem>
                                {/* <MenuItem value="mobile">Mobile</MenuItem> */}
                                <MenuItem value="email">Email</MenuItem>
                                <MenuItem value="paystatus">Pay Status</MenuItem>
                            </Select>
                        </InputGroup>
                        {/* <ExportBtn
                            onClick={handleButtonClick}
                            disabled={isReportLoading}
                            title="Export Report"
                        >
                            {isReportLoading ? (
                                <Loading
                                    color="white"
                                    width={20}
                                    height={20}
                                    noPadding={true}
                                />
                            ) : (
                                <FaFileExport className="text-white w-5 h-5 " />
                            )}
                        </ExportBtn> */}
                        {/* 
          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Search")}
          </ApplyBtn> */}
                    </div>
                </FilterContainer>
            </Form>
        </Formik>
    );
};

const Uploader = ({ setOpenUploadModel, t }) => {
    return <UploadWrapper></UploadWrapper>;
};

const TableWrapper = tw.h1`my-10 border bg-white rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;
const ExportBtn = tw.button`text-white bg-[#4e752f] hover:bg-green-700 h-10 px-3 rounded flex justify-ceter items-center`;
const Button = tw.button`text-white bg-[#4e752f] hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
const ApplyBtn = tw.button`${(p) =>
    p.$active
        ? "bg-gray-100"
        : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4 mt-8`;

export default SubscribeBook;
