import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import tw from "tailwind-styled-components";
import { twMerge } from "tailwind-merge";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Parser from "html-react-parser";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Dropzone from "./Dropzone";
import Loading from "react-loading";
import Spinner from "../Spinner";
const animatedComponents = makeAnimated();

const AddCourseForm = ({ user, addCourseMutate, isLoading, data }) => {
  console.log(data);
  const [value, setValue] = useState("");

  const initialValues = {
    name: "",
    // hours: "",
    // minutes: "",
    description: "",
    categories: "",
    introVideo: "",
    introUrl: "",
    thumbnail: null
  };

  // COURSE CATEGORIES
  // const categories = user.Category.map((category) => {
  //   return Object.assign({ value: category.id, label: category.name });
  // });
  const categories = data?.map((category) => {
    return Object.assign({ value: category.id, label: category.name });
  });
  // YUP VALIDATION
  const formSchema = yup.object().shape({
    // name: yup.string().required(),
    name: yup.string().required().min(45, 'Course name must be at least 45 characters long').max(75, 'Course name cannot exceed 75 characters'),
    // price: yup.number().required(),
    // hours: yup.number().required().min(0),
    // minutes: yup.number().required().min(0).max(59),
    description: yup
      .string()
      .required('Description is required')
      .test('word-count', 'Description cannot exceed 200 words', (value) => {
        if (!value) return true; // Allow empty description
        const wordCount = value.trim().split(/\s+/).length;
        return wordCount <= 200;
      }),
    // description: yup.string().required().max(200, 'Description cannot exceed 200 characters'),
    // introUrl: yup.string().required('Intro video URL is required').matches(/^[A-Za-z0-9_-]{11}$/, 'Invalid YouTube video key format'),
    // thumbnail: yup.mixed().test('fileSize', 'Thumbnail size should be less than 10 MB', (value) => {
    //   if (!value) return true;
    //   return value.size <= 10000000;
    // })
  });
  // SUBMIT HANDLER
  const handleSubmit = (values) => {
    console.log(values);
    const data = { ...values, requirements: value };
    console.log(data);
    addCourseMutate(data);
  };
  return (
    <article className="mt-10 w-full m-auto p-8 rounded-lg bg-white shadow-md">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={formSchema}
      >
        {({ values, setFieldValue, errors, touched }) => {
          // console.log(errors);
          return (
            <Form className="flex flex-col gap-y-4">
              <GroupWrapper>
                <FieldWrapper className="flex-1 flex-wrap">
                  <label>Course Name</label>
                  <Field
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter the course name..."
                    as={Input}
                  />
                  {errors.name && touched.name ? (
                    <p className="bg-red-100 text-red-500 p-2 text-xs">
                      {errors.name}
                    </p>
                  ) : null}
                </FieldWrapper>
                <FieldWrapper className="flex-1">
                  <label>Categories</label>
                  <Field
                    required
                    name="categories"
                    id="categories"
                    autoComplete="off"
                  >
                    {({ props }) => (
                      <Select
                        {...props}
                        closeMenuOnSelect={true}
                        required
                        theme="primary"
                        classNamePrefix="select"
                        className="z-40"
                        components={animatedComponents}
                        // defaultValue={[colourOptions[4], colourOptions[5]]}
                        // isMulti
                        options={categories}
                        onChange={(selectedOption) => {
                          // console.log("selectedoptions", selectedOption);
                          // console.log(values);
                          return setFieldValue("categories", selectedOption);
                        }}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </GroupWrapper>
              <FieldWrapper>
                <label>Short Description</label>
                <Field
                  type="text"
                  name="description"
                  id="description"
                  placeholder="Enter course description"
                  as={TextArea}
                />
                {errors.description && touched.description ? (
                  <p className="bg-red-100 text-red-500 p-2 text-xs">
                    {errors.description}
                  </p>
                ) : null}
              </FieldWrapper>
              {/* <GroupWrapper>
               

                <FieldWrapper className="flex-1 flex-wrap">
                  <label>Course Duration</label>
                  <div className="flex items-center gap-2">
                    <div className="relative flex-1">
                      <span className="absolute left-0 bg-black rounded-l-md p-2 text-sm text-neutral-200 z-20">
                        Hrs.
                      </span>
                      <Field
                        type="number"
                        name="hours"
                        id="hours"
                        placeholder="Hours"
                        as={Input}
                        className="pl-12"
                      />
                    </div>
                    <div className="relative flex-1">
                      <span className="absolute left-0 bg-black rounded-l-md p-2 text-sm text-neutral-200 z-20">
                        Min.
                      </span>

                      <Field
                        type="number"
                        name="minutes"
                        id="minutes"
                        placeholder="Minutes"
                        className="pl-12"
                        as={Input}
                      />
                    </div>
                  </div>
                  {((errors.hours && touched.hours) ||
                    (errors.minutes && touched.minutes)) && (
                      <p className="bg-red-100 text-red-500 p-2 text-xs flex flex-col">
                        {errors.hours && touched.hours && (
                          <span> - {errors.hours}</span>
                        )}
                        {errors.minutes && touched.minutes && (
                          <span> - {errors.minutes}</span>
                        )}
                      </p>
                    )}
                </FieldWrapper>
                {errors.duration && touched.duration ? (
                  <p className="bg-red-100 text-red-500 p-2 text-xs">
                    {errors.duration}
                  </p>
                ) : null}
              </GroupWrapper> */}

              <FieldWrapper className="flex-1 flex-wrap">
                <label>Intro Video</label>
                <Field
                  type="text"
                  name="introUrl"
                  id="introUrl"
                  placeholder="Enter YouTube video key..."
                  as={Input}
                />
                {/* <ErrorMessage name="introUrl" component="p" className="bg-red-100 text-red-500 p-2 text-xs" /> */}
              </FieldWrapper>
              {/* <FieldWrapper>
                <label>Requirements</label>
                <ReactQuill
                  required
                  theme="snow"
                  value={value}
                  onChange={setValue}
                  name="requirements"
                  className="bg-slate-50"
                />
              </FieldWrapper> */}

              <FieldWrapper>
                <label>Course Thumbnail</label>
                <Field id="thumbnail" name="thumbnail" type="text">
                  {(props) => (
                    <Dropzone
                      fields={props.field}
                      setFieldValue={props.form.setFieldValue}
                      componentFor="thumbnail"
                      acceptFiles="application/.jpg"
                      fileType="image"
                      File={props.field.value ? [props.field.value] : null}
                    />
                  )}
                </Field>
                {/* {errors.thumbnail && touched.thumbnail && (
                  <p className="bg-red-100 text-red-500 p-2 text-xs">{errors.thumbnail}</p>
                )} */}
                <span className="italic text-sm text-zinc-600">
                  *Image size should be less than 10 MB.*
                </span>
              </FieldWrapper>
              <SubmitBtn
                type="submit"
                className="flex items-center justify-center h-12"
              >
                {isLoading ? (
                  <Spinner className="text-white w-3 h-3" />
                ) : (
                  "Submit"
                )}
              </SubmitBtn>
            </Form>
          );
        }}
      </Formik>
    </article>
  );
};

const FieldWrapper = tw.div`${(props) =>
  twMerge(`flex flex-col gap-2`, props.className)}`;
export const GroupWrapper = tw.div`flex flex-col md:flex-row md:items-start gap-4 `;
const Input = tw.input`bg-slate-50 text-sm rounded-md p-2`;
const TextArea = tw.textarea`bg-slate-50 text-sm rounded-md p-2`;
const SubmitBtn = tw.button`bg-black rounded-lg text-center text-slate-100 py-2 px-4 w-1/2 m-auto`;

export default AddCourseForm;
