import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Institutes/StudentTable";
import Navbar from "../../Components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import { FaFileExport } from "react-icons/fa";

import {
  Page,
  Bg,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
  SmallBtn,
} from "../../Components/Styles/InputStyles";
import { Field, Form, Formik } from "formik";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import moment from "moment";
import ViewStudentModel from "../../Components/Institutes/ViewStudentModel";

const InstituteStudent = () => {
  const user = useSelector((state) => state.UserReducer.user);

  const { id: institutionId } = useParams();
  const [selectedData, setSelectedData] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [viewDetail, setViewDetail] = useState(false);
  console.log("Filter -> ", filter);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: filterValue,
  };

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("userType", user.userType);
    body.append("filterType", filter);
    body.append("filterValue", filterValue);

    body.append("instituteId", institutionId);
    return await axios.post(`${Config.apiUrl}/studentList`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getCustomersSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getCustomersError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getCustomersMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getCustomersSuccess,
    onError: getCustomersError,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getCustomersMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterValue]);
  const submitHandler = (values) => {
    // setFilterValue(values.filterVal);
    getCustomersMutate();
  };
  const ChangeHandler = (e) => {
    setFilterValue(e.target.value);
  };
  // console.log(data?.data?.data?.userList?.length);
  console.log(data?.data?.data);

  const handleExportReport = async () => {
    const body = new FormData();

    body.append("filterType", filter);
    body.append("filterValue", filterValue);
    body.append("instituteId", institutionId);

    return await axios.post(`${Config.apiUrl}/export-student-report`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      responseType: "blob",
    });
  };

  const getReportSuccess = (res) => {
    if (res?.statusText == "OK") {
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(res.data);
      link.download = "exported_report.csv";

      link.click();
      toast.success(res?.data?.msg || "Report downloaded successfully");
    } else if (res.statusText == "NOK" || res.data.data == false) {
      toast.error(res?.data?.msg || "Error exporting report");
    }
  };

  const getReportError = (res) => {
    toast.error(error?.res?.data?.msg || "Error exporting report");
  };

  const { isLoading: isReportLoading, mutate: getReportMutate } = useMutation(
    handleExportReport,
    {
      onSuccess: getReportSuccess,
      onError: getReportError,
    }
  );
  const handleButtonClick = () => {
    getReportMutate();
  };
  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>
                {data?.data?.instituteName}'s {t("Students")}
              </Title>
              <Underline />
            </div>
          </div>
          {viewDetail && (
            <ViewStudentModel
              setViewDetail={setViewDetail}
              selectedData={selectedData}
            />
          )}

          <div className="flex w-full justify-end items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              setFilterValue={setFilterValue}
              ChangeHandler={ChangeHandler}
              filterValue={filterValue}
              filter={filter}
              user={user}
              handleButtonClick={handleButtonClick}
              isReportLoading={isReportLoading}
              handleExportReport={handleExportReport}
              t={t}
            />
          </div>

          {!error && !isLoading && data && (
            <TableWrapper>
              <Table
                ApiData={error ? [] : data?.data?.data?.studentList}
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                userType={user.userType}
                setViewDetail={setViewDetail}
              />
            </TableWrapper>
          )}

          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data?.studentList?.length === 0) &&
            !isLoading && <NotFoundModel />}
        </BoxContainer>
      </Bg>
    </>
  );
};
const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  setFilterValue,
  filterValue,
  ChangeHandler,
  handleExportReport,
  isReportLoading,
  handleButtonClick,
  t,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form className="w-full">
        <FilterContainer>
          <div className="flex gap-4 items-end">
            <InputGroup className="max-w-[200px]">
              <Label htmlFor="filterVal">{t("")}</Label>
              <Field
                placeholder="Search..."
                type="text"
                name="filterVal"
                id="filterVal"
                class="ml-1"
                value={filterValue}
                onChange={ChangeHandler}
                className="border border-slate-300 rounded-md px-4 focus:ring focus:ring-lime-300 py-2"
              />
            </InputGroup>

            <InputGroup
              className="min-w-[120px]"
              style={{ flexBasis: "content" }}
            >
              <Label htmlFor="filter">{t("Search By")}</Label>

              <Select
                autoComplete="off"
                className="w-24 px-2 bg-transparent text-sm border-none"
                style={{ height: "40px" }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                  setFilterValue("");
                }}
              >
                <MenuItem value="name">Name</MenuItem>
                <MenuItem value="mobile">Phone</MenuItem>
                <MenuItem value="email">Email</MenuItem>
              </Select>
            </InputGroup>
            <ExportBtn
              onClick={handleButtonClick}
              disabled={isReportLoading}
              title="Export Report"
            >
              {isReportLoading ? (
                <Loading
                  color="white"
                  width={20}
                  height={20}
                  noPadding={true}
                />
              ) : (
                <FaFileExport className="text-white w-5 h-5 " />
              )}
            </ExportBtn>
          </div>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;
const ExportBtn = tw.button`text-white bg-[#4e752f] hover:bg-green-700 h-10 px-3 rounded flex justify-ceter items-center`;
const Button = tw.button`text-white bg-[#4e752f] hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded`;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`flex items-end flex-wrap justify-end gap-4 mt-8`;

export default InstituteStudent;
