import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import moment from "moment";
import { PaginationLimit } from "../../Config";
import { HiEye } from "react-icons/hi";
import { RiContactsFill } from "react-icons/ri";
import { FaFileExport } from "react-icons/fa";
import { MdInfoOutline, MdModeEdit, MdPassword } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { FaTrashAlt, FaTrashRestore } from "react-icons/fa";
import Config from "../../Config";

const ModuleTable = ({
  ApiData,
  setopenRoleViewModel,
  setSelectedData,
  setEditModule,
  setChangePasswordModel,
  onViewFiles,
  onSetFileType,
  onDeleteModule,
  userType,
}) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const navigate = useNavigate();
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };
  console.log(toggleRow)

  const roleViewModel = (roles) => {
    setopenRoleViewModel(true);
    setSelectedData(roles);
  };

  const edit = (item) => {
    // setEditModule(true);
    // setSelectedData(item);
    navigate(`/modules/editModule/${item.id}`);
  };
  const deleteModule = (item) => {
    // setEditModule(true);
    setSelectedData(item);
    onDeleteModule(true);
  };

  const updatePassword = (item) => {
    setChangePasswordModel(true);
    setSelectedData(item);
  };

  const viewFilesModal = (item, type) => {
    setSelectedData(item);
    onViewFiles(true);
    onSetFileType(type);
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item, index,) => ({
        index,
        moduleName: item.moduleList.name,
        description: item.moduleList.shortDescription,
        courseName: getCourseName(item.moduleList),
        action: ActionBtns(item, index),
        totalAttachments: getFiles(item.attachmentList, "pdf"),
        totalVideos: getFiles(item.videoList, "video"),
      }));

      setData(tempData);
    }
  }, [ApiData]);

  const ActionBtns = (item, index) => {
    console.log("Row Index:", index);
    return (
      <ActionBtnWrapper>
        {userType == Config.userType.SUPERADMIN &&
          <Btn
            className=" bg-rose-100 border-red-300"
            title="Delete Module"
            onClick={() => deleteModule(item.moduleList)}
          >
            <FaTrashAlt className="text-rose-500" />
          </Btn>}
        <Link to={`/modules/editModule/${item.moduleList.id}/${item.moduleList.courseId}/${index}`} >
          {/* <Link
          to={{
            pathname: `/modules/editModule/${item.id}/${item.courseId}`,
            state: { rowIndex }
          }}
        > */}
          < Btn title="Edit Module">
            <MdModeEdit className="text-gray-700" />
          </Btn>
        </Link>
      </ActionBtnWrapper >
    );
  };

  const getFiles = (fileList, type) => {
    const count = fileList.length;
    return count > 0 ? (
      <button
        type="button"
        onClick={() => viewFilesModal(fileList, type)}
        className="underline text-blue-500 text-sm Capitalize"
      >
        {`${count} file(s)`}
      </button>
    ) : (
      <span className="text-zinc-400 text-sm font-medium">No file found</span>
    );
  };

  const getCourseName = (item) => {
    return (
      <div className="text-2xl flex gap-2 font-semibold text-slate-600 items-end">
        <span>{item.courseName}</span>
        <span className="text-slate-400 italic text-lg">#{item.courseId}</span>
      </div>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      // Display only "User" column for mobile screens
      return [
        {
          Header: "Module Name",
          accessor: "moduleName",
        },
        {
          Header: "Description",
          accessor: "description",
        },
      ];
    }

    // Columns for larger screens
    return [
      //   {
      //     Header: "Course Name",
      //     accessor: "courseName",
      //   },
      {
        Header: "Module Name",
        accessor: "moduleName",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Attachment(s)",
        accessor: "totalAttachments",
      },
      {
        Header: "Sub Modules",
        accessor: "totalVideos",
      },
      {
        Header: userType != Config.userType.MARKETING ? "Action" : "",
        accessor: userType != Config.userType.MARKETING ? "action" : "undefined",
      },
      // {
      //   Header: "Action",
      //   accessor: "action",
      // },
    ];
  }, [isMobileView]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, []);

  return (
    <>
      <h1 className="mt-10 mb-4">{data[0].courseName}</h1>
      <TableWrapper>
        <CustomTable {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        <img
                          src={Images.Arrow}
                          alt="down arrow"
                          className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"
                            } w-1.5 inline-block ml-1.5`}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <React.Fragment key={rowIndex}>
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell, cellIndex) => {
                      return (
                        <Td
                          {...cell.getCellProps()}
                          onClick={() => {
                            if (
                              cellIndex === 0 ||
                              (cellIndex === 1 && window.innerWidth < 768)
                            ) {
                              toggleRow(rowIndex);
                            }
                          }}
                        >
                          {cell.render("Cell")}
                        </Td>
                      );
                    })}
                  </Tr>
                  {selectedRow === rowIndex && window.innerWidth < 768 && (
                    <Tr>
                      <Td colSpan={columns.length}>
                        {/* {/ Render the details of the selected row here /} */}
                        <ul className="flex flex-col gap-2">
                          <li>
                            <strong>Total Attachments:</strong>{" "}
                            {data[rowIndex].totalAttachments}
                          </li>
                          <li>
                            <strong>Total Videos:</strong>{" "}
                            {data[rowIndex].totalVideos}
                          </li>
                          <li>
                            <strong>Action:</strong> {data[rowIndex].action}
                          </li>
                        </ul>
                      </Td>
                    </Tr>
                  )}
                </React.Fragment>
              );
            })}
          </Tbody>
        </CustomTable>

        {ApiData?.length > 0 && (
          <PaginationWrapper>
            <div className="px-2">
              Page{" "}
              <em>
                {pageIndex + 1} of {pageOptions.length}
              </em>
            </div>

            <div className="flex gap-1">
              <PrevBtn
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                <img
                  src={Images.Arrow}
                  alt="arrow"
                  className="w-2 opacity-75 "
                />
              </PrevBtn>
              <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
                <img
                  src={Images.Arrow}
                  alt="arrow"
                  className="w-2 rotate-180 opacity-75"
                />
              </NextBtn>
            </div>
          </PaginationWrapper>
        )}
      </TableWrapper>
    </>
  );
};

const UserImageWrapper = tw.div` flex items-center space-x-2`;
const UserImage = tw.img`w-5 h-5 rounded-full overflow-hidden`;
const TableWrapper = tw.h1` border bg-white  rounded-md overflow-auto`;
// const ActionBtnWrapper = tw.div`flex items-center space-x-3`;
const ActionBtnWrapper = tw.div`grid xl:grid-cols-2 md:grid-cols-1 grid-cols-4 w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0`;
const AddBtn = tw.button`grid place-items-center bg-blue-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const EditBtn = tw.button`grid place-items-center bg-yellow-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const Delete = tw.button`grid place-items-center bg-red-100 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b bg-slate-100 uppercase text-slate-600`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden hover:bg-slate-50 `;
// const Th = tw.th`text-left text-sm p-3 font-medium text-gray-500  `;
const Th = tw.th`text-left text-sm md:p-3 p-2 font-semibold`;

const Td = tw.td`p-3 text-sm md:p-3 text-left`;
const BookingBtn = tw.button` px-8 py-1 shadow bg-green-100 text-green-700 rounded cursor-pointer`;
const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;
const PlainBtn = tw.button`grid place-items-center w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const Active = tw.p`w-16 h-7 text-green-700  bg-green-100 grid place-items-center rounded inline-block`;
const Inactive = tw.p`w-16 h-7 text-red-700  bg-red-100 grid place-items-center rounded inline-block`;

const SuccessBadge = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const PendingBadge = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;

const InActiveBadge = tw.div`bg-red-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
export default ModuleTable;
