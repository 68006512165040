import React, { useEffect } from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import { RxCross2 } from "react-icons/rx";

import { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

const ViewFilesModal = ({ setViewFiles, selectedData, fileType }) => {
  const [showImageModel, setShowImageModel] = useState(false);
  useEffect(() => {
    console.log(selectedData);
  }, []);
  const handleButtonClick = () => {
    setShowImageModel(true);
  };
  const handleCloseImageModel = () => {
    setShowImageModel(false);
  };
  return (
    <>
      <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewFiles}>
        <Wrapper>
          <Title>
            <MdViewHeadline className="mt-1" />
            View Sub Modules
          </Title>
          <div className="flex flex-col gap-8">
            {selectedData.map((file, i) => (
              <div key={i}>
                <h1 className="capitalize text-lg text-zinc-700 font-semibold">
                  {file.name}
                </h1>
                {fileType === "pdf" && (
                  // <object
                  //   data={file.file}
                  //   type="application/pdf"
                  //   width="100%"
                  //   height="100%"
                  // >
                  //   <p>
                  //     <Link
                  //       className="text-indigo-500 underline italic"
                  //       target="_blank"
                  //       to={file.file}
                  //     >
                  //       Open in new tab
                  //     </Link>
                  //   </p>
                  // </object>
                  <a
                    className="text-blue-500 underline"
                    href={file.file}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {file.name}
                  </a>
                )}
                {fileType === "video" && (
                  // <iframe
                  //   width="100%"
                  //   height="100%"
                  //   src={`https://www.youtube.com/embed/${file.file}`}
                  //   title="Introduction To WiseGPT"
                  //   frameborder="0"
                  //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  //   allowfullscreen
                  // ></iframe>
                  <a
                    className="text-blue-500 underline"
                    href={`https://www.youtube.com/watch?v=${file.file}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {file.name}
                  </a>
                )}
              </div>
            ))}
          </div>
          {/* {showImageModel && (
            <img src={selectedData?.image} alt="Displayed on button click" />
          )} */}
        </Wrapper>
      </Model>
      {showImageModel && (
        <Model width={`w-11/12 max-w-lg`} setOpenModel={setShowImageModel}>
          <EditImageWrapper>
            <img src={selectedData?.image} alt="Displayed on button click" />
          </EditImageWrapper>
        </Model>
      )}
    </>
  );
};

const SingleItem = ({ name, value }) => (
  <div className="flex items-center py-2 border-b border-gray-100">
    <Name>{name}</Name> <p>:</p>
    <Value>{value}</Value>
  </div>
);

const Wrapper = tw.div`px-4 min-h-[400px]`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;
const ImageWrapper = tw.div`grid grid-cols-1`;
const EditImageWrapper = tw.div`bg-white p-4 rounded-lg relative py-6`;

export default ViewFilesModal;
