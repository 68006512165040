import React, { useState, useEffect } from "react";
import tw from "tailwind-styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import Images from "../../Images";
import { useMutation, useQuery } from "react-query";
import ViewPlanModal from "../../Components/Plans/ViewPlan";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import Table from "../../Components/Courses/Table";
import Navbar from "../../Components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import Model from "../../Components/Model";
import {
  Page,
  Bg,
  BoxContainer,
  BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import {
  FieldWrapper,
  InputGroup,
  Label,
  SmallBtn,
} from "../../Components/Styles/InputStyles";
import { Field, Form, Formik } from "formik";
import NotFoundModel from "../../Components/NotFoundModel";
import { useSelector } from "react-redux";
import { ForUs } from "../../Config";
import { MdAdd } from "react-icons/md";
import { HiUpload } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import EditUserModel from "../../Components/Users/EditUsersModel";
import AddCourseModel from "../../Components/Courses/AddCourseModel";
import ViewDetailModel from "../../Components/Courses/ViewDetail";
import DropZoneModel from "../../Components/Courses/DropzoneImageUpload";
import EditCourseModel from "../../Components/Courses/EditCourseModel";
import AddPlanModal from "../../Components/Plans/AddPlanModal";
import PlanTable from "../../Components/Plans/PlansTable";
import EditPlanModal from "../../Components/Plans/EditPlan";
import DeleteModal from "../../Components/DeleteModal";

const Plans = () => {
  const user = useSelector((state) => state.UserReducer.user);

  const [openUploadModel, setOpenUploadModel] = useState(false);

  const [filterValue, setFilterValue] = useState("");
  const [filter, setFilter] = useState("name");
  const [editCourse, setEditCourse] = useState(false);
  const [addPlan, setAddPlan] = useState(false);

  const [selectedData, setSelectedData] = useState("");
  const [editPlan, setEditPlan] = useState(false);
  const [viewDetail, setViewDetail] = useState(false);
  const [deletePlan, setDeletePlan] = useState(false);

  const { id: courseId } = useParams();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const initialValues = {
    name: "",
    filterVal: "",
  };
  const fetchFunction = async () => {
    const body = new FormData();
    body.append("courseId", courseId);
    return await axios.post(`${Config.apiUrl}/viewplans`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getCourseSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getCourseError = (res) => {};

  const {
    isLoading,
    mutate: getPlansMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getCourseSuccess,
    onError: getCourseError,
  });

  useEffect(() => {
    getPlansMutate();
  }, [getPlansMutate]);

  const submitHandler = (values) => {
    setFilterValue(values.filterVal);
    getPlansMutate();
  };

  // !---------ADD PLAN -----------

  const AddPlanFunction = async (values) => {
    // console.log(selectedData);
    const body = new FormData();
    console.log(values);
    body.append("courseId", courseId);
    body.append("planName", values.plan);
    // body.append("planDuration", values.planDuration);
    body.append("priceUSD", values.priceUsd);
    body.append("priceZk", values.priceZk);
    body.append("discountUSD", values.discountUsd);
    body.append("discountZk", values.discountZk);
    // body.append("requirements", values.requirement);
    // body.append("description", values.description);
    // body.append("image", values.profile);

    return await axios.post(`${Config.apiUrl}/add-plan`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onAddPlanSuccess = (data) => {
    console.log(data);
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getPlansMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setAddPlan(false);
  };

  const onAddPlanError = (data) => {
    console.log(data?.response?.data?.msg);
    setOpenUploadModel(false);
    setSelectedData("");
    toast.error(data?.response?.data?.msg);
  };

  const { isLoading: AddPlanLoading, mutate: postAddPlan } = useMutation(
    AddPlanFunction,
    {
      onSuccess: onAddPlanSuccess,
      onError: onAddPlanError,
    }
  );

  //------- Edit Single Entry -------
  const EditPlanFunction = async (values) => {
    console.log(values);
    const body = new FormData();
    body.append("courseId", courseId);
    body.append("planId", selectedData.id);
    body.append("planName", values.plan);
    body.append("planDuration", values.planDuration);
    body.append("priceUSD", values.priceUsd);
    body.append("priceZk", values.priceZk);
    body.append("discountUSD", values.discountUsd);
    body.append("discountZk", values.discountZk);

    return await axios.post(`${Config.apiUrl}/edit-plan`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const onEditPlanSuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      // refetch();
      getPlansMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setEditPlan(false);
    setSelectedData("");
  };

  const onEditPlanError = (data) => {
    setEditCourse(false);
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };

  const { isLoading: editPlanLoading, mutate: editPlanMutate } = useMutation(
    EditPlanFunction,
    {
      onSuccess: onEditPlanSuccess,
      onError: onEditPlanError,
    }
  );

  // !---------DELETE PLAN----------
  const deletePlanFunction = async () => {
    const body = new FormData();
    body.append("planId", selectedData.id);
    body.append("courseId", courseId);
    body.append("userType", user?.userType);
    return await axios.post(`${Config.apiUrl}/delete-plan`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token, //token is not in inspect
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const deletePlanSuccess = (res) => {
    if (res.data.status === "OK") {
      toast.success(res.data.msg || "Plan deleted successfully.");
    }
    setDeletePlan(false);
    getPlansMutate();
    console.log(res);
  };
  const deletePlanError = (res) => {
    console.log(res);
  };
  const { isLoading: isDeletePlanLoading, mutate: deletePlanMutate } =
    useMutation(deletePlanFunction, {
      onSuccess: deletePlanSuccess,
      onError: deletePlanError,
    });

  return (
    <>
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <Navbar />

        {/* <Page> */}
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Plans")}</Title>
              <Underline />
            </div>
            {user.userType == Config.userType.SUPERADMIN && (
              <Uploader setAddPlan={setAddPlan} t={t} data={data?.data?.data} />
            )}
          </div>

          {/* <div className="flex w-full justify-between items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              setFilter={setFilter}
              filter={filter}
              user={user}
              t={t}
            />
          </div> */}

          {deletePlan && (
            <DeleteModal
              setDelete={setDeletePlan}
              deleteMutate={deletePlanMutate}
              isDeleteLoading={isDeletePlanLoading}
            />
          )}
          {editPlan && (
            <EditPlanModal
              selectedData={selectedData}
              onEditPlan={setEditPlan}
              onEditPlanMutate={editPlanMutate}
              editPlanLoading={editPlanLoading}
            />
          )}
          {viewDetail && (
            <ViewPlanModal
              selectedData={selectedData}
              setViewDetail={setViewDetail}
            />
          )}
          {addPlan && (
            <AddPlanModal
              setAddPlan={setAddPlan}
              data={data?.data}
              selectedData={selectedData}
              setOpenUploadModel={setOpenUploadModel}
              postAddPlan={postAddPlan}
              AddPlanLoading={AddPlanLoading}
            />
          )}

          {!error && !isLoading && data && (
            // <TableWrapper>
            <PlanTable
              ApiData={error ? [] : data?.data?.data}
              setSelectedData={setSelectedData}
              selectedData={selectedData}
              setViewDetail={setViewDetail}
              setAddPlan={setAddPlan}
              onEditPlan={setEditPlan}
              onDeletePlan={setDeletePlan}
              userType={user.userType}
            />
            // </TableWrapper>
          )}

          {/* {isLoading && <Loading />}
          {error && !isLoading && data?.data?.data?.userList == false && (
            <NotFoundModel />
          )} */}
          {(isLoading || isRefetching) && <Loading />}
          {(error || data?.data?.data?.userList?.length === 0) &&
            !isLoading && <NotFoundModel />}
        </BoxContainer>
      </Bg>
    </>
  );
};
const Filters = ({
  initialValues,
  submitHandler,
  InitialLoading,
  user,
  filter,
  setFilter,
  t,
}) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form>
        <FilterContainer>
          <InputGroup className="max-w-xs" style={{ flexBasis: "content" }}>
            <Label htmlFor="filter">{t("Search")}</Label>

            <Select
              autoComplete="off"
              className="w-36 pl-2 bg-transparent text-sm p-0 border-none"
              style={{ height: "40px" }}
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            >
              <MenuItem value="name">By Course</MenuItem>
            </Select>
          </InputGroup>
          <InputGroup className="max-w-xs">
            <Label htmlFor="filterVal">{t("Value")}</Label>
            <FieldWrapper className="h-10">
              <Field type="text" name="filterVal" id="filterVal" class="ml-1" />
            </FieldWrapper>
          </InputGroup>

          <ApplyBtn disabled={InitialLoading} type="submit">
            {t("Search")}
          </ApplyBtn>
        </FilterContainer>
      </Form>
    </Formik>
  );
};

const Uploader = ({ setAddPlan, data, t }) => {
  const navigate = useNavigate();
  return (
    <UploadWrapper>
      {/* <Button type="button" onClick={() => navigate("/courses/addCourse")}>
        <MdAdd className="text-white w-5 h-5" /> <p>{t("Add Course")}</p>
      </Button> */}
      <Button
        type="button"
        onClick={() => setAddPlan(true)}
        disabled={data && data.length >= 3}
      >
        <MdAdd className="text-white w-5 h-5" /> <p>{t("Add Plan")}</p>
      </Button>
      {/* <Button type="button" onClick={() => setOpenUploadModel(true)}>
        <HiUpload className="text-white w-5 h-5" /> <p>Upload Bulk Contact</p>
      </Button> */}
    </UploadWrapper>
  );
};

const TableWrapper = tw.h1`my-10 border  bg-white rounded-md overflow-auto`;

const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;

const UploadWrapper = tw.div`
 w-full flex justify-end space-x-4 items-center `;

const Button = tw.button`text-white bg-[#4e752f] hover:bg-green-700 w-40 flex items-center space-x-1 justify-center h-10   text-xs whitespace-nowrap rounded disabled:bg-zinc-400 `;
const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`
grid grid-cols-2 w-full gap-4 md:flex md:space-x-4 items-end mt-12 md:mt-8`;

export default Plans;
