import { Formik, Field, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import tw from "tailwind-styled-components";
import { twMerge } from "tailwind-merge";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Parser from "html-react-parser";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Dropzone from "./Dropzone";
import Loading from "react-loading";
import Spinner from "./Spinner";
const animatedComponents = makeAnimated();

const MailBrodcasterForm = ({ user, addCourseMutate, isLoading, }) => {

    const [value, setValue] = useState("");

    const initialValues = {
        subject: "",
        requirements: "",
    };
    // YUP VALIDATION
    const formSchema = yup.object().shape({
        subject: yup.string().required(),

    });
    // SUBMIT HANDLER
    const handleSubmit = (values) => {
        console.log(values);
        const data = { ...values, requirements: value };
        console.log(data);
        addCourseMutate(data);
    };
    return (
        <article className="mt-10 w-full m-auto p-8 rounded-lg bg-white shadow-md">
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={formSchema}
            >
                {({ values, setFieldValue, errors, touched }) => {
                    // console.log(errors);
                    return (
                        <Form className="flex flex-col gap-y-4">

                            <FieldWrapper className="flex-1 flex-wrap">
                                <label>Subject</label>
                                <Field
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    placeholder="Subject"
                                    as={Input}
                                />
                                {errors.subject && touched.subject ? (
                                    <p className="bg-red-100 text-red-500 p-2 text-xs">
                                        {errors.subject}
                                    </p>
                                ) : null}
                            </FieldWrapper>

                            <FieldWrapper>
                                <label>Content</label>
                                <ReactQuill
                                    required
                                    theme="snow"
                                    value={value}
                                    onChange={setValue}
                                    name="requirements"
                                    className="bg-slate-50"

                                // style={{ minHeight: "200px",  }}

                                />
                            </FieldWrapper>


                            <SubmitBtn
                                type="submit"
                                className="flex items-center justify-center h-12"
                            >
                                {isLoading ? (
                                    <Spinner className="text-white w-3 h-3" />
                                ) : (
                                    "Submit"
                                )}
                            </SubmitBtn>
                        </Form>
                    );
                }}
            </Formik>
        </article>
    );
};

const FieldWrapper = tw.div`${(props) =>
    twMerge(`flex flex-col gap-2`, props.className)}`;
export const GroupWrapper = tw.div`flex flex-col md:flex-row md:items-start gap-4 `;
const Input = tw.input`bg-slate-50 text-sm rounded-md p-2`;
const TextArea = tw.textarea`bg-slate-50 text-sm rounded-md p-2`;
const SubmitBtn = tw.button`bg-black rounded-lg text-center text-slate-100 py-2 px-4 w-1/2 m-auto mt-3`;

export default MailBrodcasterForm;
