import Navbar from "../../Components/Navbar";
import { BoxContainer, Underline } from "../../Components/Styles/PageStyles";
import tw from "tailwind-styled-components";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import AddModuleForm from "../../Components/Courses/AddModuleForm";
import AddModuleComp from "../../Components/Module/AddModule";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddModule = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const { id: courseId, length } = useParams();
  const navigate = useNavigate();
  console.log(length);
  const addModule = async (values) => {
    console.log(values);
    const body = new FormData();
    body.append("courseId", courseId);
    body.append("moduleName", values.name);
    body.append("shortDescription", values.description);
    body.append("videos", JSON.stringify(values.videos));
    // body.append("attachments", JSON.stringify(values.moduleAttachments));
    values.attachments.forEach((file) => body.append("attachments[]", file));
    // values.pdfTitles.forEach((item) => body.append("pdfTitles[]", item));
    body.append("pdfTitles", values.pdfTitles.join(","));

    return await axios.post(`${Config.apiUrl}/addmodule`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const onAddModuleSuccess = (res) => {
    console.log(res.data);
    if (res?.data?.status === "OK") {
      toast.success(res?.data?.msg || "Success");
      return navigate(`/modules/${courseId}`);
    }
  };
  const { isLoading, mutate: addModuleMutate } = useMutation(addModule, {
    onSuccess: onAddModuleSuccess,
    onError: (res) => {
      return toast.error(res?.response?.data?.msg || "There was an error.");
    },
  });
  return (
    <section className="min-h-screen w-screen bg-slate-100 py-20">
      <Navbar />
      <BoxContainer>
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col w-full items-start">
            <Title>Add Module {parseInt(length) + 1}.0</Title>
            <Underline />
            <AddModuleComp addModuleMutate={addModuleMutate} isLoading={isLoading} user={user} length={length} />
            {/* <AddModuleForm
              addModuleMutate={addModuleMutate}
              isLoading={isLoading}
              user={user}
            /> */}
          </div>
          {/* <Uploader setOpenUploadModel={setOpenUploadModel} t={t} /> */}
        </div>
      </BoxContainer>
    </section>
  );
};

const Title = tw.h1`text-2xl text-zinc-900 font-semibold whitespace-nowrap`;
export default AddModule;
