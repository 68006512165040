import React, { useState } from "react";
import tw from "tailwind-styled-components";
import { Formik, Field, Form } from "formik";
import {
  InputGroup,
  FieldWrapper,
  Label,
  SubmitBtn,
  CombineInputGroup,
} from "../Styles/InputStyles";
import Loading from "../Loading";
import MenuItem from "@mui/material/MenuItem";
import Select from "react-select";
import Model from "../Model";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DropZone from "./DropzoneImageUpload";
// import DropZone from "../Dropzone";

const AddCourseModel = ({
  AddCustomerLoading,
  postAddUser,
  setOpenUploadModel,
  selectedData,
  data,
}) => {
  const { t, i18n } = useTranslation();

  const InitialValues = {
    // categoryId: data?.CategoryList?.name,
  };
  const CategoryList = data?.categoryList?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  // const categoryId = [
  //     { value: "1", label: "Java" },
  //     { value: "2", label: "Python" },
  //     { value: "3", label: "AWS" },
  // ]

  const SubmitHandler = (values) => {
    console.log("Values is" + values.profile);
    postAddUser(values);
  };

  return (
    <Model width={`w-11/12 max-w-lg `} setOpenModel={setOpenUploadModel}>
      <Title>Add Course</Title>

      {AddCustomerLoading && <Loading />}

      {!AddCustomerLoading && (
        <Formik initialValues={InitialValues} onSubmit={SubmitHandler}>
          <Form>
            <Wrapper>
              <InputGroup>
                <Label htmlFor="CategoryList">Category List</Label>
                <FieldWrapper $select={true}>
                  <Field
                    name="CategoryList"
                    id="CategoryList"
                    autoComplete="off"
                  >
                    {(props) => (
                      <Select
                        className="w-full h-full"
                        // isMulti
                        required
                        options={CategoryList}
                        classNamePrefix="select"
                        // onChange={(val) => {
                        //   props.form.setFieldValue("farmerId", val.map((role)=>role.value))
                        // }}
                        onChange={(val) => {
                          props.form.setFieldValue("categoryList", val.value);
                        }}
                      />
                    )}
                  </Field>
                </FieldWrapper>
              </InputGroup>

              <InputGroup>
                <Label htmlFor="courseName">Course Name</Label>
                <FieldWrapper>
                  <Field
                    name="courseName"
                    id="courseName"
                    type="text"
                    autoComplete="off"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <CombineInputGroup>
                <InputGroup>
                  <Label htmlFor="price">Price</Label>
                  <FieldWrapper>
                    <Field
                      name="price"
                      id="price"
                      type="number"
                      autoComplete="off"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
                <InputGroup>
                  <Label htmlFor="duration">Duration (hours)</Label>
                  <FieldWrapper>
                    <Field
                      type="text"
                      name="duration"
                      id="duration"
                      placeholder="Enter Duration (hours)"
                      autoComplete="off"
                      className="truncate"
                      pattern="\d+"
                      title="Please enter a valid number"
                      required
                    />
                  </FieldWrapper>
                </InputGroup>
              </CombineInputGroup>
              <InputGroup>
                <Label htmlFor="requirement">Requirements</Label>
                <FieldWrapper>
                  <Field
                    name="requirement"
                    id="requirement"
                    type="text"
                    autoComplete="off"
                    required
                  />
                </FieldWrapper>
              </InputGroup>
              <InputGroup>
                <Label htmlFor="description">Description</Label>
                <FieldWrapper>
                  <Field
                    name="description"
                    id="description"
                    component="textarea"
                    row="7"
                    autoComplete="off"
                    required
                  />
                </FieldWrapper>
              </InputGroup>

              {/* <DropZoneModel /> */}
              <InputGroup>
                <Label htmlFor="profile">Choose Image</Label>
                <FieldCustomWrapperDoc $select={true}>
                  <Field
                    name="profile"
                    type="text"
                    id="profile"
                    autoComplete="off"
                    required
                  >
                    {(props) => (
                      <DropZone
                        fields={props.field}
                        setFieldValue={props.form.setFieldValue}
                        componentFor="profile"
                        acceptFiles="application/.jpg"
                        File={props.field.value ? [props.field.value] : null}
                      />
                    )}
                  </Field>
                </FieldCustomWrapperDoc>
                {/* <p className="text-xs text-gray-500 mt-2 ">
                          PDF max size: 10MB
                        </p> */}
              </InputGroup>
            </Wrapper>
            <BtnWrapper>
              <SubmitBtn type="submit">Add</SubmitBtn>
            </BtnWrapper>
          </Form>
        </Formik>
      )}
    </Model>
  );
};

const Wrapper = tw.div`
grid  gap-6 my-6 px-1`;
const SampleFile = tw.a` w-full pt-3 text-blue-500 underline text-sm text-center`;
const Title = tw.h1`text-lg md:text-xl font-medium text-center text-gray-800`;
const BtnWrapper = tw.div`flex justify-center items-center mt-8`;
const TextWrapper = tw.div`
${(p) => (p.$isDragActive ? "bg-gray-400" : " bg-gray-100")}
 upload-fonts w-full  rounded-lg  text-gray-600 flex flex-col items-center py-4 px-3 gap-0 mt-1 cursor-pointer md:text-xs md:gap-2 md:px-8 md:py-5
`;
const FieldCustomWrapperDoc = tw.div`
${(p) => (p.$select ? "" : "pl-2 field-wrapper ")}
relative  rounded  my-4 bg-gray-100   text-gray-800 items-center w-full`;

export default AddCourseModel;
