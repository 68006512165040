import React, { useEffect, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { PrevBtn, NextBtn, PaginationWrapper } from "../Styles/PageStyles";
import Images from "../../Images";
import tw from "tailwind-styled-components";

import moment from "moment";
import { PaginationLimit } from "../../Config";

import { IoMdAdd, IoMdEye } from "react-icons/io";
import { RiDeleteBin5Line } from "react-icons/ri";
import Config from "../../Config";

import { useNavigate, Link } from "react-router-dom";

const Table = ({
  ApiData,

  setSelectedData,

  setDeleteInstitute,
  setViewDetail,
  userType,
}) => {
  const [data, setData] = useState([
    {
      deviceName: "",
      extractionTime: "",
      processingTime: "",
      drops: "",
      action: "",
    },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const toggleRow = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
  };
  const navigate = useNavigate();

  const add = (item) => {
    navigate(`/institutes/addrecords/${item.id}`);
  };
  const courses = (item) => {
    navigate(`/institutes/courses/${item.id}`);
  };

  const deleteInstitute = (item) => {
    setDeleteInstitute(true);
    setSelectedData(item);
  };

  useEffect(() => {
    if (ApiData) {
      const tempData = ApiData.map((item) => ({
        name: getInstitutename(item),
        id: getCategoryId(item),
        email: item.email,
        date: moment(item.created_at).format("DD-MM-YYYY"),
        status: getStatus(item.status),
        action: ActionBtns(item),
      }));

      setData(tempData);
    }
  }, [ApiData]);

  const ActionBtns = (item) => {
    return (
      <ActionBtnWrapper>
        <>
          <Btn title="Add Student" onClick={() => add(item)}>
            <IoMdAdd className="text-gray-700" />
          </Btn>

          <Btn title="Institute Courses" onClick={() => courses(item)}>
            <IoMdEye className="text-gray-700" />
          </Btn>

          {/* {userType == Config.userType.SUPERADMIN && (
            <Btn title="Delete Institute" onClick={() => deleteInstitute(item)}>
              <RiDeleteBin5Line className="text-gray-700" />
            </Btn>
          )} */}
        </>
      </ActionBtnWrapper>
    );
  };
  const getStatus = (status) => {
    if (status == 1) {
      return <InActiveBadge>Inactive</InActiveBadge>;
    } else if (status == 2) {
      return <SuccessBadge>Active</SuccessBadge>;
    } else {
      return <PendingBadge>Pending</PendingBadge>;
    }
  };

  const getCategoryId = (item) => {
    return <span className="bg-sky-100 text-sm font-semibold text-sky-700 p-1 rounded-md">#{item.id}</span>;
  };

  const getInstitutename = (item) => {
    return (
      <Link to={`/institutes/studentList/${item.id}`}>
        <p className="underline hover:text-blue-500">{item.name}</p>
      </Link>
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const columns = React.useMemo(() => {
    if (isMobileView) {
      return [
        {
          Header: "Institute Id",
          accessor: "id",
        },
        {
          Header: "Name",
          accessor: "name",
        },
      ];
    }

    return [
      {
        Header: "Institute Id",
        accessor: "id",
      },
      {
        Header: "Institute Name",
        accessor: "name",
      },
      {
        Header: "Creation Date",
        accessor: "date",
      },
      {
        Header: userType != Config.userType.MARKETING ? "Action" : "",
        accessor: userType != Config.userType.MARKETING ? "action" : "undefined",
      },
    ];
  }, [isMobileView]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      data,
      columns,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(PaginationLimit);
  }, []);

  return (
    <>
      <CustomTable {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>{column.isSorted ? <img src={Images.Arrow} alt="down arrow" className={`${column.isSortedDesc ? "-rotate-90" : "rotate-90"} w-1.5 inline-block ml-1.5`} /> : ""}</span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <React.Fragment key={rowIndex}>
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <Td
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (cellIndex === 0 && window.innerWidth < 768) {
                            toggleRow(rowIndex);
                          }
                        }}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
                {selectedRow === rowIndex && window.innerWidth < 768 && (
                  <Tr>
                    <Td colSpan={columns.length}>
                      <ul>
                        <li>
                          <strong>Creation Date:</strong> {data[rowIndex].date}
                        </li>
                      </ul>
                    </Td>
                  </Tr>
                )}
              </React.Fragment>
            );
          })}
        </Tbody>
      </CustomTable>
      {ApiData?.length > 0 && (
        <PaginationWrapper>
          <div className="px-2">
            Page{" "}
            <em>
              {pageIndex + 1} of {pageOptions.length}
            </em>
          </div>

          <div className="flex gap-1">
            <PrevBtn onClick={() => previousPage()} disabled={!canPreviousPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 opacity-75 " />
            </PrevBtn>
            <NextBtn onClick={() => nextPage()} disabled={!canNextPage}>
              <img src={Images.Arrow} alt="arrow" className="w-2 rotate-180 opacity-75" />
            </NextBtn>
          </div>
        </PaginationWrapper>
      )}
    </>
  );
};

const ActionBtnWrapper = tw.div`grid xl:grid-cols-2 md:grid-cols-1 grid-cols-4 w-40 mb-1 gap-y-1 gap-x-1.5 md:w-16 mt-3 md:mt-0`;

const CustomTable = tw.table` w-full`;
const Thead = tw.thead`border-b`;
const Tbody = tw.tbody`border-b`;
const Tr = tw.tr`border-b rounded-md overflow-hidden `;

const Th = tw.th`text-left text-sm md:p-3 p-0.5 font-medium text-gray-500`;

const Td = tw.td`p-3 text-sm md:p-3 text-left`;

const Btn = tw.button`grid place-items-center border border-gray-300 w-8 h-8 rounded-full transform transition duration-200 hover:scale-110 hover:shadow-md`;

const SuccessBadge = tw.div`bg-green-100 text-green-700 text-xs border-green-300 border w-24 h-7 flex items-center justify-center rounded`;
const PendingBadge = tw.div`bg-yellow-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;

const InActiveBadge = tw.div`bg-red-100 text-yellow-700 text-xs border-yellow-300 border w-24 h-7 flex items-center justify-center rounded`;
export default Table;
