import React from "react";
import tw from "tailwind-styled-components";
import { MdViewHeadline } from "react-icons/md";
import Model from "../Model";
import moment from "moment";

const ViewStudentModel = ({ setViewDetail, selectedData }) => {
    const getGender = (selectedData) => {
        if (selectedData.gender == 1) {
            return <>Male</>;
        } else if (selectedData.gender == 2) {
            return <>Female</>;
        }
    };
    const getPlanType = (selectedData) => {
        if (selectedData?.PurchaseCourse?.planType == 1) {
            return <>Bronze</>;
        } else if (selectedData?.PurchaseCourse?.planType == 2) {
            return <>Silver</>;
        }
        else {
            return <p>Gold</p>
        }
    };

    return (
        <Model width={`w-11/12 max-w-lg`} setOpenModel={setViewDetail}>
            <Wrapper>
                <Title><MdViewHeadline className="mt-1" />View Details</Title>
                <SingleItem name={"Name"} value={selectedData?.name} />
                <SingleItem name={"Email"} value={selectedData?.email || "N/A"} />
                <SingleItem name={"Phone Number"} value={selectedData?.mobile} />
                <SingleItem name={"Course Name"} value={selectedData?.PurchaseCourse?.courseName} />
                <SingleItem name={"Expiry Date"} value={moment(selectedData?.PurchaseCourse?.expiryDate).format("DD-MM-YYYY hh:mm A")} />
                <SingleItem name={"Plan Type"} value={getPlanType(selectedData) || "N/A"} />
                <SingleItem name={"Gender"} value={getGender(selectedData) || "N/A"} />
                <SingleItem name={"City"} value={selectedData?.city} />
                <SingleItem name={"Country"} value={selectedData?.country} />
                <SingleItem name={"DOB"} value={selectedData?.dob} />
                <SingleItem name={"PIN"} value={selectedData?.pin} />
                <SingleItem name={"Created At"} value={moment(selectedData?.created_at).format("DD-MM-YYYY hh:mm A")} />
                <SingleItem name={"Updated At"} value={moment(selectedData?.updated_at).format("DD-MM-YYYY hh:mm A")} />

            </Wrapper>
        </Model>
    );
};

const SingleItem = ({ name, value }) => (
    <div className="flex items-center py-2 border-b border-gray-100">
        <Name>{name}</Name> <p>:</p>
        {<Value>{value}</Value>}
        {/* {<Value>{name == "Gender" ? (value == "1" ? "Male" : "Female") : value}</Value>} */}
    </div>
);

const Wrapper = tw.div`px-4`;
const Title = tw.h2`flex flex-row gap-2 text-lg md:text-xl mb-6 text-gray-700 font-medium text-left`;
const Name = tw.p`text-sm text-gray-500  w-32`;
const Value = tw.p`text-sm text-gray-800 font-medium w-80 px-6 `;

export default ViewStudentModel;
