import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import { BoxContainer, Underline } from "../../Components/Styles/PageStyles";
import tw from "tailwind-styled-components";
import { useMutation } from "react-query";
import axios from "axios";
import Config from "../../Config";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Field, Form } from "formik";
import * as yup from "yup";
import { twMerge } from "tailwind-merge";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Dropzone from "../../Components/Institutes/Dropzone";
import Spinner from "../../Components/Spinner";
import { IoIosAdd } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import Images from "../../Images";
import { FiDownload } from "react-icons/fi";

const animatedComponents = makeAnimated();

const AddInstituteStudents = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const { id: institutionId } = useParams();

  const navigate = useNavigate();

  const [file, setFile] = useState({ file: null, title: "" });

  const initialValues = {
    name: "",
    payment: "",
    paymentmode: null,
    currency: { value: "1", label: "ZK" },
    attachment: null,
  };

  const paymentOptions = [
    { value: 1, label: "Mobile Money" },
    { value: 2, label: "Bank" },
    { value: 3, label: "Cash" },
  ];

  const currencyOptions = [
    { value: "1", label: "ZK" },
    { value: "2", label: "$" },
  ];

  const addModule = async (values) => {
    console.log(values);
    const body = new FormData();
    body.append("instituteId", institutionId);
    body.append("name", values.name);
    body.append("attachment", values.attachment);
    body.append("amount", values.payment);
    body.append("paymentMode", values.paymentmode?.value);
    body.append("currency", values.currency?.value);

    return await axios.post(`${Config.apiUrl}/addStudentList`, body, {
      headers: {
        authorization: `Bearer ${user.token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const onAddModuleSuccess = (res) => {
    console.log(res.data);
    if (res?.data?.status === "OK") {
      toast.success(res?.data?.msg || "Success");
      return navigate(`/institutes`);
    }
  };

  const { isLoading, mutate: addModuleMutate } = useMutation(addModule, {
    onSuccess: onAddModuleSuccess,
    onError: (res) => {
      toast.error(res?.response?.data?.msg || "There was an error.");
    },
  });

  const handleSubmit = (values) => {
    console.log(values);
    addModuleMutate(values);
  };

  const formSchema = yup.object().shape({
    name: yup.string().required(),
    payment: yup.number().required(),
    paymentmode: yup.object().nullable().required(),
    currency: yup.object().nullable().required("Currency is required"),
    attachment: yup.mixed().nullable().required("Attachment is required"),
  });

  return (
    <section className="min-h-screen w-screen bg-slate-100 py-20">
      <Navbar />
      <BoxContainer>
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col w-full items-start">
            <Title>Add Students</Title>
            <Underline />
            <article className="mt-10 w-full m-auto p-8 rounded-lg bg-white shadow-md">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={formSchema}
              >
                {({ values, setFieldValue, errors, touched }) => {
                  return (
                    <Form className="flex flex-col gap-y-4">
                      <GroupWrapper>
                        <FieldWrapper className="flex-1 flex-wrap">
                          <label>Name (for your reference)</label>
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Enter the name..."
                            as={Input}
                          />
                          {errors.name && touched.name ? (
                            <p className="bg-red-100 text-red-500 p-2 text-xs">
                              {errors.name}
                            </p>
                          ) : null}
                        </FieldWrapper>
                      </GroupWrapper>
                      <GroupWrapper className="w-full">
                        <FieldWrapper className="w-full">
                          <label>Total Payment Received</label>
                          <div className="flex gap-2 w-full">
                            <FieldWrapper className="w-1/12">
                              <Field
                                name="currency"
                                id="currency"
                                autoComplete="off"
                              >
                                {({ field }) => (
                                  <Select
                                    {...field}
                                    closeMenuOnSelect={true}
                                    classNamePrefix="select"
                                    // className="z-50"
                                    components={animatedComponents}
                                    options={currencyOptions}
                                    onChange={(selectedOption) => {
                                      setFieldValue("currency", selectedOption);
                                    }}
                                    defaultValue={initialValues.currency}
                                  />
                                )}
                              </Field>
                              {errors.currency && touched.currency ? (
                                <p className="bg-red-100 text-red-500 p-2 text-xs">
                                  {errors.currency}
                                </p>
                              ) : null}
                            </FieldWrapper>
                            <FieldWrapper className="flex-1">
                              <Field
                                type="number"
                                name="payment"
                                id="payment"
                                placeholder="Enter the payment amount"
                                as={Input}
                                className="w-full"
                              />
                              {errors.payment && touched.payment ? (
                                <p className="bg-red-100 text-red-500 p-2 text-xs">
                                  {errors.payment}
                                </p>
                              ) : null}
                            </FieldWrapper>
                          </div>
                        </FieldWrapper>
                      </GroupWrapper>
                      <FieldWrapper className="flex-1">
                        <label>Mode of Payment</label>
                        <Field
                          name="paymentmode"
                          id="paymentmode"
                          autoComplete="off"
                        >
                          {({ field }) => (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              components={animatedComponents}
                              options={paymentOptions}
                              onChange={(selectedOption) => {
                                setFieldValue("paymentmode", selectedOption);
                              }}
                            />
                          )}
                        </Field>
                        {errors.paymentmode && touched.paymentmode ? (
                          <p className="bg-red-100 text-red-500 p-2 text-xs">
                            {errors.paymentmode}
                          </p>
                        ) : null}
                      </FieldWrapper>
                      <FieldWrapper>
                        <label className="flex">
                          Attachment{" "}
                          {/* <em className="text-sm text-gray-500">(CSV file)</em> */}
                          <SampleDownloadButton
                            href={Images.SampleCSV}
                            download
                          >
                            <FiDownload className="mr-2 mt-1" /> Download Sample
                            File (.csv)
                          </SampleDownloadButton>
                        </label>
                        <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4">
                          <Dropzone
                            onAddFile={(file) =>
                              setFieldValue("attachment", file)
                            }
                            onRemoveFile={() =>
                              setFieldValue("attachment", null)
                            }
                            accept=".csv"
                            multiple={false}
                          />
                          {errors.attachment && touched.attachment ? (
                            <p className="bg-red-100 text-red-500 p-2 text-xs">
                              {errors.attachment}
                            </p>
                          ) : null}
                        </div>
                      </FieldWrapper>

                      <SubmitBtn
                        type="submit"
                        className="flex items-center justify-center h-12"
                      >
                        {isLoading ? (
                          <Spinner className="text-white w-3 h-3" />
                        ) : (
                          "Submit"
                        )}
                      </SubmitBtn>
                    </Form>
                  );
                }}
              </Formik>
            </article>
          </div>
        </div>
      </BoxContainer>
    </section>
  );
};

const GroupWrapper = tw.div`flex flex-col md:flex-row md:items-start gap-4 w-full`;
const FieldWrapper = tw.div`${(props) =>
  twMerge(`flex flex-col gap-2`, props.className)}`;

const Input = tw.input`bg-slate-50 text-sm rounded-md p-2`;
const SubmitBtn = tw.button`bg-black rounded-lg text-center text-slate-100 py-2 px-4 w-1/2 m-auto`;
const Title = tw.h1`text-2xl text-zinc-900 font-semibold whitespace-nowrap`;
const SampleDownloadButton = tw.a`flex underline text-gray-600 hover:text-blue-600 ml-2`;
export default AddInstituteStudents;
