import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import Images from "../../Images";
import tw from "tailwind-styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RiDeleteBinLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import DeleteModal from "../../Components/DeleteModal";
import {
  Page,
  Bg,
  BoxContainer,
  // BoxTitle,
  Underline,
  ErrorText,
} from "../../Components/Styles/PageStyles";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import {
  FieldWrapper,
  InputGroup,
  Label,
  SmallBtn,
} from "../../Components/Styles/InputStyles";
import { Field, Form, Formik } from "formik";
import NotFoundModel from "../../Components/NotFoundModel";
import moment from "moment";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Config from "../../Config";
import Loading from "../../Components/Loading";
import DeleteCommunity from "../../Components/DeleteCommunity";

const UserAvatar = ({ name }) => {
  const randomColor = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
    Math.random() * 256
  )}, ${Math.floor(Math.random() * 256)})`;
  // Extract the initials from the user's name
  const initials = name
    .split(" ")
    .map((part) => part[0])
    .join("")
    .toUpperCase();

  return (
    <div
      className="h-10 w-10 bg-gray-300 rounded-full flex items-center justify-center mr-2 "
      // style={{ backgroundColor: randomColor }}
    >
      {initials}
    </div>
  );
};

const CommentCard = ({ comment, onDelete, userType }) => {
  const [showReplies, setShowReplies] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    if (comment.question.length <= 300) {
      setDisplayText(comment.question);
    } else {
      if (!expanded) {
        setDisplayText(comment.question.slice(0, 300));
      } else {
        setDisplayText(comment.question);
      }
    }
  }, [comment.text, expanded]);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="bg-white border-b-2 border-gray-200   my-3 px-4 py-3 rounded-md w-full">
      <div className="flex justify-between">
        <div className="flex">
          <UserAvatar name={comment.customerName} />
          <div>
            <p className="text-sm font-semibold">{comment.customerName}</p>
            <p className="text-xs font-normal mb-2 text-gray-500">
              {moment(comment.created_at).format("YYYY-MM-DD HH:MM")}
            </p>
          </div>
        </div>
        {userType == Config.userType.SUPERADMIN && (
          <Btn onClick={() => onDelete(comment.id)}>
            <RiDeleteBinLine />
          </Btn>
        )}
      </div>

      <p className="text-md font-medium mx-12 text-gray-700">{displayText}</p>
      <div className="flex my-2">
        {comment.question.length > 300 && (
          <button
            onClick={toggleExpand}
            className="text-sky-400 hover:text-sky-700 mt-1 ml-12"
          >
            {expanded ? "Read Less" : "Read More..."}
          </button>
        )}
        {comment.replies.length > 0 && (
          <button
            onClick={() => setShowReplies(!showReplies)}
            className="px-12 mt-2 text-sm text-blue-500 font-bold "
          >
            {showReplies ? (
              <div className="flex">
                <IoIosArrowUp className="w-5 h-5 mr-1" /> {comment.totalReply}{" "}
                Replies
              </div>
            ) : (
              <div className="flex">
                <IoIosArrowDown className="w-5 h-5 mr-1" /> {comment.totalReply}{" "}
                Replies
              </div>
            )}
          </button>
        )}
      </div>
      {showReplies && (
        <div className="replies">
          {comment.replies.map((reply) => (
            <div key={reply.id} className=" py-2 px-5 mx-6 mt-2 ">
              <div className="flex">
                <UserAvatar name={reply.customerName} />
                <div>
                  <p className="text-sm font-semibold">{reply.customerName}</p>
                  <p className="text-xs font-normal mb-2 text-gray-500">
                    {" "}
                    {moment(reply.created_at).format("YYYY-MM-DD HH:MM")}
                  </p>
                </div>
              </div>

              <p className="text-md font-medium mx-12 text-gray-700">
                {reply.questionReply}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CommunityTab = () => {
  const user = useSelector((state) => state.UserReducer.user);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(90, "days").format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const initialValues = {
    startDate: moment(new Date()).subtract(90, "days").format("YYYY-MM-DD"),
    endDate: moment(new Date()).format("YYYY-MM-DD"),
  };
  const { t, i18n } = useTranslation();

  const fetchFunction = async () => {
    const body = new FormData();
    body.append("userType", user.userType);

    body.append("startDate", startDate);
    body.append("endDate", endDate);
    return await axios.post(`${Config.apiUrl}/get-questions`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };

  const getCustomersSuccess = (res) => {
    if (res.data.status == "NOK" || res.data.data == false) error = true;
  };

  const getCustomersError = (res) => {};

  const {
    isLoading: isLoading,
    mutate: getCustomersMutate,
    error,
    data,
    isRefetching,
    refetch,
  } = useMutation(fetchFunction, {
    onSuccess: getCustomersSuccess,
    onError: getCustomersError,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      getCustomersMutate();
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [startDate, endDate]);
  const submitHandler = (values) => {
    const newValues = {
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
    };
    setStartDate(newValues.startDate);
    setEndDate(newValues.endDate);
    // setFilterValue(values.filterVal);
    getCustomersMutate();
  };
  // Sample data of comments and replies

  console.log(data?.data?.comments);

  const EditDeleteFunction = async (values) => {
    const body = new FormData();
    body.append("id", values.id);
    // body.append("status", values.status);

    return await axios.post(`${Config.apiUrl}/delete-community`, body, {
      headers: {
        authorization: "Bearer" + " " + user.token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
    });
  };
  const onEditDeleteSuccess = (data) => {
    if (data?.data?.status == "OK") {
      toast.success(data?.data?.msg || "Success");
      getCustomersMutate();
    } else {
      toast.error(data?.data?.msg || "An Error Occured");
    }
    setSelectedData("");
    setDeleteCategory(false);
  };
  const onEditDeleteError = (data) => {
    setDeleteCategory(false);
    setSelectedData("");
    toast.error(data?.data?.msg || "An Error Occured");
  };
  const { isLoading: categoryDeleteLoading, mutate: categoryDeleteMutate } =
    useMutation(EditDeleteFunction, {
      onSuccess: onEditDeleteSuccess,
      onError: onEditDeleteError,
    });

  return (
    <>
      <Navbar />
      <Bg
        style={{
          backgroundImage: `url(${Images.BeamsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top left",
          height: "100vh",
        }}
      >
        <BoxContainer>
          <div className="flex w-full justify-between items-center">
            <div>
              <Title>{t("Panuka Community")}</Title>
              <Underline />
            </div>
            {/* <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              user={user}
              t={t}
            /> */}
          </div>
          <div className="flex w-full justify-end items-center">
            <Filters
              submitHandler={submitHandler}
              initialValues={initialValues}
              InitialLoading={isLoading}
              user={user}
              t={t}
            />
          </div>
          {deleteCategory && (
            <DeleteCommunity
              setDeleteCategory={setDeleteCategory}
              selectedData={selectedData}
              categoryDeleteMutate={categoryDeleteMutate}
              categoryDeleteLoading={categoryDeleteLoading}
            />
          )}
          {!isLoading && data && (
            <div className="md:mx-10 my-10 ">
              {data?.data?.comments.map((comment) => (
                <CommentCard
                  key={comment.id}
                  comment={comment}
                  onDelete={(id) => {
                    setSelectedData(id);
                    setDeleteCategory(true);
                  }}
                  userType={user.userType}
                />
              ))}
            </div>
          )}
          {isLoading && <Loading />}
          {(error || data?.data?.comments?.length === 0) && !isLoading && (
            <NotFoundModel />
          )}
        </BoxContainer>
      </Bg>
    </>
  );
};

const Filters = ({ initialValues, submitHandler, InitialLoading, user }) => {
  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler}>
      <Form>
        <FilterContainer>
          {/* <div className="flex  items-end sm:text-base text-xs flex-wrap gap-2">
            <div className="flex items-end gap-2"> */}
          <InputGroup className="w-[8.5rem] ">
            <Label htmlFor="startDate">Start Date:</Label>
            <FieldWrappers className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="startDate" id="startDate" class="ml-1" />
            </FieldWrappers>
          </InputGroup>

          <InputGroup className="w-[8.5rem]">
            <Label htmlFor="endDate">End Date:</Label>
            <FieldWrappers className="h-10">
              <img
                src={Images.Calender}
                alt="Calender icon"
                className="w-3.5"
              />
              <Field type="date" name="endDate" id="endDate" className="ml-1" />
            </FieldWrappers>
          </InputGroup>
          {/* </div> */}

          <ApplyBtn disabled={InitialLoading} type="submit">
            Apply Filter
          </ApplyBtn>
          {/* </div> */}
        </FilterContainer>
      </Form>
    </Formik>
  );
};
const BoxHead = tw.div`flex justify-between items-center mb-10 mx-auto `;
const Title = tw.h1`text-xl  text-gray-800 whitespace-nowrap   font-medium`;
const Container = tw.div`Container`;

const BoxTitle = tw.h1`
${(p) => (p.$fullWidth ? "flex inline-flex" : "justify-center")}
text-2xl  text-gray-700 font-medium  space-x-2 items-center  mb-5  rounded-md cursor-pointer`;
export const FieldWrappers = tw.div`
${(p) => (p.$select ? "" : "pl-2  field-wrapper border border-gray-300")}
 relative  rounded-md   w-full  flex items-center`;

const ApplyBtn = tw.button`${(p) =>
  p.$active
    ? "bg-gray-100"
    : ""} border whitespace-nowrap border-gray-200 flex space-x-1.5 justify-center items-center text-white h-full rounded-md px-6  cursor-pointer bg-[#4e752f] hover:bg-green-700  py-2.5 text-sm`;

const FilterContainer = tw.div`flex items-end flex-wrap justify-between gap-4`;
const Btn = tw.button``;
export default CommunityTab;
